import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { OrderByPipe } from 'ngx-pipes';

import { IDynamicFormBlock } from 'src/app/core/models/dynamic-form-block.vm';
import { ProjectService } from 'src/app/core/services/project.service';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectAdminUsers } from 'src/app/store/admin-userlist.selectors';
import { OrganisationService } from 'src/app/core/services/organisation.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dynamic-form-dropdown',
  templateUrl: './dynamic-form-dropdown.component.html',
  styleUrls: ['./dynamic-form-dropdown.component.scss'],
  providers: [ OrderByPipe ]
})
export class DynamicFormDropdownComponent implements OnChanges {
  
  @Input() public form: FormGroup;
  @Input() public block: IDynamicFormBlock;
  @Input() public section?: string;
  @Input() public data: any;
  @Input() public userDetails: {email: string, name: string, userId: number};
  @Output() public optionSelected = new EventEmitter<any>();
  @Output() public buttonClicked = new EventEmitter<any>();
  @ViewChild('searchInput') searchInput: ElementRef;

  public options: { id: number, name: string }[] = [];
  public selectedOption: { id: number, name: string };
  public currentValue: number;
  public searchInputValue: string;
  public showDropdown: boolean = false;

  darkMode$: Observable<boolean>;

  constructor(
    private projectService: ProjectService,
    private orderBy: OrderByPipe,
    private organisationService: OrganisationService,
    private store: Store<{ darkMode: boolean }>,
    private router: Router,
  ) { 
    this.darkMode$ = store.select('darkMode')
  }

  ngOnChanges(): void {
    this.currentValue = this.form.get(this.block.dataKey).value;
    this.setOptions(this.block.optionsType, this.currentValue);
  }

  reloadOrganisationsList() {
    this.setOptions(this.block.optionsType, this.currentValue)
  }

  private setOptions(type: string, currentValue: number): void {
    if (type == 'adminUsers') {
      this.getAdminUsers(currentValue);
    }
    if (type == 'services') {
      this.getServices(currentValue);
    }
    if (type == 'projectReports') {
      this.getReports(currentValue);
    }
    if (type == 'organisations') {
      this.getOrganisationList(currentValue);
    }
    if (type == 'projects') {
      this.getProjects();
    }
    if (type == 'organisationTypes') {
      this.getOrganisationTypes();
    }
  }

  public buttonAction(action: string) {
    this.buttonClicked.emit(action);
  }

  public toggleDropdown(): void {
    if (this.showDropdown == false) {
      this.showDropdown = true;
      // Await change of boolean before focus
      setTimeout(()=>{ this.searchInput.nativeElement.focus() }, 0);
    } else {
      this.showDropdown = false;
      this.searchInputValue = '';
    }
  }

  public selectItem(option: any): void {
    this.form.get(this.block.dataKey).setValue(option.id);
    this.form.markAsDirty();
    this.form.updateValueAndValidity();
    this.selectedOption = this.options.find(opt => opt.id == option.id);
    this.toggleDropdown();
    if(this.block.dataKey == "organisation_id") {
      this.optionSelected.emit(option.name);
    }
    if(this.block.dataKey == "organisationId") {
      this.optionSelected.emit(option.id);
      this.currentValue = option.id
    }
  }

  // Get options

  private getReports(currentValue: number) {
    let allReports = sessionStorage.getItem('projectReports');  
    let options;
    if (allReports) {      
      options = JSON.parse(allReports);
      this.options = this.orderBy.transform(options, 'id');
      this.selectedOption = this.options.find(opt => opt.id == currentValue);
    } else {
      this.projectService.getAllReports(+sessionStorage.getItem('currentProjectId')).subscribe(
        success => {       
          options =  success.data.responseList.map(item => { return { id: item.reportId, name: "[" + item.reportId + "] " + item.reportName } });
          this.options = this.orderBy.transform(options, 'reportId');
          this.selectedOption = this.options.find(opt => opt.id == currentValue);
          sessionStorage.setItem('projectReports',JSON.stringify(this.options))       
        }
      )
    }
  }

  private getServices(currentValue: number): void {
    let sessionServices = sessionStorage.getItem('services');
    let options;
    if (sessionServices) {
      options = JSON.parse(sessionServices);
      this.options = this.orderBy.transform(options, 'name');
      this.selectedOption = this.options.find(opt => opt.id == currentValue);
    } else {
      this.projectService.getProjectServiceItems(+sessionStorage.getItem('currentProjectId')).subscribe(
        success => {
          options = success.data.serviceItemList.map(item => { return { id: item.serviceItemId, name: item.serviceItemName } });
          this.options = this.orderBy.transform(options, 'name');
          this.selectedOption = this.options.find(opt => opt.id == currentValue);
          sessionStorage.setItem('services', JSON.stringify(this.options));
        }
      )
    }
  }

  private getOrganisationList(currentValue: number): void {
    let options;
    this.organisationService.getOrganisations(+sessionStorage.getItem('currentProjectId')).subscribe(
      success => {
        options = success.data.organisationList.map(item => { return { id: item.organisationId, name: item.organisationName } });
        this.options = this.orderBy.transform(options, 'name');
        this.selectedOption = this.options.find(opt => opt.id == currentValue);
        if(!this.selectedOption) {
          this.selectedOption = {id: null, name: this.data.organisation_name || this.data.organisation}
        }
      }
    )
  }

  private getProjects(): void {
    let options;
    this.projectService.getDataUploadsProjects().subscribe(
      success => {
        options = success.projects.map(item => { return { id: item.project_id, name: item.project_name } });
        this.options = this.orderBy.transform(options, 'name');
      }
    )
  }

  private getAdminUsers(currentValue: number): void {
    this.store.select(selectAdminUsers).subscribe((data) => {
      if(data) {
        let options = data.map(user => { 
          return { id: user.userId, name: user.userFullName } 
        });
        this.options = this.orderBy.transform(options, 'name');
        this.selectedOption = this.options.find(opt => opt.id == currentValue);
      }
    });
  }

  private getOrganisationTypes(): void {
    let options;
    this.organisationService.getOrganisationTypes().subscribe(
      (success) => {
        options = success.organisationTypes.map(item => { return { id: item.organisation_type_id, name: item.organisation_type_name
        } });
        this.options = this.orderBy.transform(options, 'name');
      },
      (error) => {
        console.log('Error: ', error);
      }
    );
  }

}
