import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoaderService } from 'src/app/core/services/loader.service';
import { ProjectService } from 'src/app/core/services/project.service';
import { IDynamicTableColumn } from 'src/app/core/models/dynamic-table-column.vm';
import { IDynamicFormBlock } from 'src/app/core/models/dynamic-form-block.vm';
import { Layout } from 'src/app/core/classes/layout';
import { DocumentsStaticTableColumns, DocumentsStaticForm } from './static-document-uploader-layouts';
import moment from 'moment';
import { IProjectDocumentsStatic } from 'src/app/core/models/project-documents-static-vm';
import { UploaderService } from 'src/app/core/services/uploader.service';
import { IAlert } from 'src/app/core/models/alert';
import { ILayout } from 'src/app/core/models/layout.vm';

@Component({
  selector: 'app-static-document-uploader',
  templateUrl: './static-document-uploader.component.html',
  styleUrls: ['./static-document-uploader.component.scss']
})
export class StaticDocumentUploaderComponent {

  public componentName: string = "static-document";
  public awsDocumentsStatic: Array<IProjectDocumentsStatic>;
  public currentYear: number;
  public layout: ILayout;
  public staticDocumentsTableColumns: Array<IDynamicTableColumn> = DocumentsStaticTableColumns;
  public questionGroupForm: Array<IDynamicFormBlock> = DocumentsStaticForm;
  public alert: IAlert;
  public displayedDocuments: any;
  public documentLayout: Layout;
  public loadingError: string;
  public currentQueryParams: any;
  public newDocumentLayout: Layout;
  public files: any[] = [];
  public dynamicButtons: any = [
    {
    class: 'btn btn-success',
    name: '+ Add Document'
    }
  ]
  public nonProjectSpecificId: number = 0

  constructor(
    public loaderService: LoaderService,
    private projectService: ProjectService,
    private router: Router,
    private route: ActivatedRoute,
    private Uploader: UploaderService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.currentQueryParams = params;
    });
    this.route.params.subscribe(params => {
      this.currentYear = +params.year 
    });  
    this.getData()
  }

  public yearChange(year: number): void {
    this.currentYear = year
    this.router.navigate(['/tool/static-document-uploader', this.currentYear]);      
    this.getDocuments()
  }

  private getData(): void {
    this.layout = new Layout('Static Documents Uploader', [], `/tool`);
    this.getDocuments()       
  }

  private getDocuments(): void {   
    this.projectService.getProjectStaticDocuments(this.nonProjectSpecificId, this.currentYear).subscribe(
      success => {
        success.data.documentList.forEach(value => {
          value["uploadTime"] = moment(value.uploadTime).format('LLL');
        });
        this.awsDocumentsStatic = success.data.documentList;
           // Open slider, if in params
        if (this.currentQueryParams.document) {
          this.onTableRowClick(this.currentQueryParams.document);
        }
       },
      error => { 
        console.log('Error: ', error);
        this.loadingError = error.error.error.message;
      }
    )
  }

  public onTableRowClick = (primaryKey: string): void => {
    this.displayedDocuments = this.awsDocumentsStatic;
    this.displayedDocuments = this.displayedDocuments.find(document => document.documentId == +primaryKey);
    this.documentLayout = new Layout(
      this.displayedDocuments.awsKeyLocation,
      [ `ID: ${this.displayedDocuments.documentId}` ],
      null
    )
    this.router.navigate([], { queryParams: { document: primaryKey }, queryParamsHandling: 'merge' });
  }

  public closeQuestionSlider = (): void => {
    this.documentLayout = null;
    this.newDocumentLayout = null;
    this.router.navigate([], { queryParams: { document: null }, queryParamsHandling: 'merge' });
  }

  public newDocumentClick() {
    this.newDocumentLayout = new Layout(
      'New Document Upload',
      ['Please upload a document file'],
      null
    )
  }

  public uploadFile(event){
    this.Uploader.staticDocumentUpload(event, null, this.currentYear).subscribe(success => {
      this.closeQuestionSlider()
      this.getDocuments();
    },
    error => { 
      console.log('Error: ', error);
      if(error.error.includes('Invalid file extension. Allowed extensions are .xls, .xlsx, .pdf.')){
        this.alert = {
          message: `<strong>Invalid file type: please use .xls, .xlsx, .pdf.</strong>`,
          alertClass: 'danger',
          fadeOut: true,
        }
      } else {
        this.alert = {
          message: `<strong>Upload Failed</strong>`,
          alertClass: 'danger',
          fadeOut: true,
        };
      }
    })
  }


}
