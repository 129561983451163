import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Layout } from 'src/app/core/classes/layout';
import { ILayout } from 'src/app/core/models/layout.vm';
import { LoaderService } from 'src/app/core/services/loader.service';
import { ProjectService } from 'src/app/core/services/project.service';

@Component({
  selector: 'app-admin-reports',
  templateUrl: './admin-reports.component.html',
  styleUrls: ['./admin-reports.component.scss']
})
export class AdminReportsComponent implements OnInit {
  public loadingError: string = null;
  public layout: ILayout;
  public adminReports: any
  public currentAdminReports: number
  public currentUrlParams: any;
  constructor(
    public loaderService: LoaderService,
    private projectService: ProjectService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.getReports()
    this.route.params.subscribe((params) => {
      this.currentUrlParams = params;
      this.routeChange(params);
    });  
  
  }

  routeChange(params){
  this.currentAdminReports = params.id
    this.layout = new Layout(
      'Custom Reports',
      [], ''
    );
  }

  public openReport(report){
    if(report.setYear == 'Y'){      
      this.router.navigate(['tool/custom-reports', report.reportId, new Date().getFullYear()]);
    } else {
      this.router.navigate(['tool/custom-reports', report.reportId]);
    }
      this.currentAdminReports = report
  }

  public getReports(){
    this.projectService.getAdminReports().subscribe(success => {
      this.adminReports = success.data.adminReportList
    },
    error => { 
      console.log('Error: ', error);
    }
  )
  }


}
