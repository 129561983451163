import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map, mergeMap, tap } from "rxjs/operators";
import { loadUsersDetails, loadUsersDetailsSuccess } from "./user-details.actions";
import { UserService } from "../core/services/user.service";

@Injectable()
export class userDetailsListEffects {
    constructor(
        private actions$: Actions,
        private user: UserService,
    ) {}

    loadUsersDetails$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loadUsersDetails),
            mergeMap(() => {
                return this.user.getUser().pipe(
                    map(userDetails => {
                        return loadUsersDetailsSuccess({ userDetails })
                    }),
                    tap(({ userDetails }) => {
                        sessionStorage.setItem('user', 
                            JSON.stringify({
                              name: userDetails.data.fullName,
                              email: userDetails.data.logonName,
                              userId: userDetails.data.userId
                            })
                          );                      
                        })
                )
            })
        )
    });
}