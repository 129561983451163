import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IDynamicFormBlock } from 'src/app/core/models/dynamic-form-block.vm';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-dynamic-form-select',
  templateUrl: './dynamic-form-select.component.html',
  styleUrls: ['./dynamic-form-select.component.scss']
})
export class DynamicFormSelectComponent implements OnInit {

  @Input() public form: FormGroup;
  @Input() public block: IDynamicFormBlock;
  @Input() public data: any;
  @Input() public userDetails: {email: string, name: string, userId: number};
  @Output() id = new EventEmitter<object>();
  @Output() public buttonClicked = new EventEmitter<any>();

  darkMode$: Observable<boolean>;
  public value: string
  constructor(
    private store: Store<{ darkMode: boolean }>
  ) { 
    this.darkMode$ = store.select('darkMode')
  }

  ngOnInit(): void { }

  public buttonAction(action: string) {
    this.buttonClicked.emit(action);
  }

  onOptionSelected(e){
    this.id.emit(e)
  }
}
