import { Constants } from "src/app/core/constants/constants";
import { IDynamicFormBlock } from "src/app/core/models/dynamic-form-block.vm";
import { IDynamicTableColumn } from "src/app/core/models/dynamic-table-column.vm";

export const OrganisationProductDetailsForm: IDynamicFormBlock[] = [
    {
        blockType: 'divider'
    },
    {
        label: 'Product Details',
        blockType: 'header',
        colWidth: 12
    },
    {
        label: 'Organisation Id',
        dataKey: 'organisationId',
        blockType: 'input',
        colWidth: 6,
    },
    {
        label: 'Product Name',
        dataKey: 'productId',
        blockType: 'select',
        options: Constants.products,
        colWidth: 6,
    },
    {
        label: 'Product Year',
        dataKey: 'productYear',
        blockType: 'input',
        colWidth: 6,
    },
    {
        label: 'Address 1',
        dataKey: 'invoiceAddress1',
        blockType: 'input',
        colWidth: 6,
    },
    {
        label: 'Address 2',
        dataKey: 'invoiceAddress2',
        blockType: 'input',
        colWidth: 6,
    },
    {
        label: 'Address 3',
        dataKey: 'invoiceAddress3',
        blockType: 'input',
        colWidth: 6,
    },
    {
        label: 'Address 4',
        dataKey: 'invoiceAddress4',
        blockType: 'input',
        colWidth: 6,
    },
    {
        label: 'Post Code',
        dataKey: 'invoiceAddressPc',
        blockType: 'input',
        colWidth: 6,
    },
    {
        label: 'Invoice Contact',
        dataKey: 'invoiceContact',
        blockType: 'input',
        colWidth: 6,
    },
    {
        label: 'Invoice Email',
        dataKey: 'invoiceEmail',
        blockType: 'input',
        colWidth: 6,
    },
    {
        label: 'Invoice Job Title',
        dataKey: 'invoiceJobTitle',
        blockType: 'input',
        colWidth: 6,
    },
    {
        label: 'Invoice Phone',
        dataKey: 'invoicePhone',
        blockType: 'input',
        inputType: 'number',
        colWidth: 6,
    },
    {
        label: 'CEO Contact',
        dataKey: 'ceoContact',
        blockType: 'input',
        colWidth: 6,
    },
    {
        label: 'CEO Email',
        dataKey: 'ceoEmail',
        blockType: 'input',
        colWidth: 6,
    },
    {
        label: 'PO Number',
        dataKey: 'poNumber',
        blockType: 'input',
        colWidth: 6,
    },
    {
        label: 'Enabled',
        dataKey: 'productIsEnabled',
        blockType: 'select',
        options: Constants.yesNo,
        colWidth: 6,
        disabled: true
    },
    {
        label: 'Debtor',
        dataKey: 'debtor',
        blockType: 'select',
        options: Constants.yesNo,
        colWidth: 6,
    },
    {
        label: 'Status',
        dataKey: 'membershipStatusId',
        blockType: 'select',
        colWidth: 6,
        restrictTo: [32175, 40429]
    },
    {
        label: 'Charging Profile ID',
        dataKey: 'chargingProfileId',
        blockType: 'select',
        colWidth: 6,
        restrictTo: [32175, 40429]
    },
    {
        label: 'Charging Profile Band ID',
        dataKey: 'chargingProfileBandId',
        blockType: 'select',
        colWidth: 6,
        restrictTo: [32175, 40429]
    },
    {
        label: 'Override Fee',
        dataKey: 'overrideFee',
        inputType: 'number',
        blockType: 'input',
        colWidth: 6,
        restrictTo: [32175, 40429]
    },
    {
        label: 'Invoice Number',
        dataKey: 'invoiceNumber',
        blockType: 'input',
        inputType: 'number',
        colWidth: 6,
    },
    {
        label: 'Credit Note Number',
        dataKey: 'creditNoteNumber',
        blockType: 'input',
        inputType: 'number',
        colWidth: 6,
    },
    {
        label: 'Credit Note Fee',
        dataKey: 'creditNoteFee',
        inputType: 'number',
        blockType: 'input',
        colWidth: 6,
    },
    {
        label: 'Save',
        dataKey: 'save',
        blockType: 'saveButton',
        colWidth: 6,
        dataKeys: ['organisationId','productId','productYear'],
        action: 'submit'
    }
]

export const OrganisationProductTableColumns: IDynamicTableColumn[] = [
    {
        header: 'ID',
        dataKey: 'productId',
        percWidth: 10,
        sortable: true,
        groupable: false,
        searchable: true,
    },
    {
        header: 'Product Name',
        dataKey: 'productName',
        percWidth: 45,
        sortable: true,
        groupable: false,
        searchable: false
    },
    {
        header: 'Product Enabled',
        dataKey: 'productIsEnabled',
        percWidth: 15,
        sortable: true,
        groupable: true,
        searchable: true,
    },
    {
        header: 'Status',
        dataKey: 'membershipStatus',
        percWidth: 15,
        sortable: true,
        groupable: true,
        searchable: true,
    },
    {
        header: 'Has Document Access',
        dataKey: 'hasDocumentAccess',
        percWidth: 15,
        sortable: true,
        groupable: true,
        searchable: true,
    }
];

export const OrganisationProductAddForm: IDynamicFormBlock[] = [
    {
        blockType: 'divider'
    },
    {
        label: 'Product Details',
        blockType: 'header',
        colWidth: 12
    },
    {
        label: 'Organisation ID',
        dataKey: 'organisationId',
        helpText: "Required",
        blockType: 'input',
        colWidth: 6,
    },
    {
        label: 'Product ID',
        dataKey: 'productId',
        helpText: "Required",
        blockType: 'select',
        options: Constants.products,
        colWidth: 6,
    },
    {
        label: 'Product Year',
        dataKey: 'productYear',
        helpText: "Required",
        blockType: 'input',
        colWidth: 6,
    },
    {
        label: 'Address 1',
        dataKey: 'invoiceAddress1',
        blockType: 'input',
        colWidth: 6,
    },
    {
        label: 'Address 2',
        dataKey: 'invoiceAddress2',
        blockType: 'input',
        colWidth: 6,
    },
    {
        label: 'Address 3',
        dataKey: 'invoiceAddress3',
        blockType: 'input',
        colWidth: 6,
    },
    {
        label: 'Address 4',
        dataKey: 'invoiceAddress4',
        blockType: 'input',
        colWidth: 6,
    },
    {
        label: 'Post Code',
        dataKey: 'invoiceAddressPc',
        blockType: 'input',
        colWidth: 6,
    },
    {
        label: 'Invoice Contact',
        dataKey: 'invoiceContact',
        blockType: 'input',
        colWidth: 6,
    },
    {
        label: 'Invoice Email',
        dataKey: 'invoiceEmail',
        blockType: 'input',
        colWidth: 6,
    },
    {
        label: 'Invoice Job Title',
        dataKey: 'invoiceJobTitle',
        blockType: 'input',
        colWidth: 6,
    },
    {
        label: 'Invoice Phone',
        dataKey: 'invoicePhone',
        blockType: 'input',
        inputType: 'number',
        colWidth: 6,
    },
    {
        label: 'CEO Contact',
        dataKey: 'ceoContact',
        blockType: 'input',
        colWidth: 6,
    },
    {
        label: 'CEO Email',
        dataKey: 'ceoEmail',
        blockType: 'input',
        inputType: 'Email',
        colWidth: 6,
    },

    {
        label: 'PO Number',
        dataKey: 'poNumber',
        blockType: 'input',
        colWidth: 6,
    },
    {
        label: 'Enabled',
        dataKey: 'productIsEnabled',
        blockType: 'select',
        options: Constants.yesNo,
        colWidth: 6,
        disabled: true
    },
    {
        label: 'Debtor',
        dataKey: 'debtor',
        blockType: 'select',
        options: Constants.yesNo,
        colWidth: 6,
    },
    {
        label: 'Status',
        dataKey: 'membershipStatusId',
        blockType: 'select',
        colWidth: 6,
    },
    {
        label: 'Charging Profile ID',
        dataKey: 'chargingProfileId',
        blockType: 'select',
        colWidth: 6,
    },
    {
        label: 'Charging Profile Band ID',
        dataKey: 'chargingProfileBandId',
        blockType: 'select',
        colWidth: 6,
    },
    {
        label: 'Override Fee',
        dataKey: 'overrideFee',
        inputType: 'number',
        blockType: 'input',
        colWidth: 6,
    },

    {
        label: 'Invoice Number',
        dataKey: 'invoiceNumber',
        blockType: 'input',
        inputType: 'number',
        colWidth: 6,
    },
    {
        label: 'Credit Note Number',
        dataKey: 'creditNoteNumber',
        blockType: 'input',
        inputType: 'number',
        colWidth: 6,
    },
    {
        label: 'Credit Note Fee',
        dataKey: 'creditNoteFee',
        inputType: 'number',
        blockType: 'input',
        colWidth: 6,
    },
    {
        label: 'Save',
        dataKey: 'save',
        blockType: 'saveButton',
        dataKeys: ['organisationId','productId','productYear'],
        colWidth: 6
    }
]