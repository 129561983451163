import { Component, Input, OnInit } from '@angular/core';
import { IDynamicTableColumn } from 'src/app/core/models/dynamic-table-column.vm';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ProductsTableColumns } from './product-detail-layouts';
import { LoaderService } from 'src/app/core/services/loader.service';
import { Constants } from 'src/app/core/constants/constants';
import { ILayout } from 'src/app/core/models/layout.vm';
import { Layout } from 'src/app/core/classes/layout';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductService } from 'src/app/core/services/product.service';
import { IProductOrganisations } from 'src/app/core/models/product-organisations.vm';

@Component({
  selector: 'app-products-detail',
  templateUrl: './products-detail.component.html',
  styleUrls: ['./products-detail.component.scss']
})
export class ProductsDetailComponent implements OnInit {
  @Input() public currentProduct: any;
  @Input() public currentYear: number;
  public layout: ILayout;

  private unsubscribe = new Subject();
  public productOrganisationList: Array<IProductOrganisations>
  public organisationTableColumns: Array<IDynamicTableColumn> = ProductsTableColumns;
  public loadingError: string = null;
  public currentUrlParams: any;
  public products: any;
  public currentProductInfo: any;


  constructor(
    private productService: ProductService,
    public loaderService: LoaderService,
    private router: Router,
    private route: ActivatedRoute,

  ) { }
  
  ngOnInit(): void {
    this.getOptions()
    this.getOrganisationList(this.currentProduct, this.currentYear)
    this.route.params.subscribe((params) => {
      this.currentUrlParams = params;
      this.routeChange(params);
    });
  }

  public yearChange(year: any): void {
    this.currentYear = year
    this.router.navigate(['products',this.currentYear, this.currentProduct]);

  }

  routeChange(params){
    this.currentProduct = params.id
    this.currentYear = params.year || Constants.currentYear;
    this.getOrganisationList(this.currentProduct, this.currentYear)
  }


  private getOrganisationList(productId: number, year: number): void {
    this.productService.getOrganisationProduct(productId, year).pipe(takeUntil(this.unsubscribe)).subscribe(
      success => { 
          this.productOrganisationList = success.data.productOrgList
          this.productOrganisationList.forEach(a => a.overrideFee ? a.bandFee = a.overrideFee : null)
      },
      error => { 
        console.log('Error: ', error);
      }
    )
  }

  public getOptions(){
    this.productService.getOrganisationProductOptions().subscribe(success => {
      this.products = success.data.availableProductList
      this.findProduct()
    })

  }

  public findProduct(){
    let currentProduct: any = this.currentProduct
    this.currentProductInfo = this.products.find(a => a.productId == currentProduct)
    this.layout = new Layout(
      this.currentProductInfo.productName,
      [], ''
    );
  }

  public onTableClick = (primaryKey: any): void =>{
    this.router.navigate([`/organisation/${this.currentYear}/${primaryKey}/products`],);
  }

}
