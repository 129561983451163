

import { IDynamicTableColumn } from "src/app/core/models/dynamic-table-column.vm";

export const ProductsTableColumns: IDynamicTableColumn[] = [
    {
        header: 'ID',
        dataKey: 'organisationId',
        percWidth: 15,
        sortable: true,
        groupable: false,
        searchable: true,
    },
    {
        header: 'Debtor',
        dataKey: 'debtor',
        percWidth: 15,
        sortable: true,
        groupable: true,
        searchable: true,
        badge: 'yesNo'
    },
    {
        header: 'Name',
        dataKey: 'organisationName',
        percWidth: 35,
        sortable: true,
        groupable: false,
        searchable: true,
    },
    {
        header: 'Status',
        percWidth: 25,
        dataKey: 'membershipStatus',
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Charging Profile',
        percWidth: 25,
        dataKey: 'chargingProfile',
        sortable: true,
        groupable: true,
        searchable: false,
    },
    {
        header: 'Band Description',
        dataKey: 'bandDescription',
        percWidth: 30,
        sortable: true,
        groupable: true,
        searchable: false,
    },
    {
        header: 'Fee',
        dataKey: 'bandFee',
        percWidth: 10,
        sortable: true,
        groupable: true,
        searchable: false
    },
    {
        header: 'CEO Contact',
        dataKey: 'ceoContact',
        percWidth: 0,
        sortable: false,
        groupable: false,
        searchable: false,
    },
    {
        header: 'CEO Email',
        dataKey: 'ceoEmail',
        percWidth: 0,
        sortable: false,
        groupable: false,
        searchable: false,
    },
    {
        header: 'Invoice Contact',
        dataKey: 'invoiceContact',
        percWidth: 0,
        sortable: false,
        groupable: false,
        searchable: false,
    },
    {
        header: 'Invoice Email',
        dataKey: 'invoiceEmail',
        percWidth: 0,
        sortable: false,
        groupable: false,
        searchable: false,
    },
    {
        header: 'Invoice Address 1',
        dataKey: 'invoiceAddress1',
        percWidth: 0,
        sortable: false,
        groupable: false,
        searchable: false,
    },
    {
        header: 'Invoice Address 2',
        dataKey: 'invoiceAddress2',
        percWidth: 0,
        sortable: false,
        groupable: false,
        searchable: false,
    },
    {
        header: 'Invoice Address 3',
        dataKey: 'invoiceAddress3',
        percWidth: 0,
        sortable: false,
        groupable: false,
        searchable: false,
    },
    {
        header: 'Invoice Address 4',
        dataKey: 'invoiceAddress4',
        percWidth: 0,
        sortable: false,
        groupable: false,
        searchable: false,
    },
    {
        header: 'Invoice Address Post Code',
        dataKey: 'invoiceAddressPc',
        percWidth: 0,
        sortable: false,
        groupable: false,
        searchable: false,
    }
];