import { Component, Input, OnInit } from '@angular/core';
import { Organisation } from 'src/app/core/classes/organisation';
import { IDynamicTableColumn } from 'src/app/core/models/dynamic-table-column.vm';
import { ProjectAccessTableColumns } from './organisation-project-access-layouts';
import { LoaderService } from 'src/app/core/services/loader.service';
import { OrganisationService } from 'src/app/core/services/organisation.service';
import { ProjectAccess } from 'src/app/core/models/project-access';
@Component({
  selector: 'app-organisation-project-access',
  templateUrl: './organisation-project-access.component.html',
  styleUrls: ['./organisation-project-access.component.scss']
})
export class OrganisationProjectAccessComponent implements OnInit {

  @Input() public currentOrganisation: Organisation;

  public projectAccessTableColumns: Array<IDynamicTableColumn> = ProjectAccessTableColumns
  public loadingError: string = null;
  public dynamicButtons = [];
  public projectAccessList: ProjectAccess [] = []; 

  constructor(
    public loaderService: LoaderService,
    private organisationService: OrganisationService
  ) { }

  ngOnInit(): void {
    this.getProjectAccessList()
  }

  public getProjectAccessList() {
    this.organisationService.getOrganisationProjectAccessList(this.currentOrganisation.organisationId).subscribe(
      (response: any) => {
        response.data.projectOrganisationAccessList.forEach(p => {
          if (p.allowAccess == null) {
          p.allowAccess = 'Y'
        }})
        this.currentOrganisation.projectAccess = response.data.projectOrganisationAccessList
        this.updateDynamicButtons()
      },
      (error: any) => {
        this.loadingError = error.error.error.message;
      }
    )
  }

  public updateProjectAccessList(body: any) {
    this.organisationService.updateOrganisationProjectAccessList(this.currentOrganisation.organisationId, body).subscribe(
      (response: any) => {    
        this.getProjectAccessList()
      },
      (error: any) => {
        this.loadingError = error.error.error.message;
      }
    )
  }

  public updateDynamicButtons(): void {
    const projectAccessList = this.currentOrganisation.projectAccess;   
    // Check the access status of all projects
    const allAllowed = projectAccessList.every(project => project.allowAccess === 'Y');
    const allRestricted = projectAccessList.every(project => project.allowAccess === 'N');
    // Update dynamic buttons based on the conditions
    if (projectAccessList.length > 1) {
      if (allAllowed) {
        this.dynamicButtons = [
            { name: 'Restrict all', class: 'btn btn-danger' }
        ];
      } else if (allRestricted) {
        this.dynamicButtons = [
            { name: 'Allow all', class: 'btn btn-success' }
        ];
      } else {
        this.dynamicButtons = [
            { name: 'Allow all', class: 'btn btn-success' },
            { name: 'Restrict all', class: 'btn btn-danger' }
        ];
      }
    }
  }

  public onTableTdClick = (projectId: number, action: string): void => {  
    const body = {
      projectList: [projectId],
      allowAccess: action
    }
    this.updateProjectAccessList(body)
  };

  public toggleAllAccessStatuses(event: any) {
    const allowAccess = event.name == 'Allow all' ? 'Y' : 'N' 
    let projectsIDs = []
    this.currentOrganisation.projectAccess.forEach(project => projectsIDs.push(project.projectId))
    const body = {
      projectList: projectsIDs,
      allowAccess: allowAccess
    }
    this.updateProjectAccessList(body)
  }

}