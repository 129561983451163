import { IOrganisationMOU } from "../models/organisation-mou-vm";
import { IOrganisationProduct } from "../models/organisation-product.vm";
import { IOrganisationRegistration } from "../models/organisation-registration.vm";
import { IOrganisationSubmission } from "../models/organisation-submission.vm";
import { IOrganisationUser } from "../models/organisation-user.vm";
import { ProjectAccess } from "../models/project-access";

export class Organisation {
    public isDefunct: string;
    public nhsCode: string;
    public organisationId: number;
    public organisationName: string;
    public contributorCode: number;
    public organisationTypeId: number;
    public organisationTypeName: string;
    public organisationTypeOther: string;
    public organisationUrl: string;
    public postalAddress1: string;
    public postalAddress2: string;
    public postalAddress3: string;
    public postalAddress4: string;
    public postalAddressPc: string;
    public regionId: number;
    public regionName: string;
    public products: Array<IOrganisationProduct>;
    public productsCount: number;
    public users: Array<IOrganisationUser>;
    public registrations: Array<IOrganisationRegistration>;
    public submissions: Array<IOrganisationSubmission>;
    public reportViews: number;
    public documentViews: number;
    public dataSharingConsent: string;
    public productsString: string
    public mouCount: number;
    public mou: Array<IOrganisationMOU>;
    public projectAccess: ProjectAccess[]

    constructor(data: any, year?: number) {
        this.mapOrganisationBasicDetails(data, year);
    }
    
    private mapOrganisationBasicDetails(data: any, year?: number): void {
        this.isDefunct = data.isDefunct;
        this.nhsCode = data.nhsCode;
        this.organisationId = data.organisationId;
        this.organisationName = data.organisationName;
        this.contributorCode = data.contributorCode;
        this.organisationTypeId = data.organisationTypeId;
        this.organisationTypeName = data.organisationTypeName;
        this.organisationTypeOther = data.organisationTypeOther;
        this.organisationUrl = data.organisationUrl;
        this.postalAddress1 = data.postalAddress1;
        this.postalAddress2 = data.postalAddress2;
        this.postalAddress3 = data.postalAddress3;
        this.postalAddress4 = data.postalAddress4;
        this.postalAddressPc = data.postalAddressPc;
        this.regionId = data.regionId;
        this.regionName = data.regionName;
        this.users = data.users || null;
        this.registrations = data.registrations || null;
        this.submissions = data.submissions || null;
        this.reportViews= data.reportViews;
        this.documentViews= data.documentViews;
        this.mouCount = data.MOUCount;
        if (Object.keys(data.products).length != 0) {
            this.products = this.mapProducts(data.products, year) || null;
            this.productsCount = this.products.length;
          }
        if (Object.keys(data.products).length != 0) {
            this.products = this.mapProducts(data.products, year) || null;
            this.productsCount = this.products.length;
          }
        this.dataSharingConsent = data.dataSharingConsent;      
        this.productsString = data.productsString  
    }

    private mapProducts(products: any, year: number): Array<IOrganisationProduct> {
        let { [year]: productsYear } = products;
        if (productsYear) {
            return productsYear;   
        } else {
            return products;
        }
    }
}