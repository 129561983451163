import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoaderService } from '../core/services/loader.service';
import { ILayout } from '../core/models/layout.vm';
import { IDynamicTableColumn } from '../core/models/dynamic-table-column.vm';
import { UsersService } from '../core/services/users.service';  
import { Users } from '../core/classes/users';
import { AddUserForm, topLevelUserNavItems, usersNavItems, UsersTableColumns, UserTableFilters, UserTableUpdate } from './users-layout';
import { IDynamicPresetFilter } from '../core/models/dynamic-table-filter.vm';
import { Layout } from '../core/classes/layout';
import { INavItem } from '../core/models/nav-item.vm';
import { IUserRole } from '../core/models/user-role.vm';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IDynamicChangeList } from '../core/models/dynamic-table-change-list.vm';
import { User } from '../core/classes/user';
import { IDynamicFormBlock } from '../core/models/dynamic-form-block.vm';
import { IUserUpdate } from '../core/models/user.vm';
import { IAlert } from '../core/models/alert';


@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit, OnDestroy {
  public currentUrlParams: any;
  public loadingError: string = null;
  public layout: ILayout;
  public usersTableColumns: Array<IDynamicTableColumn> = UsersTableColumns;
  public addUserForm: Array<IDynamicFormBlock> = AddUserForm;
  public userList: Array<Users> = new Array<Users>();
  public currentUser: any = null
  public currentUserLoaded: boolean = false
  public currentUserRole: IUserRole = null;
  public presetFilters: Array<IDynamicPresetFilter> = UserTableFilters;
  public userTableUpdate: Array<IDynamicChangeList> = UserTableUpdate;
  public usersNavItems: Array<INavItem> = usersNavItems;
  public topLevelUserNavItems: Array<INavItem> = topLevelUserNavItems;
  public currentSection: string = undefined;
  public roleLayout: ILayout;
  private unsubscribe = new Subject();
  public alert: IAlert;
  public addUserLayout: ILayout = null;
  public newUser: IUserUpdate = null;
  public tableData: any
  public roles = []
  public products = []
  public isRoleAndProductsSliderOpened: boolean;
  public acceptedRoles: any[];
  // public dynamicButton: { class: string; name: string } = 
  // {
  //   class: 'btn btn-success',
  //   name: 'Add User'
  // }
  public dynamicButtons = [
    { name: 'Add User', class: 'btn btn-success' }   
  ];

  constructor(
    public loaderService: LoaderService,
    private UsersService: UsersService,
    private route: ActivatedRoute,
    private router: Router,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.currentSection = params.section;
      this.currentUrlParams = params;
      this.routeChange(params);
    });
    this.resetUserDetails();
    this.newUser.globalSubscribe = "N"
    this.newUser.roles = [];
  }

  ngOnDestroy(): void {
    this.unsubscribe.next()
    this.unsubscribe.complete()
  }

  resetUserDetails() {
    this.newUser = {
      fullName: null,
      jobTitle: null,
      logonName: null,
      phoneNo: null,
      isAdmin: null,
      organisationId: null,
      globalSubscribe: 'N',
      roles: []
    };
    this.roles = null
    this.products = null
    this.tableData = null
  }

  public routeChange(params) {
    this.renderer.setProperty(document.documentElement, 'scrollTop', 0)
    // No User selected, return to list
    if (params.section == undefined && this.userList.length == 0) {
      this.getUserList("Active", null, null);
    }
    // User selected, get details and show section
    if (params.section) {
      this.getUserDetails(this.currentUrlParams.id);
    }
  }

  private getUserList(recentlyActiveUsers?: string, adminUsers?: string, activeUsers?: string): void {
    this.currentUser = null;
    this.UsersService.getUsers(recentlyActiveUsers, adminUsers, activeUsers).pipe(takeUntil(this.unsubscribe)).subscribe(
      (success) => {
        success.data.userList.forEach((users) => {
          this.userList.push(new Users(users));
        });
        // replace all the 'null'-'undefined'-'' values with '—', in order to make the sort funcionality in the dynamic table work correctly
        this.userList.forEach((obj) => {
          Object.keys(obj).forEach((key) => {
            let { [key as keyof typeof obj]: objKey } = obj;
            if (objKey === null || objKey === undefined || objKey === '' || objKey === ' ') {
              Object.defineProperty(obj, key, {
                value: '—',
                enumerable: true,
                writable: true
              });
            }
          });
        });
        //
        this.layout = new Layout('Users', null, null);
      },
      (error) => {
        console.log('Error: ', error);
        this.loadingError = error.error.error.message;
      }
    );
  }

  public openAddUserSlider() {
    this.addUserLayout = new Layout(
      "Add User", [], null
    )
  }

  public closeAddUserSlider = (): void => {
    this.addUserLayout = null;
    this.resetUserDetails();
  }

  public dataUpdate(data: any) {
    if(data.action == 'add'){
      this.newUser.organisationId = data.organisation || null
      this.newUser.fullName = data.row.fullName || null
      this.newUser.jobTitle = data.row.jobTitle || null
      this.newUser.logonName = data.row.logonName || null
      this.newUser.phoneNo = data.row.phoneNo || null
      this.newUser.isAdmin = data.row.isAdmin || null
      this.newUser.globalSubscribe = data.row.globalSubscribe || null

      this.getProducts(data.organisation)
    } else {
      let newUserDetails: IUserUpdate = data.row;
      newUserDetails.organisationId = data.organisation
      newUserDetails.roles = this.acceptedRoles ? this.acceptedRoles : [] 
      this.UsersService.addUser(newUserDetails).subscribe(
        success => {
          this.alert = {
            message: `<strong>User added Successfully</strong>`,
            alertClass: 'success',
            fadeOut: true,
          };
          this.router.navigate([`/users/${ success.data[0].userId }/details`]);
        },(error) => {
          this.alert = {
            message: `<strong>User added Failed</strong> <br/> ${ error.error.error.message }`,
            alertClass: 'danger',
            fadeOut: true,
          };
          this.resetUserDetails();
        }
      )
    }
  }

  public onTableRowClick = (primaryKey: any): void => {
    this.router.navigate(['users', primaryKey, "details"]);
    
  };

  public changeUsersList = (name: string): void => {
    this.userList = [];
    if(name == "Inactive") {
      this.userTableUpdate[0].hideValue = true;
      this.userTableUpdate[1].hideValue = false;
    } else {
      this.userTableUpdate[0].hideValue = false;
      this.userTableUpdate[1].hideValue = true;
    }
    this.getUserList(name, null, null);
  };

  private getUserDetails(userId): void {
    if (!this.currentUserLoaded){
      this.UsersService.getUsersDetails(userId).subscribe(
        (success) => {
          this.currentUser = new User(success.data);
          this.currentUserLoaded = !this.currentUserLoaded;
          this.layout = new Layout(
            this.currentUser.fullName,
            [ `ID: ${this.currentUser.userId}`, `Email: ${this.currentUser.logonName}` ],
            `/users`
            );
          },
          (error) => {
            console.log('Error: ', error);
            this.loadingError = error.error.error.message;
          }
          );
        }
  }

  public getRoles() {
    this.UsersService.getRoles().subscribe( (success) => {
      const roles = success.roles.map(r => ({
        id: r.role_type,
        name: r.role_name
      }));   
      this.roles = roles
    },
    (error) => {
      console.log('Error: ', error);
    }
  )
  }

  public getProducts(organisationId: number) {
    this.UsersService.getProducts(organisationId).subscribe( (success) => {
      const products = success.data.map(r => ({
        id: r.productId,
        name: r.productName
      })); 
      this.products = products
      this.getRoles()
    },
    (error) => {
      console.log('Error: ', error);
    }
  )
  }

  public addTableData(e: { product: number, roles: number[] }[]) {
    if (this.tableData && this.tableData.length > 0) {
      e.forEach(newItem => {
        const existingItemIndex = this.tableData.findIndex(item => item.product === newItem.product);
        if (existingItemIndex !== -1) {
          // Product already exists, update roles if not already present
          newItem.roles.forEach(role => {
            if (!this.tableData[existingItemIndex].roles.includes(role)) {
              this.tableData[existingItemIndex].roles.push(role);
            }
          });
        } else {
          // Product does not exist, add it to the tableData
          this.tableData.push(newItem);
        }
      });
    } else {
      // If tableData is empty, simply assign the incoming data
      this.tableData = e;
    }
    this.transformRoles(this.tableData)
  }

  public removeTable() {
    this.tableData = null
  }

  
  public onRolesClick = () => {
    this.isRoleAndProductsSliderOpened = true
  }

  public closeRolesSlider = () => {
    this.isRoleAndProductsSliderOpened = false
  }

  transformRoles(tableData: any) {
    this.acceptedRoles = [];
    tableData.forEach((obj) => {
      const roles = obj.roles.map(role => ({
        productId: obj.product,
        roleType: role,
        projectId: null,
        serviceItemId: null,
        submissionId: null
    }));
    this.acceptedRoles = this.acceptedRoles.concat(roles); 
    })

  }
  
}

