<ng-container *ngIf="currentYear">
    <div class="container-fluid">
        <div class="row justify-content-center pb-5">
            <div class="col-md-11 col-12">

                <div class="row mt-5 mb-3">
                    <div class="col-md-7 col-12" *ngIf="layout">
                        <app-area-heading [heading]="layout.heading" [subheading]="layout.subheading" [backRoute]="layout.backRoute"></app-area-heading>
                    </div>
                    <div class="col-md-5 col-12 d-flex">
                        <div class="ml-auto">
                            <app-year-select (emitCurrentYear)="yearChange($event)"></app-year-select>
                        </div>
                    </div>
                </div>

                <!-- Loading and error -->
                <app-loading-status *ngIf="loaderService.isLoading | async; else notLoading"></app-loading-status>
                <app-alert-status *ngIf="alert" [alert]="alert" [parent]="componentName" (emitAlertCancelled)="alert = $event"></app-alert-status>
      
                <ng-template #notLoading>
                    <!-- Info -->
                    <div class="alert alert-info mb-4">
                        <i class="fas fa-info-circle mr-1"></i><strong>Static document links are created when you upload them to the admin tool.</strong> These links are 'static' and do not expire. The intention is that these documents can be shared openly between member and non-member organisations. Categories include promotional materials, guidance information, and generic tools and documentation.
                    </div>
                    <!-- Table -->
                    <app-dynamic-table 
                        *ngIf="awsDocumentsStatic" 
                        [data]="awsDocumentsStatic" 
                        [columns]="staticDocumentsTableColumns" 
                        [currentYear]="currentYear"
                        [tableTitle]="'staticDocumentsList'"
                        [primaryKey]="'documentId'"
                        [onRowClick]="onTableRowClick"
                        [dynamicButtons]="dynamicButtons"
                        (buttonClicked)="newDocumentClick()">
                    </app-dynamic-table>
                </ng-template>
                
                <!-- Selected Document -->
                <app-slider-panel *ngIf="documentLayout" [onClose]="closeQuestionSlider">
                    <app-area-heading [heading]="documentLayout.heading" [subheading]="documentLayout.subheading"></app-area-heading>
                    <app-dynamic-form [data]="displayedDocuments" [blocks]="questionGroupForm"></app-dynamic-form>
                </app-slider-panel>
                
                <!-- New Document -->
                <app-slider-panel *ngIf="newDocumentLayout" [onClose]="closeQuestionSlider">
                    <app-area-heading [heading]="newDocumentLayout.heading" [subheading]="newDocumentLayout.subheading"></app-area-heading>                
                    <app-drag-and-drop-uploader
                        (uploadFile)="uploadFile($event)"
                    ></app-drag-and-drop-uploader>
                </app-slider-panel>

            </div>
        </div>
    </div>            
</ng-container>