import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IDynamicFormBlock } from 'src/app/core/models/dynamic-form-block.vm';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { IDynamicFormRule } from 'src/app/core/models/dynamic-form-rule.vm';
import { Project } from 'src/app/core/classes/project';
import { IPopupModal } from 'src/app/core/models/popup-modal.vm';

@Component({
  selector: 'app-dynamic-form-array',
  templateUrl: './dynamic-form-array.component.html',
  styleUrls: ['./dynamic-form-array.component.scss']
})
export class DynamicFormArrayComponent implements OnChanges {

  @Input() public form: FormGroup;
  @Input() public block: IDynamicFormBlock;
  @Input() public data: any;
  @Input() public rules: IDynamicFormRule;
  @Output() public removeItem = new EventEmitter<number>();
  @Output() id = new EventEmitter<object>();
  
  darkMode$: Observable<boolean>;

  public modalInfo: IPopupModal = null;
  public item: number;

  constructor(
    private store: Store<{ darkMode: boolean }>
  ) { 
    this.darkMode$ = store.select('darkMode')
  }

  ngOnChanges(): void { }

  closeModal(event: boolean) {
    if(event == true) {
      this.removeItem.emit(this.item);
    }
    this.modalInfo = null;
  }

  removeSelectedItem(row: number, modalSubText: string, itemTitle: string) {
    let { [this.rules.dataKey]: dataRules } = this.data;
    let { [row]: dataRow } = dataRules;
    let { [this.rules.optionsType]: rules } = dataRow;
    let itemName = rules  || "***"
    this.item = row;
    this.modalInfo = {
      item: itemName, 
      subText: `Are you sure you want to delete ${modalSubText}`, 
      title: itemTitle,
      warning:`All information associated to this ${itemTitle} will be permanently deleted.`,
      warningRed:"This operation can not be undone.",
      request: 'Delete'
    };
  }

  idCheck(event, count){
    let plusOneCount = count + 1
    this.id.emit({name: event, count: plusOneCount})
  }
}
