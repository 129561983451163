import { IDynamicTableColumn } from "src/app/core/models/dynamic-table-column.vm";

export const ProjectAccessTableColumns: IDynamicTableColumn[] = [
    {  header: 'Project Name',
        dataKey: 'projectName',
        percWidth: 30,
        sortable: true,
        groupable: true,
        searchable: true,
    },
    {
        header: 'Project ID',
        dataKey: 'projectId',
        percWidth: 20,
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Access Control Justification',
        dataKey: 'accessControlJustification',
        percWidth: 30,
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Allow Access',
        dataKey: 'allowAccess',
        percWidth: 20,
        sortable: true,
        groupable: true,
        searchable: true,
        badge: 'yesNo'
    },
    {
        header: 'Action',
        percWidth: 7,
        dataKey: '',
        sortable: false,
        groupable: false,
        searchable: false,
        exportable: false,
        options: ['clickable'],
        button: 'options',
        buttonType: 'projectAccess',
        actions: [         
          { 
            icon: 'fas fa-undo ml-3 mr-2',
            color: '#3c763d',
            action: 'Y',
            description: 'Allow Access',
          },
          {
            icon: 'fas fa-undo ml-3',
            color: '#a94442',
            action: 'N',
            description: 'Restrict Access',
          },
        ],
    },
];