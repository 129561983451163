import { Component, Input, OnChanges, Output } from '@angular/core';
import { Event } from 'src/app/core/classes/event';
import { eventDetailNavItems } from '../events-layouts';
import { INavItem } from 'src/app/core/models/nav-item.vm';
import { LoaderService } from 'src/app/core/services/loader.service';
import { ActivatedRoute } from '@angular/router';
import { EventsService } from 'src/app/core/services/events.service';
import { IDynamicFormBlock } from 'src/app/core/models/dynamic-form-block.vm';
import { EventDetailBasics } from './event-detail-layout';
import { IAlert } from 'src/app/core/models/alert';

@Component({
  selector: 'app-event-detail',
  templateUrl: './event-detail.component.html',
  styleUrls: ['./event-detail.component.scss'],
})
export class EventDetailComponent implements OnChanges {
  @Input() public currentEvent: Event;

  public currentQueryParams: any;
  public currentScope: string = null;
  public loadingError: string = null;
  public eventDetailBasicsBlocks: Array<IDynamicFormBlock> = EventDetailBasics;
  public eventDetailNavItems: Array<INavItem> = eventDetailNavItems;
  public alert: IAlert;
  public componentName = 'event-detail'
  public isEventUpdate: boolean;

  constructor(
    public loaderService: LoaderService,
    public eventsService: EventsService,
    private route: ActivatedRoute,
  ) {}

  ngOnChanges() {
    this.route.queryParams.subscribe((params) => {
      this.currentQueryParams = params;
      this.scopeChange(this.currentQueryParams);
    });
  }

  private scopeChange(params: any): void {
    this.currentScope = params.scope || 'basics';
  }

  public dataUpdate(data) {
    const eventDetails = {
      eventDescription: data.row.eventDescription,
      eventDt: data.row.eventDt.replace("T", " ") + ":00",
      eventLocation: data.row.eventLocation,
      eventName: data.row.eventName,
      isOnline: data.row.isOnline,
      isVisible: data.row.isVisible,
      maxCapacity: data.row.maxCapacity,
      meetingDescription: data.row.meeting_description,
      meetingId: data.row.meetingId,
      meetingPasscode: data.row.meetingPasscode != '' ? data.row.meetingPasscode : '—',
      meetingUrl: data.row.meetingUrl != '' ? data.row.meetingUrl : '—',
      placesPerOrganisation: data.row.placesPerOrganisation,
      projectCategory: data.row.projectCategory,
      registrationEnabled: data.row.registrationEnabled
    };
    this.eventsService.updateEvent(this.currentEvent.eventId, eventDetails).subscribe(
      (success) => {
        this.isEventUpdate = true;
        this.alert = {
          message: `<strong>Event details successfully updated</strong>`,
          alertClass: 'success',
          fadeOut: true,
        };   
      },
      (error) => {
        console.log('Error: ', error);
        this.loadingError = error.error.error.message;
      }
    );   
  }

}
