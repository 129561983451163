import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class EventsService {
  private nhsApiUrl: string = environment.nhsApiUrl;

  constructor(private http: HttpClient, public auth: AuthService) {}

  //NHS
  public getEvents(userId?: number): Observable<any> {
    return this.http.get(this.nhsApiUrl + 'events', {
      headers: new HttpHeaders().set('Authorization', this.auth.token()),
    });
  }

  public getEventDynamicDocuments(eventId: number): Observable<any> {
    return this.http.get(
      this.nhsApiUrl + 'events/' + eventId + '/awsDocuments',
      {
        headers: new HttpHeaders().set('Authorization', this.auth.token()),
      }
    );
  }

  public createEvent(body: object): Observable<any> {
    return this.http.post(
      this.nhsApiUrl + 'events/addevent', body, {
        headers: new HttpHeaders().set('Authorization', this.auth.token()).set('Content-Type', 'application/json'),
      }
    );
  } 

  public updateEvent(eventId: number, body: object): Observable<any> {
    return this.http.patch(
      this.nhsApiUrl + 'events/' + eventId, body, {
        headers: new HttpHeaders().set('Authorization', this.auth.token()).set('Content-Type', 'application/json'),
      }
    );
  }
    
  public getEventDynamicDocument(documentId: number): Observable<any> {
    return this.http.get(
      this.nhsApiUrl + 'awsDocuments/' + documentId + '/download',
      {
        headers: new HttpHeaders().set('Authorization', this.auth.token())
      }
    );
  }

  public getEventRegistration(eventId: number, year?): Observable<any> {
    return this.http.get(
      this.nhsApiUrl + 'events/' + eventId + '/registrationList',
      {
        headers: new HttpHeaders().set('Authorization', this.auth.token()),
      }
    );
  }

  public getNonMemberEventRegistrations(eventId: number): Observable<any> {
    return this.http.get(
      this.nhsApiUrl + 'events/' + eventId + '/nonMembers/registrations',
      {
        headers: new HttpHeaders().set('Authorization', this.auth.token()),
      }
    );
  }

  public getEventAttendee(eventId: number, year?): Observable<any> {
    return this.http.get(
      this.nhsApiUrl + 'events/' + eventId + '/attendeesList',
      {
        headers: new HttpHeaders().set('Authorization', this.auth.token()),
      }
    );
  }
  
  public rejectNonMemberEventRegistrationRequest(requestId: any, body: object): Observable<any> {
    return this.http.patch(
      this.nhsApiUrl + 'events/nonMembers/registrations/'+ requestId +'/reject', body,{
        headers: new HttpHeaders().set('Authorization', this.auth.token()).set('Content-Type', 'application/json'),
      }
    );
  }

  public acceptNonMemberEventRegistrationRequest(requestId: any, body: object): Observable<any> {
    return this.http.post(
      this.nhsApiUrl + 'events/nonMembers/registrations/' + requestId + '/accept', body ,{
        headers: new HttpHeaders().set('Authorization', this.auth.token()).set('Content-Type', 'application/json'),
      }
    );
  }
  
  public addDelegate(body: object, eventId: number): Observable<any> {
    return this.http.post(
      this.nhsApiUrl + 'events/' + eventId + '/addDelegate', body ,{
        headers: new HttpHeaders().set('Authorization', this.auth.token()).set('Content-Type', 'application/json'),
      }
    );
  }

  public editDelegate(body: object): Observable<any> {
    return this.http.patch(
      this.nhsApiUrl + 'events/registration', body ,{
        headers: new HttpHeaders().set('Authorization', this.auth.token()).set('Content-Type', 'application/json'),
      }
    );
  }
}
