<ng-template
  #modal
  let-c="close"
  let-d="dismiss"
  *ngIf="{ darkMode: darkMode$ | async } as ui"
>
  <div [ngClass]="{ 'bg-dark-800': ui.darkMode, 'border-gray': ui.darkMode }">
    <div class="modal-header" [ngClass]="{ 'text-light': ui.darkMode }">
      <h4 class="modal-title" id="modal-title">Request a change</h4>
      <i
        class="fal fa-lg fa-times c-pointer"
        (click)="d('Cross click'); closeModal(false)"
        [ngClass]="{ 'text-danger': ui.darkMode }"
      ></i>
    </div>
    <div class="modal-body">
      <ng-container *ngIf="!selectedBoard">
        <p>Which system are you requesting a change for?</p>
        <div>
          <button
            *ngFor="let board of boards"
            class="btn btn-primary mr-2 mb-2"
            (click)="renderBoard(board)"
          >
            {{ board.name }}
          </button>
        </div>
      </ng-container>
      <div data-canny></div>
    </div>
  </div>
</ng-template>
