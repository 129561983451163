<!-- Loading and error -->
<app-loading-status *ngIf="loaderService.isLoading | async; else notLoading"></app-loading-status>
<app-alert-status *ngIf="loadingError" [loadingError]="loadingError"></app-alert-status>

<ng-template  #notLoading>
    <ng-container *ngIf="!currentProduct">
  <div class="container-fluid">
    <div class="row justify-content-center pt-5">
      <div class="col-md-10 col-12">
      <div class="row mt-5 mb-3">
        <div class="col-md-5 col-12" *ngIf="layout" style="padding-left: 30px;">
          <app-area-heading [heading]="layout.heading" [subheading]="layout.subheading"
            [backRoute]="layout.backRoute"></app-area-heading>
        </div>
        
        <div class="col-md-6 col-12 d-flex" style="padding-right: 28px;">
          <div class="ml-auto">
            <app-year-select (emitCurrentYear)="yearChange($event)"></app-year-select>
          </div>
        </div>
      </div>
      <div class="col-md-11 col-12">
        <div class="row" *ngIf="products">
          <div class="col-md-4 mb-3 d-flex align-items-stretch" *ngFor="let product of products">
            <div class="card" style="width: 40rem; border:2px solid rgba(0, 0, 0, 0.125)" (click)="openProduct(product)">
              <div class="card-body d-flex flex-column">
                <div class="d-flex justify-content-between pb-2">
                  <h3 class="card-title mt-1" style="color: #003087; font-weight: 600;">{{ product.productName }}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
    </div>
</div>
</ng-container>

</ng-template>

<ng-container *ngIf="currentProduct">
    <app-products-detail [currentProduct]="currentProduct" [currentYear]="currentYear"></app-products-detail>
</ng-container>
