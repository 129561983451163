import { Component, Input, OnChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Layout } from 'src/app/core/classes/layout';

import { Project } from 'src/app/core/classes/project';
import { IDynamicFormBlock } from 'src/app/core/models/dynamic-form-block.vm';
import { IDynamicTableColumn } from 'src/app/core/models/dynamic-table-column.vm';
import { ILayout } from 'src/app/core/models/layout.vm';
import { IProjectSubmission } from 'src/app/core/models/project-submission.vm';
import { LoaderService } from 'src/app/core/services/loader.service';

import { ProjectService } from 'src/app/core/services/project.service';

import {
  CaseNoteCodeTableColumns,
  DataUploadTableColumns,
  editHistoryTable,
  ServiceTableColumns,
  SubmissionDetailsForm,
  SubmissionTableColumns,
} from './project-submission-layouts';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ISubmissionDetails } from 'src/app/core/models/submissionDetails';
import { SubmissionData } from 'src/app/core/models/submissionData';
import { ISuccessResponse } from 'src/app/core/models/apiResponses';

@Component({
  selector: 'app-project-submission',
  templateUrl: './project-submission.component.html',
  styleUrls: ['./project-submission.component.scss'],
})
export class ProjectSubmissionComponent implements OnChanges {
  @Input() currentProject: Project;
  @Input() currentYear: number;

  private currentQueryParams: any;

  public layout: ILayout;

  public selectedSubmission: IProjectSubmission;
  public submissionDetails: ISubmissionDetails;
  public submissionTableColumns: Array<IDynamicTableColumn> =
    SubmissionTableColumns;
  public caseNoteCodeTableColumns: Array<IDynamicTableColumn> =
    CaseNoteCodeTableColumns;
  public serviceTableColumns: Array<IDynamicTableColumn> = ServiceTableColumns;
  public editHistoryTable: Array<IDynamicTableColumn> = editHistoryTable;
  public SubmissionDetailsForm: Array<IDynamicFormBlock> =
    SubmissionDetailsForm;
  public submissionId: number;
  public dataUploadTableColumns: Array<IDynamicTableColumn> =
    DataUploadTableColumns;

  public loadingError: string;
  public alert: Object;
  public modalInfo: Object = null;
  public primaryKey: number;
  darkMode$: Observable<boolean>;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private projectService: ProjectService,
    public loaderService: LoaderService,
    private store: Store<{ darkMode: boolean }>
  ) {
    this.darkMode$ = store.select('darkMode');
  }

  ngOnChanges(): void {
    // Check for open registration
    this.route.queryParams.subscribe((params) => {
      this.currentQueryParams = params;
    });
    // Get submissions, if needed
    if (!this.currentProject.submissions) {
      this.getProjectSubmissions(
        this.currentProject.projectId,
        this.currentYear
      );
    }
  }

  private getProjectSubmissions(projectId: number, currentYear: number): void {
    this.projectService.getProjectSubmissions(projectId, currentYear).subscribe(
      (success) => {
        let { [currentYear]: currentYearSubmissions } =
          success.data.submissionList;
        if (currentYearSubmissions) {
          this.currentProject.submissions = currentYearSubmissions;
        } else {
          this.currentProject.submissions = [];
        }
        // Open selected, if in params
        if (this.currentQueryParams.submission) {
          this.onSubmissionClick(this.currentQueryParams.submission);
        }
      },
      (error) => {
        console.log('Error: ', error);
        this.loadingError = error.error.error.message;
      }
    );
  }

  public dataUpdate(event) {
    this.renameSubmission(event.row.submissionName);
  }

  public onTableTdClick = (primaryKey: any, action: string): void => {
    this.primaryKey = primaryKey;
    if (action == 'edit') {
      this.onSubmissionClick(primaryKey);
    }
    if (action == 'delete') {
      this.deleteSubmissionModal(primaryKey);
    }
  };

  public deleteSubmissionModal(primaryKey: number): void {
    let selectedSubmission = this.currentProject.submissions.find(
      (sub) => sub.submissionId == +primaryKey
    );
    this.modalInfo = {
      item: selectedSubmission.submissionName,
      subText: ' Are you sure you want to delete the submission: ',
      title: 'Submission',
      action: 'deletion',
      warning:'All information associated to this submission will be permanently deleted.',
      warningRed: "This operation can not be undone.",
      request: 'Delete',
    };
  }

  closeModal(value: boolean) {
    if (value == true) {
      this.deleteSubmission(this.primaryKey);
    }
    this.modalInfo = null;
  }

  public deleteSubmission(primaryKey) {
    this.projectService.deleteSubmission(primaryKey).subscribe(
      (success) => {
        this.getProjectSubmissions(
          this.currentProject.projectId,
          this.currentYear
        );
        this.alert = {
          message: 'Submission successfully deleted',
          alertClass: 'success',
          fadeOut: true,
        };
      },
      (error) => {
        this.alert = {
          message: 'Deletion Failed',
          alertClass: 'danger',
          fadeOut: true,
        };
        console.log('Error: ', error);
      }
    );
  }

  public renameSubmission(sub) {
    let body = {
      newSubmissionName: sub,
      submissionId: this.selectedSubmission.submissionId,
    };
    this.projectService.renameSubmission(body).subscribe(
      (success) => {
        this.getProjectSubmissions(
          this.currentProject.projectId,
          this.currentYear
        );
      },
      (error) => {
        this.alert = {
          message: error.error.error.message,
          alertClass: 'danger',
          fadeOut: true,
        };
        console.log('Error: ', error);
      }
    );
  }

  public updateSubmission(sub: ISubmissionDetails) {
    let body = sub;
    this.projectService.updateSubmission(body).subscribe(
      (success: ISuccessResponse) => {
        this.alert = {
          alertClass: 'success',
          fadeOut: true,
          message: success.message,
        };
        this.getProjectSubmissions(
          this.currentProject.projectId,
          this.currentYear
        );
      },
      (error) => {
        this.alert = {
          message: error.error.error.message || error.statusText,
          alertClass: 'danger',
          fadeOut: true,
        };
        console.log({ error });
      }
    );
  }

  public onSubmissionClick = (primaryKey: string): void => {
    this.selectedSubmission = this.currentProject.submissions.find(
      (sub) => sub.submissionId == +primaryKey
    );
    this.selectedSubmission.renameSubmission = true;
    this.layout = new Layout(
      this.selectedSubmission.submissionName,
      [
        `ID: ${this.selectedSubmission.submissionId}`,
        `Organisation: ${this.selectedSubmission.organisationName} (ID ${this.selectedSubmission.organisationId})`,
      ],
      null
    );
    this.router.navigate([], {
      queryParams: { submission: primaryKey },
      queryParamsHandling: 'merge',
    });
    this.getSubmissionServices(+primaryKey);
    this.getSubmissionEditHistory(this.selectedSubmission.submissionId);
    this.getSubmissionAllUploads(+primaryKey);
  };

  private getSubmissionCaseCodes(
    primaryKey: number,
    selectedSubmission: IProjectSubmission
  ): void {
    this.projectService.getProjectCaseCodes(primaryKey).subscribe(
      (success) => {
        this.selectedSubmission = this.currentProject.setCaseNoteCodes(
          selectedSubmission,
          success.data
        );
      },
      (error) => {
        console.log('Error: ', error);
        this.loadingError = error.error.error.message;
      }
    );
  }

  private getSubmissionServices(primaryKey: number): void {
    this.projectService.getSubmissionServices(primaryKey).subscribe(
      (success) => {
        this.selectedSubmission.services = success.data;
        this.getSubmissionCaseCodes(+primaryKey, this.selectedSubmission);
      },
      (error) => {
        console.log('Error: ', error);
        this.loadingError = error.error.error.message;
      }
    );
  }

  private getSubmissionEditHistory(submissionId: number): void {
    this.projectService
      .getProjectSubmissionsEditHistory(submissionId)
      .subscribe(
        (success) => {
          this.selectedSubmission.editHistory = success.data.editList;
        },
        (error) => {
          console.log('Error: ', error);
          this.loadingError = error.error.error.message;
        }
      );
  }

  private getSubmissionAllUploads(primaryKey: number): void {
    this.projectService.getAllUploads(primaryKey).subscribe(
      (success) => {
        this.selectedSubmission.allUploads = success.uploadedFiles;
      },
      (error) => {
        console.log('Error: ', error);
        this.loadingError = error.error.error.message;
      }
    );
  }

  public closeSubmissionSlider = (): void => {
    this.selectedSubmission = null;
    this.router.navigate([], {
      queryParams: { submission: null },
      queryParamsHandling: 'merge',
    });
  };

  public onCaseCodeClick = (primaryKey: string): void => {
    console.log(primaryKey);
  };

  public onServiceClick = (primaryKey: string): void => {
    console.log(primaryKey);
  };

  public submissionUpdate(event: SubmissionData) {
    const { peerGroupId, submissionCodeNumber, submissionName } = event.row;
    this.updateSubmission({
      peerGroupId: Number(peerGroupId),
      submissionCode: Number(submissionCodeNumber),
      submissionName,
      submissionId: this.primaryKey,
    });
  }
}
