

import { IDynamicTableColumn } from "src/app/core/models/dynamic-table-column.vm";

export const ProductsTableColumns: IDynamicTableColumn[] = [
    {
        header: 'ID',
        dataKey: 'organisationId',
        percWidth: 10,
        sortable: true,
        groupable: false,
        searchable: true,
    },
    {
        header: 'Debtor',
        dataKey: 'debtor',
        percWidth: 10,
        sortable: true,
        groupable: true,
        searchable: true,
        badge: 'yesNo'
    },
    {
        header: 'Name',
        dataKey: 'organisationName',
        percWidth: 30,
        sortable: true,
        groupable: false,
        searchable: true,
    },
    {
        header: 'Membership Status',
        percWidth: 10,
        dataKey: 'membershipStatus',
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Charging Profile',
        percWidth: 10,
        dataKey: 'chargingProfile',
        sortable: true,
        groupable: true,
        searchable: false,
    },
    {
        header: 'Charging Band',
        dataKey: 'chargingProfileBandId',
        percWidth: 5,
        sortable: true,
        groupable: true,
        searchable: false,
    },
    {
        header: 'Fee',
        dataKey: 'bandFee',
        percWidth: 10,
        sortable: true,
        groupable: true,
        searchable: false
    },
];