<ng-template #modal let-c="close" let-d="dismiss" *ngIf="{ darkMode: darkMode$ | async } as ui">
  <div [ngClass]="{'bg-dark-800' : ui.darkMode, 'border-gray': ui.darkMode}">
    <div class="modal-header" [ngClass]="{'text-light': ui.darkMode}">
      <h4 class="modal-title" id="modal-title">{{ modalInfo.title }} {{ modalInfo.action }}</h4>
      <i
        class="fal fa-2x fa-window-close c-pointer"
        (click)="d('Cross click'); closeModal(false)"
        [ngClass]="{'text-danger': ui.darkMode}"
      ></i>
    </div>
    <div class="modal-body">
      <p [ngClass]="{'text-light': ui.darkMode}">
        <strong>
          <span>{{ modalInfo.subText }}</span>
          <span class="text-primary">{{ modalInfo.item }}</span>?
        </strong
        >
      </p>
      <p>
        <span *ngIf="modalInfo.warning">{{modalInfo.warning}} </span>        
        <br *ngIf="modalInfo.warning && modalInfo.warningRed">
        <span *ngIf="modalInfo.warningRed" class="text-danger">{{modalInfo.warningRed}}</span>
      </p>
      <div *ngIf="modalInfo.comment">
        <textarea required [(ngModel)]="myTextarea" (ngModelChange)="onValueChange($event)" style="width: 470px; height: 100px; padding: 7px;"></textarea>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-secondary"
        (click)="d('Cancel click'); closeModal(false)"
      >
        Cancel
      </button>
      <button 
        [disabled]="modalInfo.comment && !myTextarea"
        type="button"
        class="btn btn-outline-danger"
        (click)="c('Delete click'); closeModal(true)"
      >
      {{ modalInfo.request }}
      </button>
    </div>
  </div>
</ng-template>
