export interface ConstantType {
  id: string | number;
  name: string;
  example?: string;
  displaySequence?: number
}

export class Constants {
  public static readonly categoryColors = {
    acute: '#c11c1c',
    allSectors: '#768692',
    commissioning: '#fcc000',
    community: '#312783',
    mentalHealth: '#95c11f',
  };

  public static readonly yesNo: Array<ConstantType> = [
    { id: 'Y', name: 'Yes' },
    { id: 'N', name: 'No' },
    { id: null, name: '-' },
  ];

  public static readonly dietaryRequirements: Array<ConstantType> = [
    { id: 'O', name: 'Yes' },
    { id: 'N', name: 'No' },
    { id: null, name: '-' },
  ];

  public static readonly registrationStatus: Array<ConstantType> = [
    { id: 'R', name: 'Registered' },
    { id: 'W', name: 'Waiting' },
    { id: 'D', name: 'Withdrawn' },
    { id: null, name: '-' },
  ];

  public static readonly attendedStatus: Array<ConstantType> = [
    { id: 'Y', name: 'Yes' },
    { id: 'N', name: 'No' },
    { id: null, name: '-' },
  ];

   public static readonly eventSectors: Array<ConstantType> = [
    {
        id: 1,
        name: "Community",
        displaySequence: 6
    },
    {
        id: 2,
        name: "Acute",
        displaySequence: 4
    },
    {
        id: 3,
        name: "Mental Health",
        displaySequence: 7
    },
    {
        id: 4,
        name: "Commissioning",
        displaySequence: 13
    },
    {
        id: 5,
        name: "All Sectors",
        displaySequence: 3
    },
    {
        id: 6,
        name: "NAIC",
        displaySequence: 11
    },
    {
        id: 7,
        name: "NACEL",
        displaySequence: 9
    },
    {
        id: 8,
        name: "NHS England Learning Disability Improvement Standards",
        displaySequence: 10
    },
    {
        id: 9,
        name: "Monthly Indicators",
        displaySequence: 2
    },
    {
        id: 10,
        name: "Primary Care",
        displaySequence: 12
    },
    {
        id: 11,
        name: "South East Mental Health Dashboard",
        displaySequence: 1
    },
    {
        id: 12,
        name: "Acute and Community",
        displaySequence: 5
    },
    {
        id: 13,
        name: "Workforce Programme",
        displaySequence: 8
    },
    {
        id: 14,
        name: "Multi-sector Outputs",
        displaySequence: 14
    }
  ]

  // Needs to be an API call
  public static readonly categories: Array<ConstantType> = [
    { id: 1, name: 'Community' },
    { id: 2, name: 'Acute' },
    { id: 3, name: 'Mental Health' },
    { id: 4, name: 'Commissioning' },
    { id: 5, name: 'All Sectors' },
    { id: 6, name: 'NAIC' },
    { id: 7, name: 'NACEL' },
    { id: 8, name: 'NHSE & NHSI - Learning Disabilities' },
    { id: 9, name: 'Monthly Covid-19 Dashboards' },
    { id: 10, name: 'Primary Care' },
  ];

  // Needs to be an API call
  public static readonly products: Array<ConstantType> = [
    { id: 1, name: 'NHS Benchmarking Network' },
    { id: 2, name: 'National Audit of Intermediate Care' },
    { id: 3, name: 'Patients Association Complainants Survey' },
    { id: 4, name: 'Community Hospitals Research' },
    { id: 5, name: 'National Audit for Care at the End of Life' },
    { id: 6, name: 'NHSI - Learning Disabilities' },
    { id: 7, name: 'Community Services Covid-19 Dashboard' },
    { id: 8, name: 'CVDprevent' },
    { id: 9, name: 'Same Day Emergency Care' },
    { id: 12, name: 'South East Mental Health Dashboard' },
    { id: 13, name: 'Workforce Programme' },
    { id: 14, name: 'Bespoke Mental Health' },
  ];

  // Needs to be an API call
  public static readonly projectRoles: Array<ConstantType> = [
    { id: 1, name: 'Assistant Project Manager' },
    { id: 2, name: 'Programme Manager' },
    { id: 3, name: 'Project Coordinator' },
    { id: 4, name: 'Project Manager' },
    { id: 5, name: 'Senior Project Manager' },
    { id: 6, name: 'Assistant Project Manager (Community)' },
    {
      id: 7,
      name: 'Project Coordinator (Mental Health & Learning Disabilities)',
    },
    { id: 8, name: 'Graduate Project Coordinator' },
  ];

  public static readonly questionTypes: Array<ConstantType> = [
    { id: 'NR', name: 'Narrative (NR)' },
    { id: 'N1', name: 'Numeric (N1)' },
    { id: 'YN', name: 'Yes/No (YN)' },
    { id: 'YNX', name: 'Yes/No/NA (YNX)' },
    { id: 'LS', name: 'List (LS)' },
    { id: 'TX', name: 'Text (TX)' },
    { id: 'DT', name: 'Date (DT)' },
    { id: 'TM', name: 'Time (TM)' },
    { id: 'RD', name: 'Radio (RD)' },
  ];

  public static readonly questionFormatModifiers: Array<ConstantType> = [
    { id: 'I', name: 'Italic (I)' },
    { id: 'T', name: 'Title (T)' },
    { id: 'B', name: 'Bold (B)' },
    { id: 'L', name: 'Large Input (L)' },
    { id: 'S', name: 'Small Input (S)' },
    { id: 'H', name: 'Horizontal/Likert (H)' },
    { id: null, name: '-' },
  ];

  public static readonly prefixTypes: Array<ConstantType> = [{ id: '£', name: 'Pounds (£)' }];

  public static readonly suffixTypes: Array<ConstantType> = [
    { id: '%', name: 'Percentage (%)' },
    { id: 'wks', name: 'Weeks (wks)' },
    { id: 'days', name: 'Days (days)' },
    { id: 'hrs', name: 'Hours (hrs)' },
    { id: 'mins', name: 'Minutes (mins)' },
    { id: 'WTE', name: 'Waste To Energy (WTE)' },
    { id: 'm', name: 'Million (m)' },
    { id: 'k', name: 'Thousand (k)' },
    { id: ':1', name: 'To one (:1)' },
  ];

  public static readonly currentDate: Date = new Date();

  public static readonly currentYear: number = new Date().getFullYear();

  public static readonly currentFinancialYear: number =
    new Date().getMonth() < 5
      ? new Date().getFullYear() - 1
      : new Date().getFullYear();

  public static readonly currentYearShort: number = +Constants.currentYear
    .toString()
    .slice(-2);

  public static readonly placeholders: Array<ConstantType> = [
    {
      id: 'dym2',
      name: 'DOUBLE_YEAR_MINUS_2',
      example:
        Constants.currentYearShort - 2 + '/' + (Constants.currentYearShort - 1),
    },
    {
      id: 'dym3',
      name: 'DOUBLE_YEAR_MINUS_3',
      example:
        Constants.currentYearShort - 3 + '/' + (Constants.currentYearShort - 2),
    },
    {
      id: 'dyp',
      name: 'DOUBLE_YEAR_PREVIOUS',
      example:
        Constants.currentYearShort - 1 + '/' + Constants.currentYearShort,
    },
    {
      id: 'dyc',
      name: 'DOUBLE_YEAR_CURRENT',
      example:
        Constants.currentYearShort + '/' + (Constants.currentYearShort + 1),
    },
    {
      id: 'dyn',
      name: 'DOUBLE_YEAR_NEXT',
      example:
        Constants.currentYearShort + 1 + '/' + (Constants.currentYearShort + 2),
    },
    {
      id: 'sym3',
      name: 'SINGLE_YEAR_MINUS_3',
      example: (Constants.currentYearShort - 3).toString(),
    },
    {
      id: 'sym2',
      name: 'SINGLE_YEAR_MINUS_2',
      example: (Constants.currentYearShort - 2).toString(),
    },
    {
      id: 'symp',
      name: 'SINGLE_YEAR_PREVIOUS',
      example: (Constants.currentYearShort - 1).toString(),
    },
    {
      id: 'symc',
      name: 'SINGLE_YEAR_CURRENT',
      example: (Constants.currentYearShort).toString(),
    },
    {
      id: 'symn',
      name: 'SINGLE_YEAR_NEXT',
      example: (Constants.currentYearShort + 1).toString(),
    },
  ];

  public static readonly formatModifier: Array<ConstantType> = [
    { id: '', name: '-' },
    { id: 'P', name: 'Percentage (%)' },
    { id: 'C', name: 'Currency (£)' },
    { id: 'D', name: 'Days' },
    { id: 'Y', name: 'Years' },
    { id: 'T', name: 'Time' },
  ];

  public static readonly chartTypes: Array<ConstantType> = [
    {
      id: 'B1',
      name: 'Single series bar chart',
    },
    {
      id: 'B2',
      name: 'Two series bar chart',
    },
    {
      id: 'C1',
      name: 'Single series column chart',
    },
    {
      id: 'C2',
      name: 'Two series column chart',
    },
    {
      id: 'D1',
      name: 'Barthel Score chart',
    },
    {
      id: 'D2',
      name: 'Sunderland Score chart',
    },
    {
      id: 'D3',
      name: 'TOM Score chart',
    },
    {
      id: 'DB',
      name: 'Dual-bar chart',
    },
    {
      id: 'L1',
      name: 'Single series line chart',
    },
    {
      id: 'P1',
      name: 'PREM narrative table view',
    },
    {
      id: 'P3',
      name: 'NACEL narrative table view',
    },
    {
      id: 'PI',
      name: 'Pie chart',
    },
    {
      id: 'PN',
      name: 'Pie chart (inc. national values in table)',
    },
    {
      id: 'RD',
      name: 'Radar chart',
    },
    {
      id: 'SB',
      name: '100% stacked bar chart',
    },
    {
      id: 'SBS',
      name: 'Scaled stacked bar chart',
    },
    {
      id: 'SC',
      name: 'Scatter chart',
    },
    {
      id: 'TB1',
      name: 'Single series table view',
    },
    {
      id: 'TB2',
      name: 'Two series table view',
    },
    {
      id: 'TPI',
      name: 'Pie chart table view',
    },
    {
      id: 'TYN',
      name: 'Yes/No table view',
    },
    {
      id: 'YN',
      name: 'Yes/No chart',
    },
    {
      id: 'BI2',
      name: 'Independant two series bar chart (e.g. vacancy rate)'
    },
    {
      id: 'CI2',
      name: 'Independant two series column chart (e.g. vacancy rate)'
    },
    {
      id: 'SCS',
      name: 'Scaled stacked column chart'
    }
  ];
}
