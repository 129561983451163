<div class="fix-bottom">
  <div class="panel-back border bg-white rounded shadow">
    <div class="panel-row d-flex">
      <button
        type="button"
        class="btn btn-light border panel bg-light rounded"
        (click)="showIT = true"
      >
        <h6 class="text-primary border-bottom pb-3 mb-3">
          <i class="fal fa-lg fa-fw mr-1 fa-exclamation-triangle"></i> Report an IT issue
        </h6>
        <p>Do you have a problem with your equipment, system accounts, or email?</p>
        <p class="mb-0">Contact IT Support <i class="far fa-sm fa-fw fa-chevron-right text-primary"></i></p>
      </button>
      <button
        type="button"
        class="btn btn-light border panel bg-light rounded"
        (click)="showHelp = true"
      >
        <h6 class="text-primary border-bottom pb-3 mb-3">
          <i class="fal fa-lg fa-fw mr-1 fa-bug"></i> Internal software
          problem or general request
        </h6>
        <p>
          Do you need support with a project or have you found a bug with our
          software?
        </p>
        <p class="mb-0">Make a Team Request <i class="far fa-sm fa-fw fa-chevron-right text-primary"></i></p>
      </button>
      <button
        type="button"
        class="btn btn-light border panel bg-light rounded"
        (click)="showCanny = true"
        *ngIf="user.isAuthenticated()"
      >
        <h6 class="text-primary border-bottom pb-3 mb-3">
          <i class="fal fa-lg fa-fw mr-1 fa-comment"></i> Request a change
        </h6>
        <p>
          Is our software missing something? Do you have a great idea that you
          think we should know about?
        </p>
        <p class="mb-0">Use the Feedback System <i class="far fa-sm fa-fw fa-chevron-right text-primary"></i></p>
      </button>
    </div>
  </div>

  <div class="btn btn-primary shadow float-right">
    <i class="fal fa-lg fa-life-ring"></i>
  </div>
</div>

<!-- Team Request Form -->
<ng-container *ngIf="showHelp">
  <div class="teamwork-container-bg" (click)="showHelp = false"></div>
  <div class="teamwork-container">
    <iframe
      class="teamwork-embed teamwork-dynamic-height"
      [src]="
        'https://rcigroup.eu.teamwork.com/app/public/forms/65wRMmLco3gKjYGwOgO0'
          | safe
      "
      frameborder="0"
      onmousewheel=""
      width="100%"
      height="100%"
    >
    </iframe>
  </div>
</ng-container>

<!-- IT Request Form -->
<ng-container *ngIf="showIT">
  <div class="teamwork-container-bg" (click)="showIT = false"></div>
  <div class="teamwork-container">
    <iframe
      class="teamwork-embed teamwork-dynamic-height"
      [src]="
        'https://rcigroup.eu.teamwork.com/app/public/forms/D1vemNmHXNa9eOVO9DvW'
          | safe
      "
      frameborder="0"
      onmousewheel=""
      width="100%"
      height="100%"
    >
    </iframe>
  </div>
</ng-container>

<app-canny-modal
  *ngIf="showCanny"
  (action)="closeModal($event)"
></app-canny-modal>
