import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { ProjectComponent } from './project/project.component';

import { AuthGuardService as AuthGuard } from './core/services/auth-guard.service';
import { ProfileComponent } from './profile/profile.component';
import { ToolComponent } from './tool/tool.component';
import { DeskComponent } from './desk/desk.component';
import { OrganisationComponent } from './organisation/organisation.component';
import { EventsComponent } from './events/events.component';
import { DataExtractorComponent } from './tool/data-extractor/data-extractor.component';
import { DataExtractorLibraryComponent } from './tool/data-extractor/data-extractor-library/data-extractor-library.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { UsersComponent } from './users/users.component';
import { CrmComponent } from './tool/crm/crm.component';
import { UserEngagementToolComponent } from './tool/user-engagement-tool/user-engagement-tool.component';
import { UsersPendingComponent } from './users/users-pending/users-pending.component';
import { RolesComponent } from './roles/roles.component';
import { StaticDocumentUploaderComponent } from './tool/static-document-uploader/static-document-uploader.component';
import { ProductsComponent } from './products/products.component';
import { AdminReportsComponent } from './tool/admin-reports/admin-reports.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'project',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'project',
    component: ProjectComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'project/:year',
    component: ProjectComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'project/:year/:id/:section',
    component: ProjectComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'project/:year/:id/:section/:subSection',
    component: ProjectComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'tool',
    component: ToolComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'tool/static-document-uploader/:year',
    component: StaticDocumentUploaderComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'tool/data-extract',
    component: DataExtractorComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'tool/data-extract-library',
    component: DataExtractorLibraryComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'tool/data-extract/library/:year',
    component: DataExtractorLibraryComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'tool/crm',
    component: CrmComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'tool/user-engagement-tool',
    component: UserEngagementToolComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'tool/custom-reports',
    component: AdminReportsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'tool/custom-reports/:id',
    component: AdminReportsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'tool/custom-reports/:id/:year',
    component: AdminReportsComponent,
    canActivate: [AuthGuard],
  },    
  {
    path: 'tool/crm/:year',
    component: CrmComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'tool/crm/:year/:section',
    component: CrmComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'desk',
    component: DeskComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'organisation',
    component: OrganisationComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'organisation/:year',
    component: OrganisationComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'organisation/:year/:id/:section',
    component: OrganisationComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'organisation/:year/:id/:section/:subSection',
    component: OrganisationComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'users',
    component: UsersComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'users-pending',
    component: UsersPendingComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'users/:id/:section',
    component: UsersComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'event',
    component: EventsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'event/:year/:id/:section',
    component: EventsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'event/:year/:id/:section/:subSection',
    component: EventsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'event/:year',
    component: EventsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'roles',
    component: RolesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'roles/:year',
    component: RolesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'products',
    component: ProductsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'products/:year',
    component: ProductsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'products/:year/:id',
    component: ProductsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: '404',
    component: PageNotFoundComponent, 
  },
  { 
    path: '**', 
    redirectTo: '404',    
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
