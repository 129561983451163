import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { IOrganisationDetails } from '../models/organisation-details.vm';

@Injectable({
  providedIn: 'root'
})
export class OrganisationService {
  private nhsApiUrl: string = environment.nhsApiUrl;
  
  constructor(private http: HttpClient, public auth: AuthService) { }

    // NHS
    public getOrganisations(year: number): Observable<any> {
      return this.http.get(
        this.nhsApiUrl + 'organisations', {
          headers: new HttpHeaders().set('Authorization', this.auth.token()),
          params: new HttpParams().set('year', year.toString())
        }
      );
    }

    public getOrganisation(organisationId: number, year: number): Observable<any> {
      return this.http.get(
        this.nhsApiUrl + 'organisations/' + organisationId.toString(), {
          headers: new HttpHeaders().set('Authorization', this.auth.token()),
          params: new HttpParams().set('year', year.toString())
        }
      );
    }

    public getOrganisationProduct(organisationId: number, year: number): Observable<any> {
      return this.http.get(
        this.nhsApiUrl + 'organisation-products/' + organisationId.toString(), {
          headers: new HttpHeaders().set('Authorization', this.auth.token()),
          params: new HttpParams().set('year', year.toString())
        }
      );
    }

    public getOrganisationProjectAccessList(organisationId: number): Observable<any> {
      return this.http.get(
        this.nhsApiUrl + 'organisations/' + organisationId.toString() + '/projectOrganisationAccess', {
          headers: new HttpHeaders().set('Authorization', this.auth.token())
        }
      );
    }

    public updateOrganisationProjectAccessList(organisationId: number, body: any):Observable<any> {
      return this.http.post(
        this.nhsApiUrl + 'organisations/' + organisationId.toString() + '/projectAccess', body, {
          headers: new HttpHeaders().set('Authorization', this.auth.token())
        }
      );
    }

    public getOrganisationUser(organisationId: number, year: number): Observable<any> {
      return this.http.get(
        this.nhsApiUrl + 'organisations/' + organisationId.toString() + '/users', {
          headers: new HttpHeaders().set('Authorization', this.auth.token()),
          params: new HttpParams().set('year', year.toString())
        }
      );
    }

    public getOrganisationRegistration(organisationId: number, year?): Observable<any> {
      return this.http.get(
        this.nhsApiUrl + 'projectRegistration/list', {
          headers: new HttpHeaders().set('Authorization', this.auth.token()),
          params: new HttpParams().set('organisationId', organisationId.toString())
          // .set('year', year.toString())
        }
      );
    }

    public getOrganisationSubmission(organisationId: number, year?: number): Observable<any> {
      return this.http.get(
        this.nhsApiUrl + 'submissions/list', {
          headers: new HttpHeaders().set('Authorization', this.auth.token()),
          params: new HttpParams().set('organisationId', organisationId.toString()).set('year', year.toString())
        }
      );
    }

    public getMOU(organisationId: number): Observable<any> {
      return this.http.get(
        this.nhsApiUrl + 'organisations/' + organisationId + '/getMOU', {
          headers: new HttpHeaders().set('Authorization', this.auth.token())
        }
      );
    }

    public createMOU(body: any):Observable<any> {
      return this.http.post(
        this.nhsApiUrl + 'organisations/createMOU', body, {
          headers: new HttpHeaders().set('Authorization', this.auth.token())
        }
      );
    }

    public updateMOU(body: any):Observable<any> {
      return this.http.post(
        this.nhsApiUrl + 'organisations/updateMOU', body, {
          headers: new HttpHeaders().set('Authorization', this.auth.token())
        }
      );
    }

    public deleteMOU(body: any): Observable<any> {
      return this.http.post(
        this.nhsApiUrl + 'organisations/deleteMOU', body, {
          headers: new HttpHeaders().set('Authorization', this.auth.token())
        }
      );
    }

    public getOrganisationTypes(): Observable<any> {
      return this.http.get(
        this.nhsApiUrl + '/organisations/getOrganisationTypes', {
          headers: new HttpHeaders().set('Authorization', this.auth.token())
        }
      )
    }

    public getOrganisationRegion(): Observable<any> {
      return this.http.get(
        this.nhsApiUrl + '/organisations/getRegion', {
          headers: new HttpHeaders().set('Authorization', this.auth.token())
        }
      )
    }

    public updateOrganisationDetails(body: IOrganisationDetails): Observable<any> {
      return this.http.post(
        this.nhsApiUrl + 'organisations/updateOrganisationDetails', body, {
          headers: new HttpHeaders().set('Authorization', this.auth.token())
        }
      )
    }
    
}
