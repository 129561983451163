import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
  })
  export class ProductService {
    private nhsApiUrl: string = environment.nhsApiUrl;
    
    constructor(private http: HttpClient, public auth: AuthService) { }

    public getOrganisationProduct(productId: number, year: number): Observable<any> {
        return this.http.get(
          this.nhsApiUrl + 'organisations/productOrgList', {
            headers: new HttpHeaders().set('Authorization', this.auth.token()),
            params: new HttpParams().set('productYear', year.toString()).set('productId', productId.toString())
          }
        );
      }

      
      public addProduct(body: any):Observable<any> {
        return this.http.post(
          this.nhsApiUrl + 'organisations/product', body, {
            headers: new HttpHeaders().set('Authorization', this.auth.token())
          }
        );
      }

      public editProduct(body: any):Observable<any> {
        return this.http.patch(
          this.nhsApiUrl + 'organisations/product', body, {
            headers: new HttpHeaders().set('Authorization', this.auth.token())
          }
        );
      }

      public getOrganisationProductOptions(): Observable<any> {
        return this.http.get(
          this.nhsApiUrl + 'organisations/productOptions', {
            headers: new HttpHeaders().set('Authorization', this.auth.token()),
          }
        );
      }

  }  

