import { Component, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoaderService } from '../core/services/loader.service';
import { EventsService } from '../core/services/events.service';
import { Event } from '../core/classes/event';
import { ILayout } from '../core/models/layout.vm';
import { Layout } from '../core/classes/layout';
import { IDynamicTableColumn } from '../core/models/dynamic-table-column.vm';
import {
  eventNavItems,
  eventDetailNavItems,
  EventTableColumns,
  AddEventForm,
} from './events-layouts';
import { INavItem } from '../core/models/nav-item.vm';
import { Constants } from '../core/constants/constants';
import { IDynamicFormBlock } from '../core/models/dynamic-form-block.vm';

interface NewEvent {   
  eventDescription: string,
  eventDt: string, 
  eventLocation: string,
  eventName: string,
  isOnline: string,
  isVisible: string,
  maxCapacity: number,
  meetingDescription: string,
  meetingId: number,
  meetingPasscode: string,
  meetingUrl: string,
  placesPerOrganisation: number,
  projectCategory: number,  
  regionEnabled: string,
  registrationEnabled: string
};

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
})
export class EventsComponent implements OnInit {
  public currentUrlParams: any;
  public loadingError: string = null;
  public layout: ILayout;
  public eventTableColumns: Array<IDynamicTableColumn> = EventTableColumns;
  public eventList: Array<Event> = new Array<Event>();
  public currentEvent: Event;
  public eventNavItems: Array<INavItem> = eventNavItems;
  public eventDetailNavItems: Array<INavItem> = eventDetailNavItems;
  public currentYear: number;
  public selectedYear: any;
  public currentSection: string = undefined;
  public dynamicButtons = [{ name: 'Add Event', class: 'btn btn-success' }];
  public addEventLayout: ILayout = null;
  public addEventForm: Array<IDynamicFormBlock> = AddEventForm;
  public newEvent: NewEvent;

  constructor(
    public loaderService: LoaderService,
    private eventsService: EventsService,
    private route: ActivatedRoute,
    private router: Router,
    private renderer: Renderer2

  ) {}
  
  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.currentUrlParams = params;
      this.routeChange(params);
    });
    this.resetEventDetails();
  }

  public openAddEventSlider() {
    this.addEventLayout = new Layout(
      "Add Event", [], null
    )
  }

  public closeAddEventSlider = (): void => {
    this.addEventLayout = null;
    this.resetEventDetails();
  }

  public resetEventDetails () {
    this.newEvent = {   
      eventDescription: '',
      eventDt: '',     
      eventLocation: '',
      eventName: '',
      isOnline: '',
      isVisible: '',
      maxCapacity: null,
      meetingDescription: '',
      meetingId: null,
      meetingPasscode: '',
      meetingUrl: '',
      placesPerOrganisation: null,
      projectCategory: null,     
      regionEnabled: 'N',
      registrationEnabled: ''
    };
  }

  public addEvent(data: any) {
    this.newEvent = {
      eventDescription: data.row.eventDescription,
      eventDt: data.row.eventDt.replace("T", " ") + ":00",
      eventLocation: data.row.eventLocation,
      eventName: data.row.eventName,
      isOnline: data.row.isOnline,
      isVisible: data.row.isVisible,
      maxCapacity: data.row.maxCapacity,
      meetingDescription: data.row.meetingDescription,
      meetingId: data.row.meetingID,
      meetingPasscode: data.row.meetingPasscode != '' ? data.row.meetingPasscode : '—',
      meetingUrl: data.row.meetingURL != '' ? data.row.meetingURL : '—',
      placesPerOrganisation: data.row.placesPerOrganisation,
      projectCategory: data.row.projectCategory,
      regionEnabled: 'N',
      registrationEnabled: data.row.registrationEnabled
    };   
    this.eventsService.createEvent(this.newEvent).subscribe(
      (success) => {
        this.getEventList();
        this.closeAddEventSlider()
      },
      (error) => {
        console.log('Error: ', error);
        this.loadingError = error.error.error.message;
      }
    );    
  }

  public routeChange(params) {
    this.renderer.setProperty(document.documentElement, 'scrollTop', 0)
    this.currentYear = params.year || Constants.currentYear;
    this.currentSection = params.section;
    // No Event selected, return to list
    if (this.currentSection == undefined && this.eventList.length == 0) {
      this.getEventList();
    }
    // Event selected, get details and show section
    if (this.currentSection) {
      this.getEventList();
    }
  }

  public yearChange(year: number): void {
    if (this.currentEvent) {
      this.currentEvent = null;
      this.router.navigate([
        '/event',
        year,
        this.currentUrlParams.id,
        this.currentUrlParams.section,
      ]);
    } else {
      this.eventList = new Array<Event>();
      this.router.navigate(['/event', year]);
    }
  }

  public getEventList(): void {
    this.currentEvent = null;
    this.eventList = [];
    this.eventsService.getEvents().subscribe(
      (success) => {
        success.data.forEach((event) => {
          this.eventList.push(new Event(event));
        });
        // replace all the 'null'-'undefined'-'' values with '—', in order to make the sort funcionality in the dynamic table work correctly
        this.eventList.forEach((obj) => {
          Object.keys(obj).forEach((key) => {
            let { [key as keyof typeof obj]: objKey } = obj;
            if (objKey === null || objKey === undefined || objKey === '' || objKey === ' ') {
              Object.defineProperty(obj, key, {
                value: '—',
                enumerable: true,
                writable: true
              });
            }
          });
        });
        //
        this.eventList = this.eventList.filter(
          (year) => year.eventYear == this.currentYear
        );
        this.layout = new Layout('Events', null, null);
        if (this.currentUrlParams.id) {
          this.getEventDetails(this.currentUrlParams.id);
        }
      },
      (error) => {
        console.log('Error: ', error);
        this.loadingError = error.error.error.message;
      }
    );
  }

  public onTableRowClick = (primaryKey: any): void => {
    this.router.navigate(['event', this.currentYear, primaryKey, 'details']);
  };

  private getEventDetails(primaryKey: any): void {
    this.currentEvent = this.eventList.find(
      (event) => event.eventId == +primaryKey
    );
    this.layout = new Layout(
      this.currentEvent.eventName,
      [
        `ID: ${this.currentEvent.eventId}`,
        `Category: ${this.currentEvent.projectCategoryName}`,
      ],
      `/event/${this.currentYear}`
    );
  }
}
