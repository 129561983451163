import { Component, Input, OnChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Layout } from 'src/app/core/classes/layout';
import { IDynamicFormBlock } from 'src/app/core/models/dynamic-form-block.vm';
import { IDynamicTableColumn } from 'src/app/core/models/dynamic-table-column.vm';
import { ILayout } from 'src/app/core/models/layout.vm';
import { IOrganisationProduct } from 'src/app/core/models/organisation-product.vm';
import { LoaderService } from 'src/app/core/services/loader.service';
import { OrganisationProductAddForm, OrganisationProductDetailsForm, OrganisationProductTableColumns } from './organisation-product-layouts';
import { ProductService } from 'src/app/core/services/product.service';
import { IAlert } from 'src/app/core/models/alert';
import { IOrganisationProductAddForm } from 'src/app/core/models/organisation-product-add-form.vm';
import moment from 'moment';

@Component({
  selector: 'app-organisation-product',
  templateUrl: './organisation-product.component.html',
  styleUrls: ['./organisation-product.component.scss'],
})
export class OrganisationProductComponent implements OnChanges {
  @Input() public currentOrganisation;
  @Input() currentYear: number;

  public selectedProduct: IOrganisationProduct = null;
  public layout: ILayout;

  public currentQueryParams: any;
  public loadingError: string;

  public organisationProductTableColumns: Array<IDynamicTableColumn> = OrganisationProductTableColumns;
  public organisationProductDetailsForm: Array<IDynamicFormBlock> = OrganisationProductDetailsForm;
  public organisationProductAddForm: Array<IDynamicFormBlock> = OrganisationProductAddForm;

  public dynamicButton: any = [  {
    class: 'btn btn-success',
    name: 'Add Product'
  }]

  public openProductSlider: boolean = false;
  productAddForm: IOrganisationProductAddForm;
  yearList: any;
  public alert: IAlert;
  public componentName:string = "organisation-product";
  public productOptions: any


  constructor(
    public loaderService: LoaderService,
    private router: Router,
    private route: ActivatedRoute,
    private productService: ProductService,
  ) {
    this.getOptions()
  }

  
  ngOnChanges(): void {
    // Check for open product
    this.route.queryParams.subscribe((params) => {
      this.currentQueryParams = params;
    });
    // Open slider, if in params
    if (this.currentQueryParams.product) {
      this.onProductClick(this.currentQueryParams.product);
    }

    if(this.currentOrganisation.products == undefined) {
      this.currentOrganisation.products = [];
    }
    
    if (!Array.isArray(this.currentOrganisation.products)) {
      this.currentOrganisation.products = []
    }
  }

  public onProductClick = (primaryKey: string): void => {
    this.selectedProduct = this.currentOrganisation.products.find((pro) => pro.productId == +primaryKey);
    this.selectedProduct.organisationId = this.currentOrganisation.organisationId
    
  if (!moment(this.selectedProduct.salesOrder, 'HH:mm', true).isValid() && this.selectedProduct.salesOrder !== null) {
      this.selectedProduct.salesOrder = moment(this.selectedProduct.salesOrder).format('HH:mm');
    } else {
      this.selectedProduct.salesOrder = this.selectedProduct.salesOrder;
    }
    this.layout = new Layout(
      this.selectedProduct.productName,
      [`ID: ${this.selectedProduct.productId}`],
      null
    );
    this.router.navigate([], {
      queryParams: { product: primaryKey }, queryParamsHandling: 'merge', });
  };

  public closeProductSlider = (): void => {
    this.selectedProduct = null;
    this.router.navigate([], { queryParams: { product: null }, queryParamsHandling: 'merge', });
  };
  

  public openAddProductSlider(){
    this.openProductSlider = true
    this.productAddForm = {
      productYear: this.currentYear,
      organisationId: this.currentOrganisation.organisationId
    }
  }

  updateOptions(options: any): void {
    const optionMappings = {
      chargingProfileId: options.availableChargingProfiles.map((item: any) => ({
        id: item.chargingProfileId,
        name: item.chargingProfile
      })),
      membershipStatusId: options.availableMembershipStatuses.map((item: any) => ({
        id: item.membershipStatusId,
        name: item.membershipStatus
      })),
      chargingProfileBandId: options.availableChargingProfileBands.map((item: any) => ({
        id: item.chargingProfileBandId,
        name: item.bandDescription
      }))
    };
    this.organisationProductAddForm = this.organisationProductAddForm.map((item: any) => {
      if (optionMappings[item.dataKey]) {
        return {
          ...item,
          options: optionMappings[item.dataKey]
        };
      }
      return item;
    });
    this.organisationProductDetailsForm = this.organisationProductDetailsForm.map((item: any) => {
      if (optionMappings[item.dataKey]) {
        return {
          ...item,
          options: optionMappings[item.dataKey]
        };
      }
      return item;
    });
  
  }

  public dataUpdate(event){
    if(event.action == 'add'){
      this.addProduct(event.row)
    } else {
      this.editProduct(event.row)
    }
  }

  public getOptions(){
    this.productService.getOrganisationProductOptions().subscribe(success => {
      this.productOptions = success.data
      this.updateOptions(success.data)
    })
  }


  public addProduct(productDetails){
    let body = {
      organisationId: productDetails.organisationId || undefined,
      productId: productDetails.productId || undefined,
      productYear: productDetails.productYear || undefined,
      isEnabled: productDetails.productIsEnabled || undefined,
      invoiceAddress1: productDetails.invoiceAddress1 || undefined,
      invoiceAddress2: productDetails.invoiceAddress2 || undefined,
      invoiceAddress3: productDetails.invoiceAddress3 || undefined,
      invoiceAddress4: productDetails.invoiceAddress4 || undefined,
      invoiceAddressPc: productDetails.invoiceAddressPc || undefined,
      invoiceContact: productDetails.invoiceContact || undefined,
      invoiceEmail: productDetails.invoiceEmail || undefined,
      invoiceJobTitle: productDetails.invoiceJobTitle || undefined,
      invoicePhone: productDetails.invoicePhone || undefined,
      ceoContact: productDetails.ceoContact || undefined,
      ceoEmail: productDetails.ceoEmail || undefined,
      ceoJobTitle: productDetails.ceoJobTitle || undefined,
      ceoPhone: productDetails.ceoPhone || undefined,
      poNumber: productDetails.poNumber || undefined, 
      debtor: productDetails.debtor || undefined, 
      membershipStatusId: productDetails.membershipStatusId || undefined, 
      chargingProfileId: productDetails.chargingProfileId || undefined, 
      chargingProfileBandId: productDetails.chargingProfileBandId || undefined, 
      leadOrganisationId: productDetails.leadOrganisationId || undefined, 
      overrideFee: productDetails.overrideFee || undefined, 
      salesOrder: productDetails.salesOrder || undefined, 
      invoiceNumber: productDetails.invoiceNumber || undefined, 
      creditNoteNumber: productDetails.creditNoteNumber || undefined, 
      creditNoteFee: productDetails.creditNoteFee || undefined
    }
    let enabler = this.productOptions.availableMembershipStatuses.find(a => a.membershipStatusId == body.membershipStatusId)
    body.isEnabled = enabler.enabledEquivalent
    this.productService.addProduct(body).subscribe(success => {
      location.reload();
    }, error => {
      console.log('Error: ', error.error.error.message);
      if(error.error.error.message.includes("Conversion failed when converting date and/or time from character string.")){
        this.alert = {
          message: `<strong>Product Addition Failed: Please add a valid time for Sales Order</strong>`,
          alertClass: 'danger',
          fadeOut: true,
    };
      } else {
        this.alert = {
          message: `<strong>Product Addition Failed</strong>`,
          alertClass: 'danger',
          fadeOut: true,
    };
      }
    }
  )
  }

  public editProduct(productDetails){
    let body = {
      organisationId: productDetails.organisationId || null,
      productId: productDetails.productId || null,
      productYear: productDetails.productYear || null,
      isEnabled: productDetails.productIsEnabled || null,
      invoiceAddress1: productDetails.invoiceAddress1 || null,
      invoiceAddress2: productDetails.invoiceAddress2 || null,
      invoiceAddress3: productDetails.invoiceAddress3 || null,
      invoiceAddress4: productDetails.invoiceAddress4 || null,
      invoiceAddressPc: productDetails.invoiceAddressPc || null,
      invoiceContact: productDetails.invoiceContact || null,
      invoiceEmail: productDetails.invoiceEmail || null,
      invoiceJobTitle: productDetails.invoiceJobTitle || null,
      invoicePhone: productDetails.invoicePhone || null,
      ceoContact: productDetails.ceoContact || null,
      ceoEmail: productDetails.ceoEmail || null,
      ceoJobTitle: productDetails.ceoJobTitle || null,
      ceoPhone: productDetails.ceoPhone || null,
      poNumber: productDetails.poNumber || null, 
      debtor: productDetails.debtor || null, 
      membershipStatusId: productDetails.membershipStatusId || null, 
      chargingProfileId: productDetails.chargingProfileId || null, 
      chargingProfileBandId: productDetails.chargingProfileBandId || null, 
      leadOrganisationId: productDetails.leadOrganisationId || null, 
      overrideFee: productDetails.overrideFee || null, 
      salesOrder: productDetails.salesOrder || null, 
      invoiceNumber: productDetails.invoiceNumber || null, 
      creditNoteNumber: productDetails.creditNoteNumber || null, 
      creditNoteFee: productDetails.creditNoteFee || null
    }
    let enabler = this.productOptions.availableMembershipStatuses.find(a => a.membershipStatusId == body.membershipStatusId)
    body.isEnabled = enabler.enabledEquivalent

      this.productService.editProduct(body).subscribe((success) => {
        location.reload()
      }), error => {
        console.log('Error: ', error.error.error.message);
        if(error.error.error.message.includes("Conversion failed when converting date and/or time from character string.")){
          this.alert = {
            message: `<strong>Product Addition Failed: Please add a valid time for Sales Order</strong>`,
            alertClass: 'danger',
            fadeOut: true,
      };
        } else {
          this.alert = {
            message: `<strong>Product Addition Failed</strong>`,
            alertClass: 'danger',
            fadeOut: true,
      };
        }
      }
    
  }

  public closeAddProductSlider = ():void =>{
    this.openProductSlider = false
  }
}
