import { Constants } from 'src/app/core/constants/constants';
import { IDynamicFormBlock } from 'src/app/core/models/dynamic-form-block.vm';

export const EventDetailBasics: IDynamicFormBlock[] = [
  {
    label: 'Event Details',
    helpText: 'The section covers the basic details of the selected event',
    blockType: 'header',
    colWidth: 12,
  },
  {
    label: 'Event Name',
    helpText: 'Required',
    dataKey: 'eventName',
    blockType: 'input',
    colWidth: 12
  },
  {
    blockType: 'breaker'
  },
  {
    label: 'Event Date and Time',
    helpText: 'Required',
    dataKey: 'eventDt',
    blockType: 'input',
    inputType: 'datetime-local',
    colWidth: 4,
  },
  {
    blockType: 'breaker'
  },
  {
    label: 'Sector',
    helpText: 'Required',
    dataKey: 'projectCategory',
    blockType: 'select',
    inputType: 'text',
    options: Constants.eventSectors,
    colWidth: 12,
  },
  {
    blockType: 'breaker'
  },
  {
    label: 'Event Description',
    helpText: 'Required',
    dataKey: 'eventDescription',
    blockType: 'textarea',
    inputType: 'text',
    colWidth: 12,
  },
  {
    blockType: 'breaker'
  },
  {
    label: 'iCal message',
    helpText: 'Required',
    dataKey: 'meeting_description',
    blockType: 'textarea',
    inputType: 'text',
    colWidth: 12,
  },
  {
    blockType: 'breaker'
  },
  {
    label: 'Is Visible',
    helpText: 'Required',
    dataKey: 'isVisible',
    inputType: 'text',
    blockType: 'select',
    options: Constants.yesNo,
    colWidth: 4,
  },
  {
    label: 'Registration Enabled',
    helpText: 'Required',
    dataKey: 'registrationEnabled',
    inputType: 'text',
    blockType: 'select',
    options: Constants.yesNo,
    colWidth: 4,
  },
  {
    blockType: 'breaker'
  },
  {
    label: 'Event Location',
    helpText: 'Required',
    dataKey: 'eventLocation',
    blockType: 'textarea',
    inputType: 'text',
    colWidth: 12
  },
  {
    blockType: 'breaker'
  },
  {
    label: 'Is Online',
    helpText: 'Required',
    dataKey: 'isOnline',
    inputType: 'text',
    blockType: 'select',
    options: Constants.yesNo,
    colWidth: 4,
  },
  {
    blockType: 'breaker'
  },
  {
    label: 'Meeting URL',
    dataKey: 'meetingUrl',
    blockType: 'input',
    colWidth: 12
  },
  {
    blockType: 'breaker'
  },
  {
    label: 'Meeting Code',
    dataKey: 'meetingPasscode',
    blockType: 'input',
    colWidth: 4
  },
  { 
    label: 'Meeting ID',
    dataKey: 'meetingId',
    blockType: 'input',
    inputType: 'number',
    colWidth: 4
  },
  {
    blockType: 'breaker',
  },
  { 
    label: 'Max Capacity',
    helpText: 'Required',
    dataKey: 'maxCapacity',
    blockType: 'input',
    inputType: 'number',
    colWidth: 4
  },
  { 
    label: 'Places per Organisation',
    helpText: 'Required',
    dataKey: 'placesPerOrganisation',
    blockType: 'input',
    inputType: 'number',
    colWidth: 4
  },
  {
    blockType: 'divider'
  },
  {
    label: 'Update Details',
    colWidth: 4,
    dataKeys: ['eventName', 'eventDt', 'isVisible', 'registrationEnabled', 'projectCategory', 'eventDescription', 'meeting_description', 'isOnline', 'eventLocation', 'meetingUrl', 'meetingPasscode', 'meetingId', 'maxCapacity', 'placesPerOrganisation'],
    requiredKeys: ['eventName', 'eventDt', 'isVisible', 'registrationEnabled', 'projectCategory', 'eventDescription', 'meeting_description', 'isOnline', 'eventLocation', 'maxCapacity', 'placesPerOrganisation'], 
    blockType: 'changesButton',
    buttonRow: true,
  },
];