import { Constants } from '../core/constants/constants';
import { IDynamicFormBlock } from '../core/models/dynamic-form-block.vm';
import { IDynamicTableColumn } from '../core/models/dynamic-table-column.vm';
import { INavItem } from '../core/models/nav-item.vm';

export const EventTableColumns: IDynamicTableColumn[] = [
  {
    header: 'ID',
    dataKey: 'eventId',
    percWidth: 5,
    sortable: true,
    groupable: false,
    searchable: true,
  },
  {
    header: 'Name',
    dataKey: 'eventName',
    percWidth: 30,
    sortable: true,
    groupable: false,
    searchable: true,
  },
  {
    header: 'Location',
    dataKey: 'eventLocation',
    percWidth: 15,
    sortable: true,
    groupable: false,
    searchable: true,
  },
  {
    header: 'Date',
    dataKey: 'eventDt',
    percWidth: 10,
    sortable: true,
    groupable: false,
    searchable: true,
    filterDateTime: true,
  },
  {
    header: 'Past/Future',
    dataKey: 'pastOrFuture',
    percWidth: 10,
    sortable: true,
    groupable: true,
    searchable: false,
  },
  {
    header: 'Registrations',
    dataKey: 'registrationCount',
    percWidth: 10,
    sortable: true,
    groupable: true,
    searchable: false,
  },
  {
    header: 'Registration Enabled',
    dataKey: 'registrationEnabled',
    percWidth: 10,
    sortable: true,
    groupable: false,
    searchable: false,
    badge: 'yesNo'
  },
  {
    header: 'Online',
    dataKey: 'isOnline',
    percWidth: 5,
    sortable: true,
    groupable: true,
    searchable: false,
    badge: 'yesNo'
  },
  {
    header: 'Visible',
    dataKey: 'isVisible',
    percWidth: 5,
    sortable: true,
    groupable: true,
    searchable: false,
    badge: 'yesNo'
  },

];

export const eventNavItems: INavItem[] = [
  {
    icon: 'fa-house',
    label: 'Details',
    link: '../details'
  },
  {
    icon: 'fa-file-alt',
    label: 'Documents',
    link: '../documents',
  },
  {
    icon: 'fa-tasks',
    label: 'Registrations',
    link: '../registrations',
  },
  {
    icon: 'fa-clipboard-check',
    label: 'Attendees',
    link: '../attendees',
  }
];

export const eventDetailNavItems: INavItem[] = [
  {
    label: 'Basics',
    link: '../details',
    scope: 'basics',
  },
];

export const AddEventForm: IDynamicFormBlock[] = [
  {
    blockType: 'divider'
  },
  {
    label: 'Event Name',
    helpText: 'Required',
    dataKey: 'eventName',
    blockType: 'input',
    colWidth: 12
  },
  {
    blockType: 'breaker'
  },
  {
    label: 'Event Date and Time',
    helpText: 'Required',
    dataKey: 'eventDt',
    blockType: 'input',
    inputType: 'datetime-local',
    colWidth: 4,
  },
  {
    blockType: 'breaker'
  },
  {
    label: 'Sector',
    helpText: 'Required',
    dataKey: 'projectCategory',
    blockType: 'select',
    inputType: 'text',
    options: Constants.eventSectors,
    colWidth: 12,
  },
  {
    blockType: 'breaker'
  },
  {
    label: 'Event Description',
    helpText: 'Required',
    dataKey: 'eventDescription',
    blockType: 'textarea',
    inputType: 'text',
    colWidth: 12,
  },
  {
    blockType: 'breaker'
  },
  {
    label: 'iCal message',
    helpText: 'Required',
    dataKey: 'meetingDescription',
    blockType: 'textarea',
    inputType: 'text',
    colWidth: 12,
  },
  {
    blockType: 'breaker'
  },
  {
    label: 'Is Visible',
    helpText: 'Required',
    dataKey: 'isVisible',
    inputType: 'text',
    blockType: 'select',
    options: Constants.yesNo,
    colWidth: 4,
  },
  {
    label: 'Registration Enabled',
    helpText: 'Required',
    dataKey: 'registrationEnabled',
    inputType: 'text',
    blockType: 'select',
    options: Constants.yesNo,
    colWidth: 4,
  },
  {
    blockType: 'breaker'
  },
  {
    label: 'Event Location',
    helpText: 'Required',
    dataKey: 'eventLocation',
    blockType: 'textarea',
    inputType: 'text',
    colWidth: 12
  },
  {
    blockType: 'breaker'
  },
  {
    label: 'Is Online',
    helpText: 'Required',
    dataKey: 'isOnline',
    inputType: 'text',
    blockType: 'select',
    options: Constants.yesNo,
    colWidth: 4,
  },
  {
    blockType: 'breaker'
  },
  {
    label: 'Meeting URL',
    dataKey: 'meetingURL',
    blockType: 'input',
    colWidth: 12
  },
  {
    blockType: 'breaker'
  },
  {
    label: 'Meeting Code',
    dataKey: 'meetingPasscode',
    blockType: 'input',
    colWidth: 4
  },
  { 
    label: 'Meeting ID',
    dataKey: 'meetingID',
    blockType: 'input',
    inputType: 'number',
    colWidth: 4
  },
  {
    blockType: 'breaker',
  },
  { 
    label: 'Max Capacity',
    helpText: 'Required',
    dataKey: 'maxCapacity',
    blockType: 'input',
    inputType: 'number',
    colWidth: 4
  },
  { 
    label: 'Places per Organisation',
    helpText: 'Required',
    dataKey: 'placesPerOrganisation',
    blockType: 'input',
    inputType: 'number',
    colWidth: 4
  },
  {
    blockType: 'divider'
  },
  {
    label: 'Create Event',
    dataKeys: ['eventName', 'eventDt', 'isVisible', 'registrationEnabled', 'projectCategory', 'eventDescription', 'meetingDescription', 'isOnline', 'eventLocation', 'maxCapacity', 'placesPerOrganisation'],
    blockType: 'saveButton',
    action: 'submit'
  }
]
