import moment from "moment";
import { IUserActivity } from "../models/user-activity.vm";
import { IUserLoginAttempts } from "../models/user-login-attempts.vm";
import { IUserRole } from "../models/user-role.vm";

export class User {
    public fullName: string;
    public hasActiveRoles: string;
    public isAdmin: string;
    public jobTitle: string;
    public lastLogon: string;
    public logonAttempts: Array<IUserLoginAttempts>
    public logonName: string;
    public mailchimpOptIn: string;
    public notificationOptIn: string;
    public phoneNo: string;
    public recentlyActive: string;
    public roles?: Array<IUserRole>;
    public userActivity?: Array<IUserActivity>;
    public userDetailsLastReviewed: string;
    public userId: number;
    public welcomeDialogDismissed: string;
    public isActive: string;

    constructor(user: User) {
        this.mapUserDetails(user)
    }

    private mapUserDetails(data: User): void {
        this.fullName = data.fullName;
        this.hasActiveRoles = this.noYes(data.hasActiveRoles);
        this.isAdmin = data.isAdmin;
        this.jobTitle = data.jobTitle;
        this.lastLogon = this.formatDate(data.lastLogon);
        this.logonAttempts= data.logonAttempts
        this.logonName = data.logonName;
        this.mailchimpOptIn = data.mailchimpOptIn;
        this.notificationOptIn = this.noYes(data.notificationOptIn);
        this.phoneNo = data.phoneNo;
        this.recentlyActive = data.recentlyActive;
        this.roles = this.mapUserRoles(data.roles);
        this.userActivity= data.userActivity
        this.userDetailsLastReviewed = this.formatDate(data.userDetailsLastReviewed);
        this.userId = data.userId;
        this.welcomeDialogDismissed = data.welcomeDialogDismissed;
        this.isActive = data.isActive; 
    }

    private mapUserRoles(roles: Array<IUserRole>): Array<IUserRole> {
        let flatRoles: Array<IUserRole> = [];
        Object.values(roles).forEach((el)=>{Object.values(el).forEach(el => flatRoles.push(el))})  
        return flatRoles;
    }

    private formatDate(date: string) {
        return moment(date).format('LLL');
    }

    
    private noYes(data: string) {
        if(data == 'N') {
            return 'No'
        }
        if(data == 'Y') {
            return 'Yes'
        }
    }
}