import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

@Injectable()
export class CannyService {

  constructor(private http: HttpClient, public auth: AuthService) {}

  public getBoards(): Observable<any> {
    return this.http.get(environment.nhsApiUrl + 'canny/getBoards', {
      headers: new HttpHeaders().set('Authorization', this.auth.token()),
    });
  }
}
