import { Component, OnInit } from '@angular/core';
import { Constants, ConstantType } from '../core/constants/constants';
import { LoaderService } from '../core/services/loader.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ILayout } from '../core/models/layout.vm';
import { Layout } from '../core/classes/layout';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {
  public loadingError: string = null;
  public products: Array<ConstantType>
  public currentProduct: number;
  public currentUrlParams: any;
  public currentYear: number;
  public layout: ILayout;

  constructor(
    public loaderService: LoaderService,
    private router: Router,
    private route: ActivatedRoute,


  ) { }

  ngOnInit(): void {
    this.products = Constants.products
    this.route.params.subscribe((params) => {
      this.currentUrlParams = params;
      this.routeChange(params);
    });
  }

  routeChange(params){
    this.currentProduct = params.id
    this.currentYear = params.year || Constants.currentYear;
    this.layout = new Layout(
      'Products',
      [], ''
    );
  }

  public yearChange(year: number): void {
    this.currentYear = year
    this.router.navigate(['products',this.currentYear]);

  }

  openProduct(product){
    this.router.navigate(['products',this.currentYear ,product.id]);
    this.currentProduct = product
  }


}
