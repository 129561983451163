import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { IUserPendingPost } from '../models/user-pending-post.vm';
import { IUserUpdate } from '../models/user.vm';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  private nhsApiUrl: string = environment.nhsApiUrl;

  constructor(private http: HttpClient, public auth: AuthService) {}

  //NHS
  public getUsers(recentlyActiveUsers?: string, adminUsers?: string, activeUsers?: string): Observable<any> {
    let params: HttpParams = new HttpParams();

    if(recentlyActiveUsers) {
      params = params.append("recentlyActiveUsers", recentlyActiveUsers.toString());
    }

    if(adminUsers) {
      params = params.append("adminUsers", adminUsers.toString());
    }

    if(activeUsers) {
      params = params.append("activeUsers", activeUsers.toString());
    }

    return this.http.get(this.nhsApiUrl + 'users/all', {
      headers: new HttpHeaders().set('Authorization', this.auth.token()),
      params: params,
    });
  }

  public getUsersDetails(userId: number): Observable<any> {
    return this.http.get(this.nhsApiUrl + 'users/' + userId + '/details', {
      headers: new HttpHeaders().set('Authorization', this.auth.token()),
      params: new HttpParams().set('userId', userId.toString()),
    });
  }

  public addUser(details: IUserUpdate): Observable<any> {
    return this.http.post(
      this.nhsApiUrl + 'users/addUser', JSON.stringify(details), {
        headers: new HttpHeaders().set('Authorization', this.auth.token()).set('Content-Type', 'application/json')
      }
    );
  }

  public updateUser(details: IUserUpdate): Observable<any> {
    return this.http.post(
      this.nhsApiUrl + 'users/updateUser', JSON.stringify(details), {
        headers: new HttpHeaders().set('Authorization', this.auth.token()).set('Content-Type', 'application/json')
      }
    );
  }

  public getUserActivity(userId: number): Observable<any> {
    return this.http.get(this.nhsApiUrl + 'activityLog/' + userId + '/userActivity', {
      headers: new HttpHeaders().set('Authorization', this.auth.token()),   
    });
  }

  public getPendingUsers(): Observable<any> {
    return this.http.get(this.nhsApiUrl + 'accessRequests', {
      headers: new HttpHeaders().set('Authorization', this.auth.token()),   
    });
  }

  public acceptUsers(requestId: any, body: IUserPendingPost): Observable<any> {
    return this.http.post(
      this.nhsApiUrl + 'accessRequests/' + requestId + '/accept', body ,{
        headers: new HttpHeaders().set('Authorization', this.auth.token()).set('Content-Type', 'application/json'),
        params: new HttpParams().set('userId', requestId.toString()),

      }
    );
  } 

  public deleteUsers(requestId: any, body: object): Observable<any> {
    return this.http.patch(
      this.nhsApiUrl + 'accessRequests/'+ requestId +'/reject', body,{
        headers: new HttpHeaders().set('Authorization', this.auth.token()).set('Content-Type', 'application/json'),
        params: new HttpParams().set('userId', requestId.toString()),
      }
    );
  }
  
  public resetUserPassword(email: string): Observable<any> {
    return this.http.get(
      this.nhsApiUrl + 'unauthenticated/getPasswordResetCode',{
        headers: new HttpHeaders().set('Authorization', this.auth.token()).set('Content-Type', 'application/json'),
        params: new HttpParams().set('logonName', email.toString()),
      }
    );
  }

  public getRoles(): Observable<any> {
    return this.http.get(
      this.nhsApiUrl + 'accessRequests/getAllRoles',{
        headers: new HttpHeaders().set('Authorization', this.auth.token()).set('Content-Type', 'application/json'),
      }
    );
  }

  public getProducts(organisationId: number): Observable<any> {
    return this.http.get(
      this.nhsApiUrl + 'organisations/' + organisationId + '/products',{
        headers: new HttpHeaders().set('Authorization', this.auth.token()).set('Content-Type', 'application/json'),
      }
    );
  }

  public disableAllRoles(body: any, loggedInUserId: number): Observable<any> {
    return this.http.post(
      this.nhsApiUrl + 'users/disableOrgRoles', body ,{
        headers: new HttpHeaders().set('Authorization', this.auth.token()).set('Content-Type', 'application/json'),
        params: new HttpParams().set('userId', loggedInUserId.toString()),
      }
    );
  } 

}
