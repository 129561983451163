import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtModule } from '@auth0/angular-jwt';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgPipesModule } from 'ngx-pipes';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule, DatePipe } from '@angular/common';

// Core
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { environment } from '../environments/environment';

// Service
import { DeskService } from './core/services/desk.service';
import { AuthGuardService } from './core/services/auth-guard.service';
import { UserService } from './core/services/user.service';
import { ProjectService } from './core/services/project.service';
import { LoaderService } from './core/services/loader.service';
import { AuthService } from './core/services/auth.service';
import { ToolService } from './core/services/tool.service';
import { OutputValidationService } from './core/services/validation.service';

// Project
import { ProjectComponent } from './project/project.component';
import { ProjectDetailComponent } from './project/project-detail/project-detail.component';
import { ProjectRegistrationComponent } from './project/project-registration/project-registration.component';
import { ProjectSubmissionComponent } from './project/project-submission/project-submission.component';
import { ProjectDataCollectionComponent } from './project/project-data-collection/project-data-collection.component';
import { ProjectResponseComponent } from './project/project-response/project-response.component';
import { ProjectResponseFilterComponent } from './project/project-response/project-response-filter/project-response-filter.component';
import { ProjectToolkitComponent } from './project/project-toolkit/project-toolkit.component';
import { ProjectValidationComponent } from './project/project-validation/project-validation.component';
import { DynamicDocumentComponent } from './project/project-document/dynamic-document/dynamic-document.component';
import { StaticDocumentComponent } from './project/project-document/static-document/static-document.component';
import { ProjectDashboardComponent } from './project/project-dashboard/project-dashboard.component';
import { ProjectDashboardFilterComponent } from './project/project-dashboard/project-dashboard-filter/project-dashboard-filter.component';

// Organisation
import { OrganisationComponent } from './organisation/organisation.component';
import { OrganisationDetailComponent } from './organisation/organisation-detail/organisation-detail.component';
import { OrganisationProductComponent } from './organisation/organisation-product/organisation-product.component';
import { OrganisationUserComponent } from './organisation/organisation-user/organisation-user.component';
import { OrganisationRegistrationComponent } from './organisation/organisation-registration/organisation-registration.component';
import { OrganisationSubmissionComponent } from './organisation/organisation-submission/organisation-submission.component';

// User
import { UsersComponent } from './users/users.component';
import { UsersDetailComponent } from './users/users-detail/users-detail.component';
import { UsersRolesComponent } from './users/users-roles/users-roles.component';
import { UsersLoginAttemptsComponent } from './users/users-login-attempts/users-login-attempts.component';
import { UsersActivityComponent } from './users/users-activity/users-activity.component';

// Event
import { EventsComponent } from './events/events.component';
import { EventDetailComponent } from './events/event-detail/event-detail.component';
import { EventDocumentComponent } from './events/event-document/event-document.component';
import { EventRegistrationComponent } from './events/event-registration/event-registration.component';
import { EventAttendeeComponent } from './events/event-attendee/event-attendee.component';

// Tool
import { ToolComponent } from './tool/tool.component';
import { DataExtractorComponent } from './tool/data-extractor/data-extractor.component';
import { DataExtractorLibraryComponent } from './tool/data-extractor/data-extractor-library/data-extractor-library.component';
import { DataExtractorFilterComponent } from './tool/data-extractor/data-extractor-filter/data-extractor-filter.component';
import { CrmComponent } from './tool/crm/crm.component';
import { CrmOpportunitiesComponent } from './tool/crm/crm-opportunities/crm-opportunities.component';
import { CrmFollowUpsComponent } from './tool/crm/crm-follow-ups/crm-follow-ups.component';
import { CrmInteractionsComponent } from './tool/crm/crm-interactions/crm-interactions.component';
import { CrmOverviewComponent } from './tool/crm/crm-overview/crm-overview.component';

// Desk Booking
import { DeskComponent } from './desk/desk.component';
import { FloorplanComponent } from './desk/floorplan/floorplan.component';
import { PanelComponent } from './desk/panel/panel.component';

// Profile
import { ProfileComponent } from './profile/profile.component';

// Highcharts
import { Ng2HighchartsModule } from 'ng2-highcharts';
import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import * as exporting from 'highcharts/modules/exporting.src';

// Pipes
import { HighlightPipe } from './core/pipes/highlight.pipe';
import { SafePipe } from './core/pipes/safe.pipe';

// Interceptor
import { LoaderInterceptor } from './core/interceptors/loader.interceptor';

// Directive
import { ClickElsewhereDirective } from './core/directives/clickElsewhereDirective';

// Store
import { StoreModule } from '@ngrx/store';
import { darkModeReducer } from './store/darkmode.reducer';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { adminUserListEffects } from './store/admin-userlist.effects';
import { adminUserListReducer } from './store/admin-userlist.reducer';

// Shared
import { AreaNavComponent } from './shared/area-nav/area-nav.component';
import { AreaHeadingComponent } from './shared/area-heading/area-heading.component';
import { LoadingStatusComponent } from './shared/loading-status/loading-status.component';
import { ErrorStatusComponent } from './shared/alert-status/alert-status.component';
import { SectionNavComponent } from './shared/section-nav/section-nav.component';
import { YearSelectComponent } from './shared/year-select/year-select.component';
import { DynamicTableComponent } from './shared/dynamic-table/dynamic-table.component';
import { TableFiltersComponent } from './shared/dynamic-table/table-filters/table-filters.component';
import { TableExportSearchComponent } from './shared/dynamic-table/table-export-search/table-export-search.component';
import { DynamicFormComponent } from './shared/dynamic-form/dynamic-form.component';
import { DynamicFormInputComponent } from './shared/dynamic-form/dynamic-form-input/dynamic-form-input.component';
import { DynamicFormSelectComponent } from './shared/dynamic-form/dynamic-form-select/dynamic-form-select.component';
import { DynamicFormArrayComponent } from './shared/dynamic-form/dynamic-form-array/dynamic-form-array.component';
import { DynamicFormSearchInputComponent } from './shared/dynamic-form/dynamic-form-search-input/dynamic-form-search-input.component';
import { DynamicFormTextareaComponent } from './shared/dynamic-form/dynamic-form-textarea/dynamic-form-textarea.component';
import { DynamicFormDropdownComponent } from './shared/dynamic-form/dynamic-form-dropdown/dynamic-form-dropdown.component';
import { SliderPanelComponent } from './shared/slider-panel/slider-panel.component';
import { TeamworkFormComponent } from './shared/teamwork-form/teamwork-form.component';
import { InputPlaceholderComponent } from './shared/input-placeholder/input-placeholder.component';
import { InputCopyComponent } from './shared/input-copy/input-copy.component';
import { TableRowComponent } from './shared/dynamic-table/table-row/table-row.component';
import { HighchartComponent } from './shared/highchart/highchart.component';
import { TablePaginationComponent } from './shared/dynamic-table/table-pagination/table-pagination.component';
import { MultiSelectFilterComponent } from './shared/multi-select-filter/multi-select-filter.component';
import { TableDateFilterComponent } from './shared/dynamic-table/table-filters/table-date-filter/table-date-filter.component';
import { JsonViewerComponent } from './shared/json-viewer/json-viewer.component';
import { PopupModalComponent } from './shared/popup-modal/popup-modal.component';
import { ProjectToolkitStoredProceduresSelectFilterComponent } from './project/project-toolkit/project-toolkit-stored-procedures-select/project-toolkit-stored-procedures-select.component';
import { storedProceduresEffects } from './store/stored-procedures.effects';
import { storedProcedureReducer } from './store/stored-procedures.reducer';
import { UserEngagementToolComponent } from './tool/user-engagement-tool/user-engagement-tool.component';
import { UploaderComponent } from './shared/uploader/uploader.component';
import { ProjectDataUploadComponent } from './project/project-data-upload/project-data-upload.component';
import { UsersPendingComponent } from './users/users-pending/users-pending.component';
import { UsersRolesProductsSharedComponent } from './users/users-roles-products-shared/users-roles-products-shared.component';
import { RolesProductsFormComponent } from './users/users-roles-products-shared/roles-products-form/roles-products-form.component';
import { RolesProductsTableComponent } from './users/users-roles-products-shared/roles-products-table/roles-products-table.component';
import { OrganisationMouComponent } from './organisation/organisation-mou/organisation-mou.component';
import { organisationListReducer } from './store/organisation-list.reducer';
import { organisationListEffects } from './store/organisation-list.effects';
import { RolesComponent } from './roles/roles.component';
import { DragAndDropDirective } from './core/directives/drag-and-drop.directive';
import { DragAndDropUploaderComponent } from './shared/drag-and-drop-uploader/drag-and-drop-uploader.component';
import { OrganisationProjectAccessComponent } from './organisation/organisation-project-access/organisation-project-access.component';
import { ProductsComponent } from './products/products.component';
import { ProductsDetailComponent } from './products/products-detail/products-detail.component';

export function tokenGetter() {
  return localStorage.getItem('adminUserToken');
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ProjectComponent,
    ProjectDetailComponent,
    ProjectComponent,
    AreaNavComponent,
    AreaHeadingComponent,
    LoadingStatusComponent,
    ErrorStatusComponent,
    SectionNavComponent,
    YearSelectComponent,
    DynamicTableComponent,
    TableFiltersComponent,
    TablePaginationComponent,
    TableExportSearchComponent,
    DynamicFormComponent,
    DynamicFormInputComponent,
    DynamicFormArrayComponent,
    DynamicFormSelectComponent,
    DynamicFormTextareaComponent,
    DynamicFormSearchInputComponent,
    ProjectRegistrationComponent,
    SliderPanelComponent,
    ProjectSubmissionComponent,
    HighlightPipe,
    ProjectDataCollectionComponent,
    DynamicDocumentComponent,
    StaticDocumentComponent,
    ProjectResponseComponent,
    TeamworkFormComponent,
    SafePipe,
    ClickElsewhereDirective,
    ProfileComponent,
    ToolComponent,
    DynamicFormDropdownComponent,
    ProjectToolkitComponent,
    ProjectResponseFilterComponent,
    InputPlaceholderComponent,
    ProjectValidationComponent,
    InputCopyComponent,
    TableRowComponent,
    DeskComponent,
    FloorplanComponent,
    PanelComponent,
    OrganisationComponent,
    OrganisationDetailComponent,
    OrganisationProductComponent,
    OrganisationUserComponent,
    OrganisationRegistrationComponent,
    OrganisationSubmissionComponent,
    UsersComponent,
    UsersDetailComponent,
    UsersRolesComponent,
    EventsComponent,
    EventDetailComponent,
    EventDocumentComponent,
    ProjectDashboardComponent,
    ProjectDashboardFilterComponent,
    PageNotFoundComponent,
    EventRegistrationComponent,
    DataExtractorComponent,
    DataExtractorLibraryComponent,
    MultiSelectFilterComponent,
    DataExtractorFilterComponent,
    ProjectToolkitStoredProceduresSelectFilterComponent,
    TableDateFilterComponent,
    JsonViewerComponent,
    UsersLoginAttemptsComponent,
    UsersActivityComponent,
    PopupModalComponent,
    HighchartComponent,
    CrmComponent,
    EventAttendeeComponent,
    CrmOpportunitiesComponent,
    CrmFollowUpsComponent,
    CrmInteractionsComponent,
    CrmOverviewComponent,
    UserEngagementToolComponent,
    ProjectDataUploadComponent,
    UploaderComponent,
    UsersPendingComponent,
    UsersRolesProductsSharedComponent,
    RolesProductsFormComponent,
    RolesProductsTableComponent,
    OrganisationMouComponent,
    RolesComponent,
    DragAndDropDirective,
    DragAndDropUploaderComponent,
    OrganisationProjectAccessComponent,
    ProductsComponent,
    ProductsDetailComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    ChartModule,
    JwtModule.forRoot({
      config: { tokenGetter: tokenGetter },
    }),
    FormsModule,
    ReactiveFormsModule,
    NgPipesModule,
    AngularEditorModule,
    StoreModule.forRoot({ 
      darkMode: darkModeReducer,
      adminUsers: adminUserListReducer,
      storedProcedures: storedProcedureReducer,
      organisationList: organisationListReducer
    }, {}),
    EffectsModule.forRoot([
      adminUserListEffects, 
      storedProceduresEffects,
      organisationListEffects
    ]),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    CommonModule,
    NgxJsonViewerModule,
    Ng2HighchartsModule,
  ],
  providers: [
    AuthGuardService,
    AuthService,
    UserService,
    ProjectService,
    DeskService,
    LoaderService,
    ToolService,
    OutputValidationService,
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
    { provide: HIGHCHARTS_MODULES, useFactory: () => [ exporting ] }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
