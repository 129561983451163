import { Component } from '@angular/core';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-teamwork-form',
  templateUrl: './teamwork-form.component.html',
  styleUrls: ['./teamwork-form.component.scss'],
})
export class TeamworkFormComponent {
  public showHelp: boolean = false;
  public showIT: boolean = false;
  public showCanny: boolean = false;

  constructor(
    public user: UserService,
  ){}

  public closeModal(event) {
    this.showCanny = event;
  }
}
