<!-- Loading and error -->
<app-loading-status *ngIf="(loaderService.isLoading | async) && !currentOrganisation; else notLoading"></app-loading-status>
<app-alert-status *ngIf="loadingError" [loadingError]="loadingError"></app-alert-status>

<ng-template #notLoading>
    <!-- Table -->
    <app-dynamic-table 
        *ngIf="currentOrganisation.projectAccess" 
        [data]="currentOrganisation.projectAccess"
        [columns]="projectAccessTableColumns" 
        [dynamicButtons]="dynamicButtons"
        [tableTitle]="'dataUploadList'"
        [primaryKey]="'projectId'" 
        [onTdClick]="onTableTdClick" 
        (buttonClicked)="toggleAllAccessStatuses($event)"
        [disableRowClick]="true">
    </app-dynamic-table>
</ng-template>
