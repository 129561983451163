import { Component, Input } from '@angular/core';
import { IDynamicTableColumn } from 'src/app/core/models/dynamic-table-column.vm';
import { organisationList } from 'src/app/store/organisation-list.selectors';
import { Store } from '@ngrx/store';
import moment from 'moment';

@Component({
  selector: 'app-table-row',
  templateUrl: './table-row.component.html',
  styleUrls: ['./table-row.component.scss'],
})
export class TableRowComponent {
  @Input() public disableRowClick: boolean;
  @Input() public columns: Array<IDynamicTableColumn>;
  @Input() public data: Array<any>;
  @Input() public searchText: string = undefined;
  @Input() public primaryKey: string;
  @Input() onRowClick: (primaryKey: string, secondaryKey?: string) => void;
  @Input() onTdClick: (primaryKey: string, secondaryKey?: string) => void;
  public optionSelected: boolean;
  public clicked: boolean = false;

  constructor(
    private store: Store<{ darkMode: boolean }>
  ) { }

  public columnText(format: string, text: any, dateFormat?: string, organisation?: boolean): string {
    this.optionSelected = this.data.map(resp => resp.set).indexOf(true) > -1;
    if (text === null) {
      return '—';
    }

    if (format) {
      switch (format) {
        case 'date':
          let date = new Date(text);
          text = date.toLocaleDateString();
          break;
        case 'time':
          let time = new Date(text);
          text = time.toLocaleTimeString();
          break;
        case 'datetime':
          let dt = new Date(text);
          text = dt.toLocaleTimeString() + ' ' + dt.toLocaleDateString();
          break;
      }
    }

    if (dateFormat) {
      text = moment( text ).format(dateFormat);
    }

    if (organisation) {
      text = this.getOrganisationList(text);
    }

    return text;
  }

  private getOrganisationList(text: number) {
    let organisation: string;
    this.store.select(organisationList).subscribe(
      (success) => {
        if (success?.data) {
          let organisations = success.data.organisationList.map(item => { return { id: item.organisationId, name: item.organisationName } });
          organisation = organisations.filter(organisation => organisation.id == text)[0].name
        }
      },
      (error) => {
        console.log('Error: ', error);
      }
    );
    return organisation;
  }

  public columnBadge(badge: string, text: string): string {
    if (badge) {
      return `col-badge ${badge}-${text}`;
    } else {
      return '';
    }
  }

  public checkResponsePosted(): boolean {
    return this.data.map(resp => resp.set).indexOf(true) > -1;
  }

  public enableClick(col) {
    if (col.hasOwnProperty('options')) {
      return col.options.find((option) => option === 'clickable');
    } else {
      return false;
    }
  }


  buttonAction(row, layout, buttonType: string) {
    if(buttonType == 'dataUploads') {
      return this.dataUploadButton(row, layout);
    }

    if(buttonType == 'submissions') {
      return this.submissionsButton(row, layout)
    }

    if(buttonType == 'projectAccess') {
      return this.projectAccessButton(row)
    }
  }


  // Projects -> Data Uploads
  dataUploadButton(row, layout) {
    if(!row.submission_id && layout.action == 'accept') {
      return false;
    }

    if(['accept', 'reject'].includes(layout.action) && ['Submitted', 'Updated'].includes(row.status_name)) {
      return true;
    } else if(layout.action == "edit") {
      return true;
    }

    if(layout.action == "undo" && ['Error', 'Intray', 'Rejected', 'Imported'].includes(row.status_name)) {
      return true;
    }
    return false;
  }

    // Projects -> Submissions
    submissionsButton(row, layout) {
      if(row.responseCount > 0 && layout.action == 'delete') {
        return false;
      } else {
        return true
      }
    }

  // Organisation -> Project Access
  projectAccessButton(row) {   
    if(!row.allowAccess) {
      return false;
    } else {
      return true
    }
  }

  addClicked(primaryKey, button) {
    this.clicked = true;
    this.onTdClick(primaryKey, button)
    this.data.forEach(n => {
      n.clicked = false;
      if(n.extractId == primaryKey) {
        n.clicked = true;
      }
    })
  }


}
