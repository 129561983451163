import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { LoaderService } from 'src/app/core/services/loader.service';
import { ProjectService } from 'src/app/core/services/project.service';

import { IDynamicTableColumn } from 'src/app/core/models/dynamic-table-column.vm';
import { IDynamicFormBlock } from 'src/app/core/models/dynamic-form-block.vm';

import { Project } from 'src/app/core/classes/project';
import { Layout } from 'src/app/core/classes/layout';

import { DocumentsDynamicTableColumns, DocumentsDynamicForm, DocumentsDynamicTableAccessLogColumns, UploadDocumentsDynamicForm } from './project-documents-dynamic-layouts';
import moment from 'moment';
import { Store } from '@ngrx/store';
import { UploaderService } from 'src/app/core/services/uploader.service';
import { IAlert } from 'src/app/core/models/alert';
import { EventsService } from 'src/app/core/services/events.service';

@Component({
  selector: 'app-dynamic-document',
  templateUrl: './dynamic-document.component.html',
  styleUrls: ['./dynamic-document.component.scss'],
})
export class DynamicDocumentComponent implements OnChanges {
  @Input() currentProject: Project;
  @Input() currentYear: number;
  public componentName:string = "dynamic-document";
  public alert: IAlert;

  public dynamicDocumentsTableColumns: Array<IDynamicTableColumn> =
    DocumentsDynamicTableColumns;
  public DocumentsDynamicTableAccessLogColumns: Array<IDynamicTableColumn> =
    DocumentsDynamicTableAccessLogColumns;
  public questionGroupForm: Array<IDynamicFormBlock> = DocumentsDynamicForm;
  public UploadDocumentsDynamicForm: Array<IDynamicFormBlock> = UploadDocumentsDynamicForm;
  public displayedDocuments: any;
  public documentLayout: Layout;
  public loadingError: string;
  public currentQueryParams: any;
  public accessedByInfo: any;
  darkMode$: any;
  public newDocumentLayout: Layout;
  public UploadDocumentData: { documentName: string; displaySequence: string; };
  public files: any;
  public dynamicButtons = [
    {
      class: 'btn btn-success',
      name: '+ Add Document'
    }
  ];

  constructor(
    public loaderService: LoaderService,
    private projectService: ProjectService,
    private router: Router,
    private route: ActivatedRoute,
    private Uploader: UploaderService,
    private Events: EventsService,
    private store: Store<{ darkMode: boolean }>
  ) {this.darkMode$ = store.select('darkMode');

    this.UploadDocumentData = {
      documentName:'',
      displaySequence:'',
    }
  }

  ngOnChanges(): void {
    // Check for open slider
    this.route.queryParams.subscribe((params) => {
      this.currentQueryParams = params;
    });

    if (!this.currentProject.awsDocumentsDynamic) {
      this.getProjectSubmissions(
        this.currentProject.projectId,
        this.currentYear
      );
    }
  }

  private getProjectSubmissions(projectId: number, currentYear: number): void {
    this.projectService
      .getProjectDynamicDocuments(projectId, null, null, currentYear)
      .subscribe(
        (success) => {
          this.currentProject.awsDocumentsDynamic = success.data.documentList;

          // Open slider, if in params
          if (this.currentQueryParams.id) {
            this.onTableRowClick(this.currentQueryParams.id);
          }
        },
        (error) => {
          console.log('Error: ', error);
          this.loadingError = error.error.error.message;
        }
      );
  }

  public newDocumentClick() {
    this.newDocumentLayout = new Layout(
      'New Document Upload',
      ['Please upload a document file'],
      null
    )
  }

  private getDynamicDocumentsAccessLog(documentId): void {
    this.accessedByInfo = documentId.downloadedBy;
  }

  public onTableRowClick = (primaryKey: string): void => {
    this.displayedDocuments = this.currentProject.awsDocumentsDynamic;
    this.displayedDocuments = this.displayedDocuments.find(
      (document) => document.documentId == +primaryKey
    );
    this.getDynamicDocumentsAccessLog(this.displayedDocuments);
    this.documentLayout = new Layout(
      this.displayedDocuments.documentName,
      [`ID: ${this.displayedDocuments.documentId}`],
      null
    );
    this.router.navigate([], {
      queryParams: { id: primaryKey },
      queryParamsHandling: 'merge',
    });
  };

  public closeQuestionSlider = (): void => {
    this.documentLayout = null;
    this.newDocumentLayout = null;
    this.router.navigate([], {
      queryParams: { id: null },
      queryParamsHandling: 'merge',
    });
  };

  public saveFiles(files){
    this.files = files
  }

  public dataUpdate(event){
    if(this.files && event.action == 'create') {
      this.uploadDocuments(event.row);
    } else if(event.action == 'create') {
      this.alert = {
        message: `<strong>File not detected</strong>`,
        alertClass: 'danger',
        fadeOut: true,
      }
    }
  }

  public dataUpdateEdit(event){
    this.editDocument(event.row)
  }

  public uploadDocuments(form){
    this.Uploader.dynamicDocumentUpload('https://membersapidev.nhsbenchmarking.nhs.uk/awsDocuments/addDynamicDocument', this.files, form, this.currentProject.projectId, this.currentYear).subscribe(success => {
      this.closeQuestionSlider()
      this.getProjectSubmissions(this.currentProject.projectId, this.currentYear);
    },
    error => { 
      console.log('Error: ', error);
      if(error.error.includes('Invalid file extension. Allowed extensions are .xls, .xlsx, .pdf.')){
        this.alert = {
          message: `<strong>Invalid file type: please use .xls, .xlsx, .pdf.</strong>`,
          alertClass: 'danger',
          fadeOut: true,
      }}else{
        this.alert = {
          message: `<strong>Upload Failed</strong>`,
          alertClass: 'danger',
          fadeOut: true,
    };
      }
    })
  }

  public editDocument(form){
    let body = {
      projectId: this.displayedDocuments.projectId,
      documentId: this.displayedDocuments.documentId,
      documentName: form.documentName,
      displaySequence: form.displaySequence,
      documentCategoryId: form.documentCategoryId,
      isVisible: form.isVisible,
      organisationId: form.organisationId
    }

    this.Uploader.editDynamicDocument(this.displayedDocuments.projectId, this.displayedDocuments.documentId, body).subscribe(success => {
      this.closeQuestionSlider()
      this.getProjectSubmissions(this.currentProject.projectId, this.currentYear);
    },
    error => { 
      console.log('Error: ', error);
        this.alert = {
          message: `<strong>Edit failed, please check details</strong>`,
          alertClass: 'danger',
          fadeOut: true,
      }
    })
  }
}
