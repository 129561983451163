export class toolInformation {
  
    static tools: Array<object> = [
      {
        icon: "fal fa-user-chart",
        title: "User Engagement Tool",
        subTitle: "An internal BMC tool to our user engagement data.",
        route: ['tool','user-engagement-tool'],
        folderLocationLink: false,
        openButtonInnerHtml:'Open tool',
        showButton: true,
        disableOpenButton: false
      },
      {
        icon: "fal fa-file-export",
        title: "Data Extract Service",
        subTitle: "A tool to extract large amount of data.",
        route: ['tool','data-extract'],
        folderLocationLink: false,
        openButtonInnerHtml:'Open tool',
        showButton: true,
        disableOpenButton: false
      },
      {
        icon: "fal fa-file-upload",
        title: "Static Documents Uploader",
        subTitle: "Documents can be shared openly between member and non-member organisations.",
        route: ['tool','static-document-uploader'],
        folderLocationLink: false,
        openButtonInnerHtml:'Open tool',
        showButton: true,
        disableOpenButton: false
      },
      {
        icon: "fal fa-pencil-ruler",
        title: "Data Collection Builder",
        subTitle: "A tool to specify the core annual project data collection pages.",
        route: false,
        dataKey:"#data-collection-builder",
        folderLocationLink: "https://rcigroupuk.sharepoint.com/:f:/r/sites/BMCIntranet/Shared%20Documents/Resource%20Library/Tools%20%26%20Templates/Excel%20systems/01_Online_Data_Collection_(Script)Builder?csf=1&web=1&e=eLWnma",
        openButtonInnerHtml:'',
        showButton: false,
        disableOpenButton: true
      },
      {
        icon: "fal fa-chart-pie",
        title: "Toolkit Builder",
        subTitle: "A tool to specify the core annual project online toolkit pages.",
        route: false,
        dataKey:"#toolkit-builder",
        folderLocationLink: "https://rcigroupuk.sharepoint.com/:f:/r/sites/BMCIntranet/Shared%20Documents/Resource%20Library/Tools%20%26%20Templates/Excel%20systems/04_Online_Toolkit_(Script)Builder?csf=1&web=1&e=SZgmsX",
        openButtonInnerHtml:'',
        showButton: false,
        disableOpenButton: true
      },
      {
        icon: "fal fa-file",
        title: "Filehandler",
        subTitle: "Extracts questionnaire attachments from outlook, matches with our database, creates an audit trail, and saves the e-mail and attachment.",
        route: false,
        dataKey:"#file-handler",
        folderLocationLink: "https://rcigroupuk.sharepoint.com/:f:/r/sites/BMCIntranet/Shared%20Documents/Resource%20Library/Tools%20%26%20Templates/Excel%20systems/05_Filehandler?csf=1&web=1&e=ektxVy",
        openButtonInnerHtml:'',
        showButton: false,
        disableOpenButton: true
      },
      {
        icon: "fal fa-users",
        title: "CRM",
        subTitle: "A tool to track communications with member organisations.",
        route: ['tool','crm'],
        folderLocationLink: false,
        openButtonInnerHtml:'In development',
        showButton: true,
        disableOpenButton: true
      },
      {
        icon: "fal fa-file",
        title: "Custom Reports",
        subTitle: "A tool to view and export custom reports",
        route: ['tool','custom-reports'],
        folderLocationLink: false,
        openButtonInnerHtml:'Open tool',
        showButton: true,
        disableOpenButton: false
      }       
    ];
  
    static getToolInfo() {
      return this.tools;
    }

  }
  