import { ChangeDetectorRef, Component, HostListener, Input, OnChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { LoaderService } from 'src/app/core/services/loader.service';
import { ProjectService } from 'src/app/core/services/project.service';

import { IDynamicTableColumn } from 'src/app/core/models/dynamic-table-column.vm';
import { IDynamicFormBlock } from 'src/app/core/models/dynamic-form-block.vm';

import { Project } from 'src/app/core/classes/project';
import { Layout } from 'src/app/core/classes/layout';

import { DocumentsStaticTableColumns, DocumentsStaticForm } from './project-documents-static-layouts';
import moment from 'moment';
import { IProjectDocumentsStatic } from 'src/app/core/models/project-documents-static-vm';
import { UploaderService } from 'src/app/core/services/uploader.service';
import { IAlert } from 'src/app/core/models/alert';

@Component({
  selector: 'app-static-document',
  templateUrl: './static-document.component.html',
  styleUrls: ['./static-document.component.scss']
})
export class StaticDocumentComponent implements OnChanges {

  @Input() currentProject: Project;
  @Input() currentYear: number;
  public componentName:string = "static-document";

  public staticDocumentsTableColumns: Array<IDynamicTableColumn> = DocumentsStaticTableColumns;
  public questionGroupForm: Array<IDynamicFormBlock> = DocumentsStaticForm;
  public alert: IAlert;

  public displayedDocuments: any;
  public documentLayout: Layout;
  public loadingError: string;
  public currentQueryParams: any;
  public newDocumentLayout: Layout;
  public files: any[] = [];  
  public dynamicButtons = [
    {
      class: 'btn btn-success',
      name: '+ Add Document'
    }
  ];


  constructor(
    public loaderService: LoaderService,
    private projectService: ProjectService,
    private router: Router,
    private route: ActivatedRoute,
    private Uploader: UploaderService
  ) {}

  ngOnChanges(): void {
    // Check for open slider
    this.route.queryParams.subscribe(params => {
      this.currentQueryParams = params;
    });

    if (!this.currentProject.awsDocumentsStatic) {
      this.getProjectSubmissions(this.currentProject.projectId, this.currentYear);
    }
  }

  private getProjectSubmissions(projectId: number, currentYear: number): void {
    this.projectService.getProjectStaticDocuments(projectId, currentYear).subscribe(
      success => {
        success.data.documentList.forEach(value => {
          value["uploadTime"] = moment(value.uploadTime).format('LLL');
        });
        this.currentProject.awsDocumentsStatic = success.data.documentList;

        // Open slider, if in params
        if (this.currentQueryParams.document) {
          this.onTableRowClick(this.currentQueryParams.document);
        }
       },
      error => { 
        console.log('Error: ', error);
        this.loadingError = error.error.error.message;
      }
    )
  }

  public onTableRowClick = (primaryKey: string): void => {
    this.displayedDocuments = this.currentProject.awsDocumentsStatic;
    this.displayedDocuments = this.displayedDocuments.find(document => document.documentId == +primaryKey);
    this.documentLayout = new Layout(
      this.displayedDocuments.awsKeyLocation,
      [ `ID: ${this.displayedDocuments.documentId}` ],
      null
    )
    this.router.navigate([], { queryParams: { document: primaryKey }, queryParamsHandling: 'merge' });
  }

  public closeQuestionSlider = (): void => {
    this.documentLayout = null;
    this.newDocumentLayout = null;
    this.router.navigate([], { queryParams: { document: null }, queryParamsHandling: 'merge' });
  }

  public newDocumentClick() {
    this.newDocumentLayout = new Layout(
      'New Document Upload',
      ['Please upload a document file'],
      null
    )
  }

  public uploadFile(event){
    this.Uploader.staticDocumentUpload('https://membersapidev.nhsbenchmarking.nhs.uk/awsDocuments/addStaticDocument', event, this.currentProject.projectId, this.currentYear).subscribe(success => {
      this.closeQuestionSlider()
      this.getProjectSubmissions(this.currentProject.projectId, this.currentYear);
    },
    error => { 
      console.log('Error: ', error);
      if(error.error.includes('Invalid file extension. Allowed extensions are .xls, .xlsx, .pdf.')){
        this.alert = {
          message: `<strong>Invalid file type: please use .xls, .xlsx, .pdf.</strong>`,
          alertClass: 'danger',
          fadeOut: true,
      }}else{
        this.alert = {
          message: `<strong>Upload Failed</strong>`,
          alertClass: 'danger',
          fadeOut: true,
    };
      }
    })
  }

}

