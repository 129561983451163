import { IDynamicTableColumn } from "src/app/core/models/dynamic-table-column.vm";

export const BoardTableColumns: IDynamicTableColumn[] = [
    {
        header: 'Email Address',
        dataKey: 'Email Address',
        percWidth: 40,
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Full Name',
        percWidth: 20,
        dataKey: 'Full Name',
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Job Title',
        percWidth: 20,
        dataKey: 'Job Title',
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Org 2019',
        percWidth: 5,
        dataKey: 'Org 2019',
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Org 2020',
        percWidth: 5,
        dataKey: 'Org 2020',
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Org 2021',
        percWidth: 5,
        dataKey: 'Org 2021',
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Org 2022',
        percWidth: 5,
        dataKey: 'Org 2022',
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Organisation ID',
        percWidth: 0,
        dataKey: 'Organisation ID',
        sortable: false,
        groupable: false,
        searchable: true
    },
    {
        header: 'Organisation Name',
        percWidth: 30,
        dataKey: 'Organisation Name',
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Organisation Type',
        percWidth: 30,
        dataKey: 'Organisation Type',
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'User Active',
        percWidth: 5,
        dataKey: 'User Active',
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'User ID',
        percWidth: 20,
        dataKey: 'user_id',
        sortable: true,
        groupable: true,
        searchable: true
    },
];

export const ProductLeads: IDynamicTableColumn[] = [
    {
        header: 'Name',
        dataKey: 'full_name',
        percWidth: 20,
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Job Title',
        percWidth: 40,
        dataKey: 'job_title',
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Email',
        percWidth: 30,
        dataKey: 'logon_name',
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Organisation ID',
        percWidth: 0,
        dataKey: 'organisation_id',
        sortable: false,
        groupable: false,
        searchable: true
    },
    {
        header: 'Organisation Name',
        percWidth: 30,
        dataKey: 'organisation_name',
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Phone No',
        percWidth: 30,
        dataKey: 'phone_no',
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Project Name',
        percWidth: 30,
        dataKey: 'project_name',
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'User ID',
        percWidth: 30,
        dataKey: 'user_id',
        sortable: true,
        groupable: true,
        searchable: true
    },
];

export const UserRolesForMH: IDynamicTableColumn[] = [
    {
        header: 'Name',
        dataKey: 'full_name',
        percWidth: 20,
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Job Title',
        percWidth: 40,
        dataKey: 'job_title',
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Email',
        percWidth: 30,
        dataKey: 'logon_name',
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Organisation Name',
        percWidth: 30,
        dataKey: 'organisation_name',
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Phone No',
        percWidth: 30,
        dataKey: 'phone_no',
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Role',
        percWidth: 30,
        dataKey: 'role_name',
        sortable: true,
        groupable: true,
        searchable: true
    },
];

export const OrganisationProductLeads: IDynamicTableColumn[] = [
    {
        header: 'Address 1',
        dataKey: 'Address 1',
        percWidth: 20,
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Address 2',
        percWidth: 40,
        dataKey: 'Address 2',
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Address 3',
        percWidth: 30,
        dataKey: 'Address 3',
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Address 4',
        percWidth: 30,
        dataKey: 'Address 4',
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Address PC',
        percWidth: 30,
        dataKey: 'Address PC',
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'NHSBN',
        percWidth: 30,
        dataKey: 'NHSBN',
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Product Email',
        percWidth: 30,
        dataKey: 'Product Email',
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Product Job',
        percWidth: 30,
        dataKey: 'Product Job',
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Organisation ID',
        percWidth: 0,
        dataKey: 'organisation_id',
        sortable: false,
        groupable: false,
        searchable: true
    },
    {
        header: 'Organisation Name',
        percWidth: 30,
        dataKey: 'organisation_name',
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Postal Address 1',
        percWidth: 30,
        dataKey: 'postal_address_1',
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Postal Address 2',
        percWidth: 30,
        dataKey: 'postal_address_2',
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Postal Address 3',
        percWidth: 30,
        dataKey: 'postal_address_3',
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Postal Address 4',
        percWidth: 30,
        dataKey: 'postal_address_4',
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Postal Address PC',
        percWidth: 30,
        dataKey: 'postal_address_pc',
        sortable: true,
        groupable: true,
        searchable: true
    },
];

export const UserRolesForCAMHS: IDynamicTableColumn[] = [
    {
        header: 'Name',
        dataKey: 'full_name',
        percWidth: 20,
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Job Title',
        percWidth: 40,
        dataKey: 'job_title',
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Email',
        percWidth: 30,
        dataKey: 'logon_name',
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Organisation Name',
        percWidth: 30,
        dataKey: 'organisation_name',
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Phone No',
        percWidth: 30,
        dataKey: 'phone_no',
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Role',
        percWidth: 30,
        dataKey: 'role_name',
        sortable: true,
        groupable: true,
        searchable: true
    },
];

export const UsersFromAcuteOrganisations: IDynamicTableColumn[] = [
    {
        header: 'Name',
        dataKey: 'full_name',
        percWidth: 20,
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Job Title',
        percWidth: 40,
        dataKey: 'job_title',
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Email',
        percWidth: 30,
        dataKey: 'email_address',
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Last Logon',
        percWidth: 30,
        dataKey: 'last_logon',
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Organisation Name',
        percWidth: 30,
        dataKey: 'organisation_name',
        sortable: true,
        groupable: true,
        searchable: true
    },

];

export const NHSILDStaffSurveyDataExtract: IDynamicTableColumn[] = [
    {
        header: 'Name',
        dataKey: 'full_name',
        percWidth: 20,
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Job Title',
        percWidth: 40,
        dataKey: 'job_title',
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Email',
        percWidth: 30,
        dataKey: 'logon_name',
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Organisation Name',
        percWidth: 30,
        dataKey: 'organisation_name',
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Phone No',
        percWidth: 30,
        dataKey: 'phone_no',
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Role',
        percWidth: 30,
        dataKey: 'role_name',
        sortable: true,
        groupable: true,
        searchable: true
    },
];

export const NHSEILDRegistrationAddresses: IDynamicTableColumn[] = [
    {
        header: 'Organisation ID',
        dataKey: 'organisation_id',
        percWidth: 20,
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Organisation Name',
        dataKey: 'organisation_name',
        percWidth: 30,
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Registration Locked',
        dataKey: 'registration_locked',
        percWidth: 15,
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Registration Lead Name',
        dataKey: 'registration_lead_name',
        percWidth: 30,
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Registration Lead Email',
        dataKey: 'registration_lead_email',
        percWidth: 30,
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Registration Lead Job Title',
        dataKey: 'registration_lead_job_title',
        percWidth: 40,
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Address Line 1',
        dataKey: 'address_1',
        percWidth: 30,
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Address Line 2',
        dataKey: 'address_2',
        percWidth: 30,
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Address Line 3',
        dataKey: 'address_3',
        percWidth: 30,
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Address Line 4',
        dataKey: 'address_4',
        percWidth: 30,
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Postcode',
        dataKey: 'address_postcode',
        percWidth: 20,
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Address Contact',
        dataKey: 'address_contact',
        percWidth: 25,
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Phone Number',
        dataKey: 'address_phone',
        percWidth: 20,
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Registration Completed',
        dataKey: 'registration_completed',
        percWidth: 15,
        sortable: true,
        groupable: true,
        searchable: true
    },
];

export const OrganisationDeputyProductLeads: IDynamicTableColumn[] = [
    {
        header: 'Name',
        dataKey: 'full_name',
        percWidth: 20,
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Job Title',
        percWidth: 40,
        dataKey: 'job_title',
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Email',
        percWidth: 30,
        dataKey: 'logon_name',
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Organisation Name',
        percWidth: 30,
        dataKey: 'organisation_name',
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Organisation ID',
        percWidth: 0,
        dataKey: 'organisation_id',
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'User ID',
        percWidth: 10,
        dataKey: 'user_id',
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Phone No',
        percWidth: 30,
        dataKey: 'phone_no',
        sortable: true,
        groupable: true,
        searchable: true
    },
];

export const ProductAndDeputyProductLeads: IDynamicTableColumn[] = [
    {
        header: 'Product ID',
        dataKey: 'product_id',
        percWidth: 0,
        sortable: false,
        groupable: false,
        searchable: true
    },
    {
        header: 'Product Name',
        dataKey: 'product_name',
        percWidth: 30,
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Organisation ID',
        dataKey: 'organisation_id',
        percWidth: 0,
        sortable: false,
        groupable: false,
        searchable: true
    },
    {
        header: 'Organisation Name',
        dataKey: 'organisation_name',
        percWidth: 30,
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Role Type',
        dataKey: 'role_type',
        percWidth: 15,
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Role Name',
        dataKey: 'role_name',
        percWidth: 25,
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'User ID',
        dataKey: 'user_id',
        percWidth: 20,
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Full Name',
        dataKey: 'full_name',
        percWidth: 30,
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Logon Name',
        dataKey: 'logon_name',
        percWidth: 30,
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Active Status',
        dataKey: 'is_active',
        percWidth: 15,
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Job Title',
        dataKey: 'job_title',
        percWidth: 30,
        sortable: true,
        groupable: true,
        searchable: true
    },
];

export const ProjectAndDeputyProjectLeads: IDynamicTableColumn[] = [
    {
        header: 'Name',
        dataKey: 'full_name',
        percWidth: 20,
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Job Title',
        percWidth: 40,
        dataKey: 'job_title',
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Email',
        percWidth: 30,
        dataKey: 'logon_name',
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Organisation Name',
        percWidth: 30,
        dataKey: 'organisation_name',
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Organisation ID',
        percWidth: 0,
        dataKey: 'organisation_id',
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Project ID',
        percWidth: 0,
        dataKey: 'project_id',
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Project Name',
        percWidth: 30,
        dataKey: 'project_name',
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Role Name',
        percWidth: 30,
        dataKey: 'role_name',
        sortable: true,
        groupable: true,
        searchable: true
    },    {
        header: 'Role Type',
        percWidth: 0,
        dataKey: 'role_type',
        sortable: true,
        groupable: true,
        searchable: true
    },    {
        header: 'User ID',
        percWidth: 30,
        dataKey: 'user_id',
        sortable: true,
        groupable: true,
        searchable: true
    },
];

export const NACELOverviewEnglandWales: IDynamicTableColumn[] = [
    {
        header: 'Audit Completion',
        dataKey: 'audit_completion',
        percWidth: 10,
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Cnr Started',
        percWidth: 10,
        dataKey: 'cnr_started',
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Country',
        percWidth: 30,
        dataKey: 'country',
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Organisation Name',
        percWidth: 30,
        dataKey: 'organisation_name',
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Organisation ID',
        percWidth: 0,
        dataKey: 'organisation_id',
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Organisational Level',
        percWidth: 10,
        dataKey: 'organisational_level',
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Peer Group Name',
        percWidth: 30,
        dataKey: 'peer_group_name',
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Quality Staff Survey Not Submitted',
        percWidth: 10,
        dataKey: 'quality_staff_survey_not_submitted',
        sortable: true,
        groupable: true,
        searchable: true
    },    
    {
        header: 'Quality Staff Survey Started',
        percWidth: 10,
        dataKey: 'quality_staff_survey_started',
        sortable: true,
        groupable: true,
        searchable: true
    },    
    {
        header: 'Staff Survey Started',
        percWidth: 10,
        dataKey: 'staff_survey_started',
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Submission ID',
        percWidth: 10,
        dataKey: 'submission_id',
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Submission Name',
        percWidth: 30,
        dataKey: 'submission_name',
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Submission Type',
        percWidth: 30,
        dataKey: 'submission_type',
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Trust Level',
        percWidth: 30,
        dataKey: 'trust_level',
        sortable: true,
        groupable: true,
        searchable: true
    },
];