import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';

import { ValidationDetailsFormBlocks, ValidationSliderTableColumns, ValidationTableColumns } from './project-validation-layouts';
import { Chart } from 'angular-highcharts';
import { IDynamicTableColumn } from 'src/app/core/models/dynamic-table-column.vm';
import { IProjectValidation } from 'src/app/core/models/project-validation.vm';
import { ProjectService } from 'src/app/core/services/project.service';
import { LoaderService } from 'src/app/core/services/loader.service';
import { Project } from 'src/app/core/classes/project';
import { ILayout } from 'src/app/core/models/layout.vm';
import { Layout } from 'src/app/core/classes/layout';
import { IDynamicFormBlock } from 'src/app/core/models/dynamic-form-block.vm';
import { IAlert } from 'src/app/core/models/alert';
import { UserService } from 'src/app/core/services/user.service';
import { OutputValidationService } from 'src/app/core/services/validation.service';
import { ValidationProject } from 'src/app/core/classes/validation';
import { IUser } from 'src/app/core/models/user.vm';
import { IDynamicFormEmit } from 'src/app/core/models/dynamic-form-emit.vm';
import { IProjectValidationTier } from 'src/app/core/models/project-validation-tier.vm';
import { IProjectValidationData } from 'src/app/core/models/project-validation-data.vm';
import { IProjectValidationResponse } from 'src/app/core/models/project-validation-response.vm';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-project-validation',
  templateUrl: './project-validation.component.html',
  styleUrls: ['./project-validation.component.scss']
})
export class ProjectValidationComponent implements OnInit {
  @Input() public currentProject: Project;
  @Input() public currentYear: number;

  public validationTableColumns: Array<IDynamicTableColumn> = ValidationTableColumns;
  public validationSliderTableColumns: Array<IDynamicTableColumn> = ValidationSliderTableColumns;
  public ValidationDetailsForm: Array<IDynamicFormBlock> = ValidationDetailsFormBlocks;

  public validationSelection: IProjectValidation;
  public validationLayout: ILayout;
  public alert: IAlert;

  public validationDescriptionForm: FormGroup;
  public validationNoteForm: FormGroup;

  public chart: Chart;
  public mean: number;
  public median: number;
  public tierSearch: string;
  public searchableTiers: Array<IProjectValidationTier>;
  public selectedTier: IProjectValidationTier;
  public errorMessage: string;
  public selectedYear: number;
  public loadingMessage: string;
  public noData: boolean = false;
  public selectedValidation: IProjectValidation;
  public project: ValidationProject;
  public selectedOptionId: any = null;
  public selectedProjectId: number;
  public notePosted: boolean = false;
  public applyFilter: boolean = false;
  public showListView: boolean = true;
  public responsePosted: boolean = false;
  public selectedPeerGroupId: number = null;
  public currentQueryParams: any;
  public initialSelectedTier: IProjectValidationTier;
  public descriptionPosted: boolean = false;
  public selectedDeleteValidation: number;
  public modalInfo: Object = null; 
  public availableResponses: Array<IProjectValidationResponse>;
  public peerGroupId: number;
  public optionId: number;
  public chartObject: any;
  public currentUser: IUser;
  public outlierIdentifierRunning: boolean = false;
  public darkMode$: Observable<boolean>;
  public responseSet: boolean = false;
  public scrollToId: boolean = false;
  public sameSubmissionValidate: boolean = null;
  public actionEdit: boolean = false;
  public chartClicked: boolean = false;
  public setOptionId: number = 0;
  public comment: string;
  public chatHistory = null;
  public hasWhitespace: boolean = false;

  constructor(
    public loaderService: LoaderService,
    private projectService: ProjectService,
    private route: ActivatedRoute,
    private router: Router,
    private users: UserService,
    private outputValidationService: OutputValidationService,
    private store: Store<{ darkMode: boolean }>,
  ) { 
    this.darkMode$ = store.select('darkMode')
  }

  ngOnInit(): void {
    // Check for open validation
    this.route.queryParams.subscribe(params => {
      this.currentQueryParams = params;
    });
    this.currentUserCheck();
  }







  shiftOption(direction: string): void {
    // Check current index
    let currentIndex = this.selectedTier.report.options.denominators.findIndex(opt => opt.optionId == this.selectedOptionId);
    let newIndex;
    // Check length of availableOptions
    let optionsLength = this.selectedTier.report.options.denominators.length;
    // Update index based on direct +/- 1
    if (direction == 'up') {
      newIndex = currentIndex + 1;
    } else {
      newIndex = currentIndex - 1;
    }
    // If within available options, getTierYears with new optionId
    if (newIndex > -1 && newIndex < optionsLength) {
      this.selectedOptionId = this.selectedTier.report.options.denominators[newIndex].optionId.toString();
      this.getTier(this.selectedTier, this.selectedOptionId, this.selectedPeerGroupId)
    }
  }










  public scrollToTierId(id: string) {
    document.getElementById(id).scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' })
  }

  public triggerOutlierIdentifier(projectId: number, year: number): void {
    this.alert = { message: `Outlier identifier started.`, alertClass: 'success', fadeOut: true };
    this.outlierIdentifierRunning = true;
    this.projectService.triggerOutlierIdentifier(projectId, year).subscribe(
      success => {
        this.alert = { message: `Outliers returned. Refreshing validations...`, alertClass: 'success', fadeOut: true };
        this.getValidations(projectId, year);
        this.outlierIdentifierRunning = false;
      },
      error => {
        // No error messages returned
        this.alert = { message: 'There has been an error with the outlier identifier. Please refresh and try again.', alertClass: 'danger', fadeOut: false };
        this.outlierIdentifierRunning = false;
      }
    )
  }

  public onValidationClick = (primaryKey: any): void => {
    this.getChatHistory(primaryKey);
    this.validationSelection = this.project.validations.find(vld => vld.validationId == +primaryKey);
      if(this.validationSelection.validationResponseId) {
      this.responseSet = true;
    } else {
      this.responseSet = false;
    }
    if(this.validationSelection.validationId) {
      this.chartClicked = true;
      this.getValidationResponses(this.validationSelection);
      this.buildValidationDescriptionForm(this.validationSelection);
      this.buildValidationNoteForm(this.validationSelection);
    } else {
      this.editValidation(this.validationSelection, false)
    }
  }

  setSliderInfo() {
    this.validationLayout = new Layout(
      this.validationSelection.submissionName,
      [ `Validation ID: ${this.validationSelection.validationId}` ],
      null
    )
    this.router.navigate([], { queryParams: { validation: this.validationSelection.validationId }, queryParamsHandling: 'merge' });
  }

  public closeValidationSlider = (): void => {
    this.validationSelection = null;
    this.availableResponses = null;
    this.responseSet = false;
    this.router.navigate([], { queryParams: { validation: null }, queryParamsHandling: 'merge' });
  }

  noteUpdate(note: IDynamicFormEmit) {
    this.editValidationStatus(note.row.validationId, note.row.status)
    this.editValidationNote(this.validationSelection, note.row.notes)
    this.closeValidationSlider();
  }

private currentUserCheck(): void {
  this.users.getUser().subscribe(
    success => {
      this.currentUser = success.data;
      if (this.currentUser && this.currentUser.isAdmin == 'Y') {
        this.getTiers(this.currentProject.projectId, this.currentYear);
      }
    },
    error => {
      console.log(error);
      this.errorMessage = error.error.error.message;
    }
  )
}

public onTableTdClick = (primaryKey: any, action: string): void => {
  if(action == 'accept') {
    let project = this.project.validations.filter(k => k.validationId == primaryKey)
    this.editValidationStatus(primaryKey, project[0].status == 'accepted' ? 'pending' : 'accepted')
  }
  
  if(action == 'set') {
    this.responseSet = true;
    this.setResponse(primaryKey);
    this.getChatHistory(primaryKey);
  }

  if(action == 'internalLink') {
    this.scrollToId = true;
    this.getTier(primaryKey.tier, primaryKey.optionId, 0, primaryKey);
    this.switchView(false);
  }
  if(action == 'externalLink') {
    this.dataCollectionLink(primaryKey)
  }

  if(action == 'edit') {
    this.getChatHistory(primaryKey);
    this.actionEdit = true;
    this.availableResponses = null;
    this.onValidationClick(primaryKey)
  }
  if(action == 'validate') {
    let project = this.project.validations.filter(k => k.validationId == primaryKey)
    this.editValidationStatus(primaryKey, project[0].status == 'validated' ? 'pending' : 'validated')
  }
  if(action == 'delete') {
    this.deleteValidation(primaryKey)
  }
};

private getTiers(projectId: number, year: number): void {
  this.loadingMessage = "Loading validations. Please wait...";
  this.outputValidationService.getProjectTiers(projectId, year).subscribe(
    success => {
      this.project = new ValidationProject(success.data);
      if(this.project.validations == null) {
        this.project.validations = [];
      }
      this.searchableTiers = this.project.tiers.filter(tier => tier.reportId);
      this.getValidations(projectId, year);
    },
    error => {
      this.errorMessage = "There was an error retrieving tiers for this project/year combination."
      console.log(error);
    }
  )
}

private getValidations(projectId: number, year: number, validate?): void {
  this.outputValidationService.getValidations(projectId, year).subscribe(
    success => {
      this.project.setValidationsToTiers(success.data.validations, this.currentProject.projectId, this.currentYear);
      this.project.setFilters(success.data.validations);
      // If tier pre-selected
      let selectedTierId = +this.route.snapshot.queryParams['tier'];
      if (selectedTierId && !validate) {
        this.initialSelectedTier = this.project.tiers.find(tier => tier.id == selectedTierId);
        this.getTier(this.initialSelectedTier, 0);
      }
      
      if (this.currentQueryParams.validation && !this.validationSelection) {
        this.onValidationClick(this.currentQueryParams.validation);
        this.getChatHistory(this.currentQueryParams.validation);
      }
    },
    error => {
      console.log(error);
    },
    () => {
      this.loadingMessage = null;
    }
  )
}

public getTier(tier: IProjectValidationTier, optionId?: any, peerGroupId?: any, primaryKey?: any): void {
  if(!optionId && !peerGroupId) {
    this.applyFilter = false;
  } else {
    this.applyFilter = true;
  }
  this.peerGroupId = peerGroupId
  this.optionId = optionId
  peerGroupId = parseInt(peerGroupId)
  optionId = parseInt(optionId)
  this.tierSearch = null;
  this.noData = false;
  this.loadingMessage = "Loading report details and data. Please wait...";
  this.errorMessage = null;
  this.chartObject = null;
  this.chart = null;
  this.showListView = false;
  if(!this.selectedOptionId || optionId || optionId == 0) {
    this.selectedOptionId = optionId || 0;
  }
  if(Number.isNaN(optionId)) {
    this.selectedOptionId = this.setOptionId;
  }
  this.selectedPeerGroupId = peerGroupId || 0;
    this.outputValidationService.getTier(tier.reportId, 232, this.selectedPeerGroupId, this.currentYear, tier.submissionLevel, tier.serviceItemId, this.selectedOptionId).subscribe(
      success => {
        tier.report = this.project.setReportDetails(success.data, this.selectedOptionId, this.currentYear);
        tier.data = this.project.setValidationsToData(tier.validations, success.data.yearData[this.currentYear], tier.report.viewTypes[0].viewType, this.selectedOptionId);
        let values = tier.data.map(d => d.y);
        this.mean = this.project.mean(values);
        this.median = this.project.median(values);
        this.selectedTier = tier;
        this.router.navigate([], { queryParams: { tier: tier.id }, queryParamsHandling: 'merge' });
        let chartTypeCheck: boolean = this.project.chartTypeCheck(tier.report.viewTypes[0]);
        let isGenericCheck: boolean = tier.report.storedProcedure.includes('generic');
        if (chartTypeCheck && isGenericCheck) {
          this.buildChart(tier.data, this.mean, this.median);
        } else {
          this.errorMessage = `<strong>This chart is not yet available for validation.</strong> This may be because of the chart type (${tier.report.viewTypes[0].viewType}) or because the chart is not built using parameters.`
        }
      },
      error => {
        this.errorMessage = error.error.error.message;
        console.log(error);
      },
      () => {
        this.loadingMessage = null;
        if(this.scrollToId) this.scrollToTierId(primaryKey.tierId);
        this.scrollToId = false;
      }
    ) 
}

public buildChart(data: Array<IProjectValidationData>, mean: number, median: number): void {
  if(data.length == 0){
    this.noData = true;
  }
  this.chartObject = {
    colors: [ 'lightgray' ],
    chart: { style: { fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',  }},
    title: { text: '', style: { fontWeight: 'bold' } },
    xAxis: { categories: data.map(d => d.submissionCode),
      labels: {
        rotation: data.length > 25 ? 60 : 0,
        step: 1,
        formatter () {
          let color = data.find(d => d.submissionCode == this.value).color || null;
          return `<span style="color:${color}">${this.value}</span>`
        },
        style: {
          color: 'grey'
        }
      },
      title: '', 
      tickInterval: undefined },
    yAxis: { 
      title: { text: null },
      min: null,
      max: null,
      labels: {
        style: {
          color: 'grey'
        }
      },
      plotLines: [
        {
          color: '#009639',
          value: mean,
          width: '2',
          zIndex: 10
        },
        {
          color: '#78BE20',
          value: median,
          width: '2',
          zIndex: 11
        }
      ],
      tickInterval: undefined,
      dateTimeLabelFormats: {}
    },
    tooltip: {},
    exporting: { enabled: false },
    plotOptions: { 
      series: { 
        cursor: 'pointer',
        animation: false
      }
    },
    credits: { text: ''},
    legend: { enabled: false },
    series: [
      {
        type: 'column',
        data: data
      }
    ]
  };

  this.chart = new Chart(this.chartObject);
  this.chartObject = this.chartObject

  if(this.applyFilter) {
    if(this.selectedPeerGroupId !== this.peerGroupId) {
      this.selectedPeerGroupId = this.peerGroupId || 0;
    }
    if(this.selectedOptionId !== this.optionId) {
      this.selectedOptionId = this.optionId;
      this.setOptionId = this.selectedOptionId;
    }
  }

  if(this.optionId == undefined) {
    this.selectedOptionId = this.setOptionId;
  }
}

highchartsColourMode(mode: string) {
  // Chart in dark mode
  if(mode == 'dark') {
    this.chartObject.chart.backgroundColor = '#2a2a2b';
    this.chartObject.yAxis.labels.style.color = '#E0E0E3';
    this.chartObject.xAxis.labels.style.color = '#E0E0E3';
  } else {
    this.chartObject.chart.backgroundColor = '';
    this.chartObject.yAxis.labels.style.color = 'grey';
    this.chartObject.xAxis.labels.style.color = 'grey';
  }
  this.chart = new Chart(this.chartObject);

  return this.chart;
}

allowedChartTypes(value: string) {
  let allowedChartTypes = ['B1 ','B2 ','C1 ','C2 ', 'DB '];
  return allowedChartTypes.includes(value) || value == null;
}

public chartClick = (event: any): void => {
  // Remove any errors
  this.errorMessage = null;
  // Define point data
  let pointData = null;
  // Highcharts bar selected
  if (event.point) {
    pointData = {
      submissionCode: +event.point.category.substr(2),
      submissionId: event.point.submissionId,
      y: event.point.y
    };
  }
  // Highcharts axis label selected
  else if (event.target.innerHTML) {
    let point = this.selectedTier.data.find(d => d.submissionCode === event.target.innerHTML);
    pointData = {
      submissionCode: point.submissionCode.substr(2),
      submissionId: point.submissionId,
      y: point.y
    };
  }
  this.chartClicked = true;
  // Check for no codes and duplicates, then build
  let submissionCodeNaN = isNaN(+pointData.submissionCode);
  let duplicatePoint = this.selectedTier.validations.find(vld => vld.submissionCode == pointData.submissionCode && vld.optionId == this.selectedOptionId);
  if (pointData && !duplicatePoint && !submissionCodeNaN) {
    this.sameSubmissionValidate = true;
    this.editValidation(pointData, true);
  }
  else if (pointData && submissionCodeNaN) {
    alert('Submission codes have not been assigned. Codes must be assigned before validation can begin.')
  } 
  else if (pointData && duplicatePoint) {
    this.availableResponses = null;
    this.onValidationClick(duplicatePoint.validationId);
  }
  else {
    console.log('No point selected')
  }
}

public buildValidationDescriptionForm(validation: IProjectValidation): void {
  this.validationDescriptionForm = new FormGroup({
    validationDescription: new FormControl(validation.validationDescription ? validation.validationDescription : ''),
  });
}

public postValidation(pointData: any): void {

  let submissionCodeCheck = this.selectedTier.validations.find(vld => vld.submissionCode == pointData.submissionCode);

  if (!submissionCodeCheck || this.sameSubmissionValidate) {
    let validation: any = {
      validationId: undefined,
      year: this.currentYear,
      tierId: this.selectedTier.id,
      submissionCode: pointData.submissionCode,
      validationValue: pointData.y,
      serviceItemId: this.selectedTier.serviceItemId,
      validationDescription: '',
      optionId: this.selectedOptionId
    }

    this.outputValidationService.postValidation(validation).subscribe(
      success => {
        validation.validationId = success.data.newValidationId;
        validation['submissionId'] = pointData.submissionId;
        validation['validatorName'] = this.currentUser.fullName;
        validation['submissionName'] = success.data.submissionName;
        validation['reportId'] = this.selectedTier.reportId;
        validation['reportName'] = this.selectedTier.report.reportName;
        validation['status'] = null;
        validation['createdAt'] = null;
        validation['lastUpdated'] = null;
        validation['questionId'] = null;
        validation['optionId'] = this.selectedOptionId;
        validation['titleOptionName'] = this.selectedTier.report.options.denominators.find(den => { den.optionId == this.selectedOptionId }) || null;

        this.project.validations.push(validation);

        this.project.setValidationsToTiers(this.project.validations, this.currentProject.projectId, this.currentYear);
        this.project.setValidationsToData(this.selectedTier.validations, this.selectedTier.data, this.selectedTier.report.viewTypes[0].viewType, this.selectedOptionId);
        this.buildChart(this.selectedTier.data, this.mean, this.median);

        this.selectedValidation = validation;
        this.getValidations(this.currentProject.projectId, this.currentYear, true);
        this.onValidationClick(this.selectedValidation.validationId);
        this.sameSubmissionValidate = null;
      },
      error => {
        console.log(error);
      }
    )
  } else {
    alert('This point has already been added for validation.')
  }
}

public getValidationResponses(validation: IProjectValidation): void {
  this.comment = null;
  // Availability of reportId and serviceItemId varies on use case
  let reportId = validation.reportId ? validation.reportId : (this.selectedTier?.reportId ? this.selectedTier.reportId : null);
  let serviceItemId = this.selectedTier ? this.selectedTier.serviceItemId : validation.serviceItemId;
  if(this.validationSelection) {
    this.setSliderInfo();
  }
  let rowOptionId: any;
  if(validation?.optionId >= 0 && validation?.optionId !== null && this.actionEdit == true) {
    rowOptionId = validation.optionId.toString();
  }
  if(this.chartClicked == true || this.actionEdit == true) {
    this.outputValidationService.getValidationResponses(reportId || null, this.currentYear, validation.submissionId, serviceItemId || 0, rowOptionId || this.selectedOptionId).subscribe(
      success => { 
        this.availableResponses = this.project.setAvailableResponses(success.data.responses, this.currentYear, validation, this.responsePosted);
        this.actionEdit = false;
        this.chartClicked = false;
      },
      error => { 
        console.log(error) 
      }
    )
  }
}

public setResponse(responseId: number): void {
  let question = this.availableResponses.find(vld => vld.responseId == +responseId);
  let response = question;
  let validation = this.validationSelection;
  let validationResponse = {
    validationId: response.validationId,
    submissionId: response.submissionId, 
    questionId: response.questionId, 
    questionPart: response.questionPart, 
    serviceItemId: validation ? validation.serviceItemId : this.selectedTier.serviceItemId
  };
  this.outputValidationService.addValidationResponse(validationResponse).subscribe(
    success => {
      let validation = this.project.validations.find(vld => vld.validationId == response.validationId);
      validation.validationResponseId = success.data.newValidationResponseId;
      validation.response = response.response;
      validation.displayQuestionText = response.displayQuestionText;
      validation.questionId = response.questionId;
      validation.questionPart = response.questionPart;
      this.validationSelection = validation;
      this.buildValidationNoteForm(validation);
      this.responsePosted = true;
      response.set = true;
      this.getValidations(this.currentProject.projectId, this.currentYear, true);
    },
    error => {
      console.log(error);
    }
  )
}

public buildValidationNoteForm(validation: IProjectValidation): void {
  this.validationNoteForm = new FormGroup({
    validationResponseId: new FormControl(validation.validationResponseId),
    notes: new FormControl(validation.notes ? validation.notes : ''),
  });
}

public editValidationNote(form: IProjectValidation, note: string): void {
  let updatedValidation = {
    validationResponseId: form.validationResponseId,
    note: note
  }

  this.outputValidationService.editValidationNote(updatedValidation).subscribe(
    success => {
      form.notes = note;
      this.selectedTier?.validations.find(item => {
        if(item.validationId == form.validationId) {
          item.notes = note;
        }
      })
      this.validationNoteForm.markAsPristine;
      this.notePosted = true;
      setTimeout(() => { this.notePosted = false }, 2000);
      this.alert = { message: "Changes saved!", alertClass: 'success', fadeOut: true };
    },
    error => {
      console.log(error);
      this.alert = { message: "Error! Changes have not been saved", alertClass: 'danger', fadeOut: true };
    }
  )
}

public editValidationDescription(selectedValidation: IProjectValidation, note: string, alert?: string): void {
  let updatedValidation = {
    validationId: selectedValidation.validationId,
    description: note,
    alertUser: alert
  }
  this.outputValidationService.editValidationDescription(updatedValidation).subscribe(
    success => {
      selectedValidation.validationDescription = note;
      this.selectedTier?.validations.find(item => {
        if(item.validationId == selectedValidation.validationId) {
          item.validationDescription = note;
        }
      })
      this.validationDescriptionForm.markAsPristine;
      this.descriptionPosted = true;
      setTimeout(() => { this.descriptionPosted = false }, 2000);
      this.getChatHistory(this.currentQueryParams.validation);
    },
    error => {
      console.log(error);
    }
  )
}

closeModal(value: boolean) {
  if(value == true) {
    this.outputValidationService.deleteValidation(this.selectedDeleteValidation).subscribe(
      success => {
        this.project.validations = this.project.validations.filter(v => v.validationId !== this.selectedDeleteValidation);
        this.project.setValidationsToTiers(this.project.validations, this.currentProject.projectId, this.currentYear);
        if(this.selectedTier) {
          this.project.setValidationsToData(this.selectedTier.validations, this.selectedTier.data, this.selectedTier.report.viewTypes[0].viewType, this.selectedOptionId);
          this.buildChart(this.selectedTier.data, this.mean, this.median);
        }
        this.selectedValidation = null;
        this.modalInfo = null;
      },
      error => {
        console.log(error);
      }
    )
  }
  this.modalInfo = null;
}

public deleteValidation(validationId: number): void {
  let validation = this.project.validations.find(vld => vld.validationId == +validationId);
  this.modalInfo = {
    item: validation.reportName || validation.displayQuestionText, 
    subText: " Are you sure you want to delete the validation for", 
    title: "Validation",
    action: "deletion",
    warning: "All information associated to this validation will be permanently deleted.",
    warningRed: "This operation can not be undone.",
    request: "Delete"
  };

  this.selectedDeleteValidation = validationId;
}

public editValidationStatus(validationId: number, status: string): void {
  let updatedValidation = {
    validationId: validationId,
    status: status
  }
  this.outputValidationService.editValidationStatus(updatedValidation).subscribe(
    success => {
      let validation = this.project.validations.find(vld => vld.validationId == validationId);
      validation.status = status;
      this.project.setValidationsToTiers(this.project.validations, this.currentProject.projectId, this.currentYear);
      if(this.selectedTier) {
        this.project.setValidationsToData(this.selectedTier.validations, this.selectedTier.data, this.selectedTier.report.viewTypes[0].viewType, this.selectedOptionId);
        this.buildChart(this.selectedTier.data, this.mean, this.median);   
      }
      this.validationSelection = null;
    },
    error => {
      console.log(error);
    }
  )
}

public dataCollectionLink(validation: IProjectValidation): void {
  let url: string;
  if (validation.serviceItemId > 0) {
    url = 'https://members.nhsbenchmarking.nhs.uk/data-collection/' 
      + validation.questionGroupLevel + '/' 
      + validation.submissionId + '/' 
      + validation.validationYear + '/' 
      + validation.serviceItemId + '?group=' 
      + validation.questionGroupId + '#'
      + validation.questionId;
  } else {
    url = 'https://members.nhsbenchmarking.nhs.uk/data-collection/' 
      + validation.questionGroupLevel + '/' 
      + validation.submissionId + '/'
      + validation.validationYear + '?group=' 
      + validation.questionGroupId + '#'
      + validation.questionId;
  }
  window.open(url, '_blank','noopener');
}

public switchView(showListView: boolean): void {
  this.errorMessage = null;
  if (showListView) {
    this.router.navigate([], { queryParams: { tier: null }, queryParamsHandling: 'merge' });
    this.showListView = true;
    this.project.validations = null;
    this.selectedTier = null;
    this.getValidations(this.currentProject.projectId, this.currentYear);
  } else {
    this.showListView = false;
    this.selectedTier = null;
  }
}

public toggleTierCollapsed(tier: IProjectValidationTier, collapse?: boolean): void {
  tier.children = this.project.toggleChildren(tier.children, collapse);
}

public editValidation(validation: IProjectValidation, create: boolean): void {
  this.selectedValidation = validation;
  if (create) {
    this.postValidation(validation);
  } else {
    this.getValidationResponses(validation);
    this.buildValidationDescriptionForm(validation);
    this.buildValidationNoteForm(validation)
  }
}

public getSearchTier(tier: IProjectValidationTier, allTiers: Array<IProjectValidationTier>): void {
  this.loadingMessage = "Loading report details and data. Please wait...";
  callRecursively(tier, allTiers);
  function callRecursively(tier: IProjectValidationTier, tiers: any) {
    let parent = tiers.find(t => t.id == tier.parentId);
    if (parent) { 
      parent.children.forEach(child => child.collapsed = false);
      callRecursively(parent, tiers)
    }
  }
  this.getTier(tier);
}

getChatHistory(validationId: number) {
  this.chatHistory = null;
  this.outputValidationService.ValidationConversationHistory(validationId).subscribe(
    success => {
      this.chatHistory = success.result;
      this.chatHistory.reverse();
    },
    error => {
      console.log(error);
    }
  )
}

checkWhitespace(event: Event): void {
  const input = event.target as HTMLInputElement;
  if (input.value.startsWith(' ')) {
    this.hasWhitespace = true;
    input.value = '';
    this.comment = null;
  } else {
    this.hasWhitespace = false;
  }
}

}