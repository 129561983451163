<app-alert-status *ngIf="loadingError" [loadingError]="loadingError"></app-alert-status>
<app-alert-status [alert]="alert" [parent]="componentName" (emitAlertCancelled)="alert = $event"></app-alert-status>

<div class="row justify-content-start">
    <div class="col-md-2">
      <app-section-nav [navItems]="eventDetailNavItems"></app-section-nav>
    </div>
    <div class="col-md-10 mb-5">
      <app-dynamic-form
        *ngIf="currentScope == 'basics'"
        [data]="currentEvent"
        [blocks]="eventDetailBasicsBlocks"
        (dataUpdate)="dataUpdate($event)"
      ></app-dynamic-form>
    </div>
</div>
