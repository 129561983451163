<app-loading-status *ngIf="loaderService.isLoading | async; else notLoading"></app-loading-status>
<app-alert-status *ngIf="loadingError" [loadingError]="loadingError"></app-alert-status>

<ng-template #notLoading>
    <ng-container>
      <div class="container-fluid">
        <div class="row justify-content-center">
          <div class="col-md-11 col-12">
            <div class="row mt-5 mb-3">
              <div class="col-md-7 col-12" *ngIf="layout">
                <app-area-heading [heading]="layout.heading" [subheading]="layout.subheading"
                  [backRoute]="layout.backRoute"></app-area-heading>
              </div>
              <div class="col-md-5 col-12 d-flex">
                <div *ngIf="currentYear" class="ml-auto">
                    <app-year-select (emitCurrentYear)="yearChange($event)"></app-year-select>
                </div>
            </div>
            </div>
            <app-dynamic-table 
              [data]="reportDetail" 
              [columns]="globalTableColumns" 
              [tableTitle]="currentReportName + ' Export'"
              [primaryKey]="'organisationId'"
              [disableRowClick]="true" 
              >
            </app-dynamic-table>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-template>
  