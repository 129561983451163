    <!-- Loading and error -->
<app-loading-status *ngIf="(loaderService.isLoading | async) && !dropdowns; else notLoading"></app-loading-status>
<app-alert-status *ngIf="loadingError" [loadingError]="loadingError"></app-alert-status>
<app-alert-status *ngIf="alert" [alert]="alert" [parent]="componentName" (emitAlertCancelled)="alert = $event"></app-alert-status>


<ng-template #notLoading>
    <!-- Table -->
    <app-dynamic-table 
        *ngIf="currentUser.roles"
        [data]="currentUser.roles"
        [columns]="UsersRolesTableColumns"
        [tableTitle]="'userId'"
        [primaryKey]="'roleId'"
        [onRowClick]="onUserRoleClick"
        [dynamicButtons]="dynamicButtons"
        (buttonClicked)="onDynamicButtonClick($event)">
    </app-dynamic-table>

    <!-- Selected Role -->
    <app-slider-panel *ngIf="currentUserRole" [onClose]="closeUserRoleSlider">
        <!-- Heading -->
        <app-area-heading [heading]="roleLayout.heading" [subheading]="roleLayout.subheading" [backRoute]="roleLayout.backRoute"></app-area-heading>
        <!-- Form -->
        <app-dynamic-form [data]="currentUserRole" [blocks]="UserRolesDetailsForm"></app-dynamic-form>

        <button (click)="editDetails()" class="btn btn-success">Edit Details</button>
    </app-slider-panel>


    <!-- Add Role -->
    <app-slider-panel [layer]="1"  *ngIf="openAddRole" [onClose]="closeAddUserRoleSlider" [pageWidth]="50">
        <!-- Heading -->
        <app-area-heading [heading]="addRoleLayout.heading" [subheading]="addRoleLayout.subheading" [backRoute]="addRoleLayout.backRoute"></app-area-heading>
            <!-- Form -->
            <div class="d-flex">
            <div class="col-md-12" style="width: 100%;">
            <ng-container class="container" *ngFor="let dropdown of dropdowns; let i = index">
            <div>
                <div *ngIf="dropdown.type == 'select'"  style="margin-bottom: 10px;">
                    <label style="font-weight: 600;">{{ dropdown.name }} </label>
                    <select [id]="dropdown.id"  class="form-control" [(ngModel)] = "dropdown.default" (ngModelChange)='selectItem($event, dropdown)'>
                        <ng-container>
                            <option [ngValue]="null">Please select an option...</option>
                            <option *ngFor="let item of dropdown.data" [ngValue]="item">
                                {{ item.name }}
                            </option>
                        </ng-container>
                    </select>
                </div>

            <div *ngIf="dropdown.type !== 'select'" class="form-group position-relative" (clickElsewhere)="dropdown.active = false">
                <div class="d-flex justify-content-between">
                    <label>{{ dropdown.name }} </label>
                </div>
                <small [id]="dropdown.id + 'Help'" class="form-text text-muted" [innerHTML]="dropdown.helpText"></small>
                        <div [id]="dropdown.id" class="form-control c-pointer text-truncate" 
                            (click)="toggleDropdown(dropdown.id);" [innerHtml]="dropdown.selectedOption?.name">
                        </div>
                        <div class="border rounded py-2 px-3 dd-container mt-5" *ngIf="dropdown.active">
                            <input type="text" #searchInput [(ngModel)]="dropdown.inputText" placeholder="Search by name..." class="form-control mb-2 mt-1">
                            <div class="dd-item" *ngFor="let opt of dropdown.data | filterBy: ['name']: dropdown.inputText" (click)="selectItem(opt, dropdown)">
                                <span [innerHTML]="opt.name | highlight: dropdown.inputText"></span>
                            </div>
                            <div *ngIf="(dropdown.data | filterBy: ['name']: dropdown.inputText).length == 0" class="text-muted text-center">
                                No results for this search
                            </div>
                        </div>
                </div>
            </div>                
            <div *ngIf="i == 3">
                <p style="border-bottom: 0.5px solid gray;">Optional Information</p>
            </div>
        </ng-container>
            </div>
            </div>
            
        <button (click)="addRoles()" class="btn btn-success ml-3" [disabled]="disableButton">
            Save
        </button>
    </app-slider-panel>

    <app-popup-modal *ngIf="modalInfo" [modalInfo]="modalInfo" (action)="closeModal($event)"></app-popup-modal>
