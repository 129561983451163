import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap/typeahead/typeahead';
import { Store } from '@ngrx/store';
import { Observable, OperatorFunction } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

import { IDynamicFormBlock } from 'src/app/core/models/dynamic-form-block.vm';
import { selectAdminUsers } from 'src/app/store/admin-userlist.selectors';

@Component({
  selector: 'app-dynamic-form-search-input',
  templateUrl: './dynamic-form-search-input.component.html',
  styleUrls: ['./dynamic-form-search-input.component.scss']
})
export class DynamicFormSearchInputComponent implements OnInit {

  @Input() public form: FormGroup;
  @Input() public block: IDynamicFormBlock;
  @Input() public userDetails: {email: string, name: string, userId: number};
  @Output() public optionSelected = new EventEmitter<any>();

  public options: { id: number, name: string }[];
  public selectedOption: { id: number, name: string };

  public currentValue: number;

  search: OperatorFunction<string, readonly { id, name }[]> = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(50),
    distinctUntilChanged(),
    map(term => term.length < 2 ? [] : this.options.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
  )

  formatter = (option: {name: string}) => option.name;

  constructor(
    private store: Store<{ darkMode: boolean }>
  ) { }

  ngOnInit(): void {
    this.currentValue = this.form.get(this.block.dataKey).value;
    this.setOptions(this.block.optionsType, this.currentValue);
  }

  public selectedItem(event: NgbTypeaheadSelectItemEvent): void {
    this.form.get(this.block.dataKey).setValue(event.item.id)
    this.optionSelected.emit(true)
  }

  private setOptions(type: string, currentValue: number): void {
    if (type == 'adminUsers') {
      this.getAdminUsers(currentValue);
    }
  }

  private getAdminUsers(currentValue: number): void {
    this.store.select(selectAdminUsers).subscribe((data) => {
      this.options = data.map(user => { 
        return { id: user.userId, name: user.userFullName } 
      });
      this.selectedOption = this.options.find(opt => opt.id == currentValue);
    });
  }

}
