import { IDynamicFormBlock } from "src/app/core/models/dynamic-form-block.vm";
import { IDynamicTableColumn } from "src/app/core/models/dynamic-table-column.vm";

export const DataUploadTableColumns: IDynamicTableColumn[] = [
    {
        header: 'Status',
        dataKey: 'status_name',
        percWidth: 8,
        sortable: true,
        groupable: true,
        searchable: true,
        badge: 'uploads',
        insight: {
            title: "Possible States",
            noHeader: true,
            body: [{
              column1: 'Submitted',
              column2: 'Submission from portal',
              badge: 'rgba(108, 117, 125, 0.2)'
            },
            {
              column1: 'Updated',
              column2: 'Submission has been assigned matching details',
              badge: '#fef3c7'
            },
            {
              column1: 'Rejected',
              column2: 'NHSBN user has rejected submission',
              badge: '#d65533'
            },
            {
              column1: 'Intray',
              column2: 'Submission awaiting MDI input',
              badge: '#47754b'
            },
            {
              column1: 'Imported',
              column2: 'Submission within database',
              badge: '#17a2b8'
            },
            {
              column1: 'Error',
              column2: 'Error with MDI input process',
              badge: '#fcc000'
            }]
          }
    },
    {
        header: 'File ID',
        dataKey: 'file_tracking_id',
        percWidth: 5,
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Submission ID',
        dataKey: 'submission_id',
        percWidth: 10,
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Organisation',
        dataKey: 'organisation_name',
        percWidth: 25,
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Submitter Name',
        dataKey: 'submitter_name',
        percWidth: 10,
        sortable: true,
        groupable: true,
        searchable: true
    },
    // {
    //     header: 'Submitter Email',
    //     dataKey: 'submitter_email',
    //     percWidth: 10,
    //     sortable: true,
    //     groupable: true,
    //     searchable: true
    // },
    // {
    //     header: 'File Name',
    //     dataKey: 'filename',
    //     percWidth: 10,
    //     sortable: true,
    //     groupable: false,
    //     searchable: true
    // },
    {
        header: 'Comment',
        dataKey: 'tracking_note',
        percWidth: 25,
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Initial Comment',
        dataKey: 'initial_comment',
        percWidth: 25,
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Last Updated',
        dataKey: 'submission_date_time',
        percWidth: 13,
        sortable: true,
        groupable: true,
        searchable: true,
        dateFormat: 'MMM D, h:mm A'
    },
    {
        header: 'Upload Time',
        dataKey: 'upload_time',
        percWidth: 13,
        sortable: true,
        groupable: true,
        searchable: true,
        dateFormat: 'MMM D, h:mm A'
    },
    {
        header: 'Options',
        percWidth: 8,
        dataKey: '',
        sortable: false,
        groupable: false,
        searchable: false,
        exportable: false,
        options: ['clickable'],
        button: 'options',
        buttonType: 'dataUploads',
        actions: [
          {
            icon: 'fas fa-lg fa-check-square mr-2',
            color: '#3c763d',
            action: 'accept',
            description: 'Accept submission',
          },
          {
            icon: 'fas fa-lg fa-minus-square mr-2',
            color: '#a94442',
            action: 'reject',
            description: 'Reject submission',
          },
          {
            icon: 'fas fa-lg fa-pen-square mr-2',
            color: '#005eb8',
            action: 'edit',
            description: 'Edit submission',
          },
          {
            icon: 'fas fa-undo mr-2',
            color: '#42a6a9',
            action: 'undo',
            description: 'Reset from Intray/Rejected/Error/Imported',
          }
        ],
    },
];

export const CreateSubmissionForm: IDynamicFormBlock[] = [
    {
        blockType: 'divider'
    },
    {
        label: 'Submission Name',
        dataKey: 'SubmissionName',
        inputType: 'string',
        blockType: 'input',
        colWidth: 6,
    },
    {
        label: 'Organisation',
        dataKey: 'organisation_id',
        blockType: 'searchDropdown',
        colWidth: 6,
        optionsType: 'organisations'
    },
    {
        label: 'Save',
        dataKeys: ['SubmissionName'],
        blockType: 'saveButton'
    }
];

export const UpdateProjectIDForm: IDynamicFormBlock[] = [
    {
        blockType: 'divider'
    },
    {
        label: 'Projects',
        dataKey: 'project_id',
        blockType: 'searchDropdown',
        colWidth: 7,
        optionsType: 'projects'
    },
    {
        label: 'Update Project ID',
        dataKey: 'project_id',
        blockType: 'dynamicButton'
    }
];

export const CreateOrganisationForm: IDynamicFormBlock[] = [
    {
        blockType: 'divider'
    },
    {
        label: 'Organisation Name',
        helpText: "Required",
        dataKey: 'organisationName',
        inputType: 'string',
        blockType: 'input',
        colWidth: 12,
    },
    {
        label: 'Area Code',
        helpText: "Required",
        dataKey: 'postalAddressPc',
        inputType: 'string',
        blockType: 'input',
        colWidth: 12,
    },
    {
        label: 'Address 1',
        helpText: "Required",
        dataKey: 'postalAddress1',
        inputType: 'string',
        blockType: 'input',
        colWidth: 12,
    },
    {
        label: 'Address 2',
        helpText: "Required",
        dataKey: 'postalAddress2',
        inputType: 'string',
        blockType: 'input',
        colWidth: 12,
    },
    {
        label: 'Address 3',
        helpText: "Required",
        dataKey: 'postalAddress3',
        inputType: 'string',
        blockType: 'input',
        colWidth: 12,
    },
    {
        label: 'Address 4',
        helpText: "Required",
        dataKey: 'postalAddress4',
        inputType: 'string',
        blockType: 'input',
        colWidth: 12,
    },
    {
        label: 'Organisation Url',
        helpText: "Required",
        dataKey: 'organisationUrl',
        inputType: 'string',
        blockType: 'input',
        colWidth: 12,
    },
    {
        label: 'Organisation Type',
        helpText: "Required",
        dataKey: 'organisationTypeId',
        blockType: 'searchDropdown',
        optionsType: 'organisationTypes',
        colWidth: 12,
    },
    {
        label: 'Organisation Type Other',
        helpText: "Required",
        dataKey: 'organisationTypeOther',
        inputType: 'string',
        blockType: 'input',
        colWidth: 12,
    },
    {
        label: 'VAT',
        helpText: "Required",
        dataKey: 'vatCharge',
        inputType: 'string',
        blockType: 'input',
        colWidth: 12,
    },
    {
        label: 'PO',
        helpText: "Required",
        dataKey: 'poRequired',
        inputType: 'string',
        blockType: 'input',
        colWidth: 12,
    },
    {
        label: 'Add Organisation',
        requiredKeys: [
            'organisationName', 
            'postalAddress1', 
            'postalAddress2', 
            'postalAddress3', 
            'postalAddress4', 
            'organisationUrl', 
            'organisationTypeId', 
            'organisationTypeOther', 
            'vatCharge', 
            'poRequired', 
            'postalAddressPc'],
        dataKeys: ['organisationName', 'postalAddress1', 'postalAddressPc'],
        blockType: 'changesButton',
    }
];

export const DataUploadForm: IDynamicFormBlock[] = [
    {
        blockType: 'divider'
    },
    {
        label: 'Submission Details',
        blockType: 'header',
    },
    {
        label: 'Organisation',
        dataKey: 'organisation_id',
        helpText: "Auto save applied when an organisation is selected",
        blockType: 'searchDropdown',
        colWidth: 7,
        optionsType: 'organisations',
        button: [{
            text: 'Create a new organisation',
            style: 'fas fa-plus-square c-pointer',
            action: 'createOrganisation',
        }]
    },
    {
        label: 'Submission',
        dataKey: 'submission_id',
        helpText: "**Do not change the submission after the file has been Imported**",
        blockType: 'select',
        submissions: true,
        colWidth: 5,
        button: [{
            text: 'Create a new submission',
            style: 'fas fa-plus-square c-pointer mr-2',
            action: 'createSubmission',
        },{
            text: 'Rename a submission',
            style: 'fas fa-pen-square c-pointer',
            action: 'renameSubmission',
        },
    ],
    },
    {
        label: 'Service',
        dataKey: 'service_item_id',
        blockType: 'searchDropdown',
        colWidth: 7,
        optionsType: 'services'
    },
    {
        label: 'Comment',
        helpText: "Please update the comment with any changes made",
        dataKey: 'tracking_note',
        blockType: 'input',
        colWidth: 12
    },
    {
        label: 'Organisation ID',
        dataKey: 'organisation_id',
        blockType: 'input',
        colWidth: 3,
        hidden: true
    },
    {
        label: 'File Tracking ID',
        dataKey: 'file_tracking_id',
        blockType: 'input',
        colWidth: 3,
        hidden: true
    },
    {
        blockType: 'saveButton',
        dataKeys: ['tracking_note'],
        label: 'Save'
    }
];

export const KeyInformationFromFileTableColumns: IDynamicTableColumn[] = [
    {
        header: 'Name',
        dataKey: 'columnA',
        percWidth: 50,
        sortable: false,
        groupable: false,
        searchable: false
    },
    {
        header: 'Value',
        dataKey: 'columnB',
        percWidth: 50,
        sortable: false,
        groupable: false,
        searchable: false,
    },
];

export const FileHistoryTableColumns: IDynamicTableColumn[] = [
    {
        header: 'Name',
        dataKey: 'full_name',
        percWidth: 25,
        sortable: false,
        groupable: false,
        searchable: false
    },
    {
        header: 'Status',
        dataKey: 'status_name',
        percWidth: 25,
        sortable: false,
        groupable: false,
        searchable: false,
    },
    {
        header: 'Date',
        dataKey: 'submission_date_time',
        percWidth: 25,
        sortable: false,
        groupable: false,
        searchable: false
    },
    {
        header: 'Comment',
        dataKey: 'tracking_note',
        percWidth: 25,
        sortable: false,
        groupable: false,
        searchable: false,
    }
];
