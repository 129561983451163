import { Component, ElementRef, Input, OnChanges, ViewChild } from '@angular/core';
import { usersDetailNavItems} from '../users-layout';
import { INavItem } from 'src/app/core/models/nav-item.vm';
import { LoaderService } from 'src/app/core/services/loader.service';
import { ActivatedRoute, Router } from '@angular/router';
import { IDynamicFormBlock } from 'src/app/core/models/dynamic-form-block.vm';
import { User } from 'src/app/core/classes/user';
import { IDynamicTableColumn } from 'src/app/core/models/dynamic-table-column.vm';
import { UserRolesDetailsForm, UsersRolesTableColumns } from './users-roles-layout';
import { IUserRole } from 'src/app/core/models/user-role.vm';
import { Layout } from 'src/app/core/classes/layout';
import { OrganisationService } from 'src/app/core/services/organisation.service';
import { UsersService } from 'src/app/core/services/users.service';
import { ProjectService } from 'src/app/core/services/project.service';
import { RolesService } from 'src/app/core/services/roles.service';
import { IAlert } from 'src/app/core/models/alert';


@Component({
  selector: 'app-users-roles',
  templateUrl: './users-roles.component.html',
  styleUrls: ['./users-roles.component.scss'],
})
export class UsersRolesComponent implements OnChanges {
  @Input() public currentUser: User;

  public currentScope: string = null;
  public loadingError: string = null;

  public UsersRolesTableColumns: Array<IDynamicTableColumn> = UsersRolesTableColumns;
  public usersDetailNavItems: Array<INavItem> = usersDetailNavItems;
  public currentUserRole: IUserRole = null;
  public UserRolesDetailsForm: Array<IDynamicFormBlock> = UserRolesDetailsForm;
  public roleLayout: Layout;
  public openAddRole: boolean = false;
  public addRoleLayout: Layout;
  public showDropdown: boolean = false;
  public searchInputValue: string;
  public dropdowns: any;
  public modalInfo: Object = null; 
  public dynamicButtons = [
    { name: 'Add User Role', class: 'btn btn-success' },
    { name: 'Disable All Roles', class: 'btn btn-danger' } 
  ];


  @ViewChild('searchInput') searchInput: ElementRef;

  public data: { id: number; name: string; }[];
  public yearList: any[];
  public disableButton: boolean = true;
  public alert: IAlert;
  public componentName:string = "users-roles";
  public editRoles: boolean = false;

  constructor(
    public loaderService: LoaderService,
    private route: ActivatedRoute,
    private router: Router,
    private organisations: OrganisationService,
    private usersService: UsersService,
    private projectService: ProjectService,
    private RolesService: RolesService,

  ) {
    this.dropdowns = [
      {
        name: 'Year',
        id: 'yearIdDrop',
        type: 'select',
        data: [],
        count: 0,
        active: false,
        selectedOption: null,
        inputText: '',
        default: {}
    },
      {
          name: 'organisation',
          id: 'organisationIdDrop',
          data: [],
          count: 1,
          active: false,
          selectedOption: null,
          inputText: ''
      },
      {
          name: 'product',
          id: 'productIdDrop',
          data: [],
          count: 2,
          active: false,
          selectedOption: null,
          inputText: ''
      },
      {
        name: 'roles',
        id: 'roleIdDrop',
        data: [],
        count: 3,
        active: false,
        selectedOption: null,
        inputText: ''
    },
    {
      name: 'project',
      id: 'projectIdDrop',
      data: [],
      count: 4,
      active: false,
      selectedOption: null,
      inputText: ''
  },
  {
    name: 'submission',
    id: 'submissionIdDrop',
    data: [],
    count: 5,
    active: false,
    selectedOption: null,
    inputText: ''
},
{
  name: 'services',
  id: 'serviceIdDrop',
  data: [],
  count: 6,
  active: false,
  selectedOption: null,
  inputText: ''
},
{
  name: 'Enabled',
  id: 'enabledIdDrop',
  type: 'select',
  data: [{name: 'Yes', id: 'Y'}, {name: 'No', id: 'N'}],
  active: false,
  selectedOption: null,
  inputText: '',
  default: {}
},
  ]
  this.getDefaultYearList()

  }

  ngOnChanges() {
    this.route.queryParams.subscribe(params => {
      if (params.role && params.edit == 'Y') {
        this.onUserRoleClick(params.role);
        this.editDetails()
      } else if(params.role){
        this.onUserRoleClick(params.role);
      }
    });
    this.updateDynamicButtons()
  }

  private getUserDetails(userId): void {
    this.usersService.getUsersDetails(userId).subscribe(
      (success) => {
        this.currentUser = new User(success.data);        
      },
      (error) => {
        console.log('Error: ', error);
        this.loadingError = error.error.error.message;
      }
    );    
  }

  public onDynamicButtonClick(event: any) {
    if (event.name == 'Add User Role') {
      this.openAddRoleSlider()      
    } else {
      this.modalInfo = {
        item: this.currentUser.fullName, 
        subText: "Are you sure you want to disable all the roles for the user: ", 
        title: "User",
        action: "deactivation",
        warning: "All the roles assigned to this user will be permanently deleted.",
        request: "Disable all roles"
      };
    }   
  }

  public getUniqueOrganisationIds(data) {
    const organisationIds = [];    
    data.forEach(item => {
        if (!organisationIds.includes(item.organisationId)) {
            organisationIds.push(item.organisationId);
        }
    });    
    return organisationIds;
  }

  public disableAllUserRoles() {
    const body = { organisationList: this.getUniqueOrganisationIds(this.currentUser.roles) };
    this.usersService.disableAllRoles(body, this.currentUser.userId).subscribe(
      success => {
        this.getUserDetails(this.currentUser.userId)
      },
      error => {
        console.log('Error: ', error);
        this.loadingError = error.error.error.message;
      }
    )
  }
  

  closeModal(value: boolean) {
    if(value == true) {
      this.disableAllUserRoles()
      this.updateDynamicButtons()
    }
    this.modalInfo = null;
  }

  public openAddRoleSlider() {
    this.editRoles = false
    this.openAddRole = true
    this.addRoleLayout = new Layout(
      'Add Roles',
      null,
      null
    )
    this.dropdownDisabler()
  }

  public updateDynamicButtons(): void {
    const currentUserRolesList = this.currentUser.roles;
    // Check if the roles list is empty
    if (currentUserRolesList.length === 0) {
        this.dynamicButtons = [{ name: 'Add User Role', class: 'btn btn-success' }]; // No roles, no Deactivate User button
    }    
  }

  public toggleDropdown(dropdown): void {
    let foundDropdown = this.dropdowns.find(item => item.id == dropdown)
 
    if (foundDropdown.active == false) {
      foundDropdown.active = true

      // Await change of boolean before focus
      setTimeout(()=>{ this.searchInput.nativeElement.focus() }, 0);
    } else {
      foundDropdown.active = false
      this.searchInputValue = '';
    }
  }

  public async selectItem(option, dropdown?){
    let foundDropdown = this.dropdowns.find(item => item.id == dropdown.id)
    foundDropdown.selectedOption = option

    if(foundDropdown.id == 'yearIdDrop'){
      this.resetDropdowns(foundDropdown)
      this.getOrgList(foundDropdown.selectedOption.id)
    }

    if(foundDropdown.id == 'organisationIdDrop'){
      this.resetDropdowns(foundDropdown)
       this.getProductList(foundDropdown.selectedOption.id)
    }

    if(foundDropdown.id === 'productIdDrop'){
      this.resetDropdowns(foundDropdown)
      this.getRoleList()
    }

    if(foundDropdown.id === 'roleIdDrop'){
      this.resetDropdowns(foundDropdown)
      let yearSelected = this.dropdowns.find(dropdown => dropdown.id === 'yearIdDrop')
      this.getProjectList(yearSelected.selectedOption.id)
    }

    if(foundDropdown.id == 'projectIdDrop'){
      this.resetDropdowns(foundDropdown)
      let organisationSelected = this.dropdowns.find(dropdown => dropdown.id === 'organisationIdDrop')
      let yearSelected = this.dropdowns.find(dropdown => dropdown.id === 'yearIdDrop') 

      this.getSubmissionList(yearSelected.selectedOption.id, foundDropdown.selectedOption.id, organisationSelected.selectedOption.id)
    }

    if(foundDropdown.id == 'submissionIdDrop'){
      this.resetDropdowns(foundDropdown)
      let projectSelected = this.dropdowns.find(dropdown => dropdown.id === 'projectIdDrop')
      let yearSelected = this.dropdowns.find(dropdown => dropdown.id === 'yearIdDrop')
      this.getServiceList(foundDropdown.selectedOption.id, projectSelected.selectedOption.id, yearSelected.selectedOption.id)
    }
    this.dropdowns.forEach(a => a.active = false)
  }

  public dropdownDisabler(){
    setTimeout(() => {
      this.dropdowns.forEach(dropdown => {
        if(dropdown.data.length === 0){
          document.getElementById(dropdown.id).classList.add("disabled-div");
        }else {
            document.getElementById(dropdown.id).classList.remove("disabled-div");
        }
      })
    }, 100);
    let roleSelected = this.dropdowns.find(dropdown => dropdown.id === 'roleIdDrop')
    if (roleSelected.selectedOption !== null){
      this.disableButton = false
    }else {
      this.disableButton = true
    } 
  }

  public resetDropdowns(dropdown){
      this.dropdowns.forEach(item => {
        if(item.count > dropdown.count && item.id) {
            item.data = [];
            item.selectedOption = null
        }
    })  
}

  public getDefaultYearList() {
    this.yearList = [];
    for (var i = 2013; i <= new Date().getFullYear() + 1; i++) {
      this.yearList.push({name: i, id:i});
    }
    let yearDropdown = this.dropdowns.find(drop => drop.id == 'yearIdDrop')

    yearDropdown.data = this.yearList.reverse();
    yearDropdown.default = yearDropdown.data[1]
    yearDropdown.selectedOption = yearDropdown.default
    this.getOrgList(yearDropdown.default.id)
  }


  public getOrgList(year){
    this.organisations.getOrganisations(year).subscribe(success => {
      let orgDropdown = this.dropdowns.find(drop => drop.id == 'organisationIdDrop')
      orgDropdown.data = success.data.organisationList.map(item => { return { id: item.organisationId, name: item.organisationName } });
      
      if(this.openAddRole !== false){
        this.dropdownDisabler()
      }
 
    })
  }

  public addRoles(){
    let projectSelected = this.dropdowns.find(dropdown => dropdown.id === 'projectIdDrop')
    let yearSelected = this.dropdowns.find(dropdown => dropdown.id === 'yearIdDrop')
    let organisationSelected = this.dropdowns.find(dropdown => dropdown.id === 'organisationIdDrop')
    let submissionSelected = this.dropdowns.find(dropdown => dropdown.id === 'submissionIdDrop')
    let serviceSelected = this.dropdowns.find(dropdown => dropdown.id === 'serviceIdDrop')
    let roleSelected = this.dropdowns.find(dropdown => dropdown.id === 'roleIdDrop')
    let productSelected = this.dropdowns.find(dropdown => dropdown.id === 'productIdDrop')
    let enabledSelected = this.dropdowns.find(dropdown => dropdown.id === 'enabledIdDrop')
 
    let addRoleBody = {
      organisationId: organisationSelected.selectedOption?.id,
      productId: productSelected.selectedOption?.id,
      roleType: roleSelected.selectedOption?.id,
      roleYear: yearSelected.selectedOption?.id,
      projectId: projectSelected.selectedOption?.id,
      submissionId: submissionSelected.selectedOption?.id,
      serviceItemId: serviceSelected.selectedOption?.id,
      isEnabled: enabledSelected.selectedOption?.id
    }



    if(this.editRoles == false){
      this.RolesService.addNewRole(this.currentUser.userId, addRoleBody).subscribe(success => {
        location.reload();
      }, error => {
        console.log('Error: ', error);
        this.alert = {
          message: `<strong>Role Addition Failed</strong>`,
          alertClass: 'danger',
          fadeOut: true,
    };
      }
    )
    }

  if(this.editRoles == true){
    let editRoleBody =
    {
      roleId: this.currentUserRole.roleId,
      newUserId: this.currentUser.userId,
      newProductId: productSelected.selectedOption?.id,
      newRoleType: roleSelected.selectedOption?.id,
      newOrganisationId: organisationSelected.selectedOption?.id,
      newRoleYear: yearSelected.selectedOption?.id,
      newProjectId: projectSelected.selectedOption?.id,
      newServiceItemId: serviceSelected.selectedOption?.id,
      newSubmissionId: submissionSelected.selectedOption?.id,
      newIsEnabled: enabledSelected.selectedOption?.id
  }
    this.RolesService.editRole(editRoleBody).subscribe(success => {
      this.alert = {
        message: `<strong>Role Editted</strong>`,
        alertClass: 'success',
        fadeOut: true,
  };
    setTimeout(() => {
      location.reload()
    }, 1000);
    }, error => {
      console.log('Error: ', error);
      this.alert = {
        message: `<strong>Role Edit Failed</strong>`,
        alertClass: 'danger',
        fadeOut: true,
  };
    }
  )
  }
  }

  public async getProductList(orgId){
      this.usersService.getProducts(orgId).subscribe(success => {
        let productDropdown = this.dropdowns.find(drop => drop.id == 'productIdDrop')

        productDropdown.data = success.data.map(item => { return { id: item.productId, name: item.productName } })
        this.dropdownDisabler()

      }, error => {

      })

  }

  public async getProjectList(year){
      this.projectService.getProjects(year).subscribe(success => {
        let projectDropdown = this.dropdowns.find(drop => drop.id == 'projectIdDrop')

        projectDropdown.data = success.data.projectList.map(item => { return { id: item.projectId, name: item.projectName } })
        this.dropdownDisabler()

      })
  }

  public async getRoleList(){
      this.usersService.getRoles().subscribe(success => {
        let roleDropdown = this.dropdowns.find(drop => drop.id == 'roleIdDrop')

        roleDropdown.data = success.roles.map(item => { return { id: item.role_type, name: item.role_name } })
        this.dropdownDisabler()
      })
  }

  public async getSubmissionList(year, project, organisation){
      this.projectService.getSubmissions(year, project, organisation).subscribe(success => {
        let submissionDropdown = this.dropdowns.find(drop => drop.id == 'submissionIdDrop')
        submissionDropdown.data = success.submissions.map(item => { return { id: item.submission_id, name: item.submission_name } })
        this.dropdownDisabler()

      })
  }

  public async getServiceList(submission, project, year){
      this.projectService.getSubmissionPortalServiceItems(submission, project, year).subscribe(success => {
        let serviceDropdown = this.dropdowns.find(drop => drop.id == 'serviceIdDrop')
        serviceDropdown.data = success.services.map(item => { return { id: item.service_item_id, name: item.service_item_name } })
        this.dropdownDisabler()

      })
  }

  public editDetails(){
    this.router.navigate([], { queryParams: { edit: 'Y' }, queryParamsHandling: 'merge' });

    this.openAddRoleSlider()
    this.addRoleLayout = new Layout(
      'Edit Role',
      null,
      null
    )
    this.editRoles = true
    let organisationSelected = this.dropdowns.find(dropdown => dropdown.id === 'organisationIdDrop')
    let productSelected = this.dropdowns.find(dropdown => dropdown.id === 'productIdDrop')
    let roleSelected = this.dropdowns.find(dropdown => dropdown.id === 'roleIdDrop')
    let projectSelected = this.dropdowns.find(dropdown => dropdown.id === 'projectIdDrop')
    let submissionSelected = this.dropdowns.find(dropdown => dropdown.id === 'submissionIdDrop')
    let serviceSelected = this.dropdowns.find(dropdown => dropdown.id === 'serviceIdDrop')
    let enabledSelected = this.dropdowns.find(dropdown => dropdown.id === 'enabledIdDrop')
    let yearSelected = this.dropdowns.find(dropdown => dropdown.id === 'yearIdDrop')


    organisationSelected.selectedOption = {name: this.currentUserRole.organisationName, id: this.currentUserRole.organisationId}
    productSelected.selectedOption = {name: this.currentUserRole.productName, id: this.currentUserRole.productId}
    roleSelected.selectedOption = {name: this.currentUserRole.roleName, id: this.currentUserRole.roleType}
    projectSelected.selectedOption = {name: this.currentUserRole.projectName, id: this.currentUserRole.projectId}
    submissionSelected.selectedOption = {name: this.currentUserRole.submissionName, id: this.currentUserRole.submissionId}
    serviceSelected.selectedOption = {name: this.currentUserRole.serviceItemName, id: this.currentUserRole.serviceItemId}
    


    if(this.currentUserRole.roleYear !== null){
      yearSelected.default = this.yearList.find(a => a.id == this.currentUserRole.roleYear)
      yearSelected.selectedOption = this.yearList.find(a => a.id == this.currentUserRole.roleYear)

    } else {
      yearSelected.default = this.yearList[1]
    }

    if(this.currentUserRole.isEnabled !== null){
      enabledSelected.default = enabledSelected.data.find(a => a.id == this.currentUserRole.isEnabled)
      enabledSelected.selectedOption = enabledSelected.data.find(a => a.id == this.currentUserRole.isEnabled)
    }

    this.getOrgList(this.currentUserRole.roleYear || 2024)
    this.getProductList(this.currentUserRole.organisationId)
    this.getRoleList()
    this.getProjectList(this.currentUserRole.roleYear || 2024)
    this.getSubmissionList(this.currentUserRole.roleYear || 2024, this.currentUserRole.productId, this.currentUserRole.organisationId)
    this.getServiceList(this.currentUserRole.submissionId, this.currentUserRole.projectId, this.currentUserRole.roleYear || 2024)

  }

  public onUserRoleClick = (primaryKey: string): void => {
    this.currentUserRole = this.currentUser.roles.find(user => user.roleId == +primaryKey);
    this.roleLayout = new Layout(
      this.currentUserRole.roleName,
      [ this.currentUserRole.organisationName ],
      null
    )
    this.router.navigate([], { queryParams: { role: primaryKey }, queryParamsHandling: 'merge' });
  }

  public closeUserRoleSlider = (): void => {
    this.currentUserRole = null;
    this.router.navigate([], { queryParams: { role: null }, queryParamsHandling: 'merge' });
  }

  public closeAddUserRoleSlider = (): void => {
    this.router.navigate([], { queryParams: { edit: null }, queryParamsHandling: 'merge' });

    this.openAddRole = false
    this.dropdowns.forEach(dropdown => {
      if(dropdown.id !== 'enabledIdDrop'){
        dropdown.data = []
        dropdown.selectedOption = null
      }
    })
    this.getDefaultYearList()
  }

}
