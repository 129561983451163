import { Constants } from "src/app/core/constants/constants";
import { IDynamicFormBlock } from "src/app/core/models/dynamic-form-block.vm";
import { IDynamicTableColumn } from "src/app/core/models/dynamic-table-column.vm";

export const usersDetailBasics: IDynamicFormBlock[] = [
    {
        label: 'Details',
        blockType: 'header',
        colWidth: 12
    },
    {
        label: 'Name',
        dataKey: 'fullName',
        blockType: 'input',
        inputType: 'text',
        colWidth: 3,
    },
    {
        label: 'Email',
        dataKey: 'logonName',
        blockType: 'input',
        inputType: 'text',
        colWidth: 3
    },
    {
        label: 'Admin',
        dataKey: 'isAdmin',
        blockType: 'select',
        inputType: 'text',
        options: Constants.yesNo,
        colWidth: 3,
    },
    {
        label: 'ID',
        dataKey: 'userId',
        blockType: 'input',
        inputType: 'text',
        colWidth: 3,
        disabled: true
    },
    {
        blockType: 'divider'
    },
    {
        label: 'Job Title',
        dataKey: 'jobTitle',
        helpText: '80 character limit',
        blockType: 'input',
        inputType: 'text',
        colWidth: 3,
    },
    {
        label: 'Phone Number',
        dataKey: 'phoneNo',
        blockType: 'input',
        colWidth: 3
    },
    {
        label: 'Active Roles',
        dataKey: 'hasActiveRoles',
        blockType: 'input',
        inputType: 'text',
        colWidth: 3,
        disabled: true
    },
    {
        label: 'Recently Active',
        dataKey: 'recentlyActive',
        blockType: 'input',
        inputType: 'text',
        colWidth: 3,
        disabled: true
    },
    {
        label: 'Active',
        dataKey: 'isActive',
        blockType: 'select',
        options: Constants.yesNo,
        inputType: 'text',
        colWidth: 3,
    },
    {
        blockType: 'divider'
    },    
    {
        label: 'Mailchimp Opt-In',
        dataKey: 'mailchimpOptIn',
        blockType: 'select',
        inputType: 'text',
        options: Constants.yesNo,
        colWidth: 3,
    },  
    {
        label: 'Notification Opt-In',
        dataKey: 'notificationOptIn',
        blockType: 'input',
        inputType: 'text',
        colWidth: 3,
        disabled: true
    },
    {
        label: 'Last Login',
        dataKey: 'lastLogon',
        blockType: 'input',
        inputType: 'text',
        colWidth: 3,
        disabled: true
    },
    {
        label: 'Last Reviewed',
        dataKey: 'userDetailsLastReviewed',
        blockType: 'input',
        inputType: 'text',
        colWidth: 3,
        disabled: true
    },
    {
        label: 'Update Details',
        colWidth: 2,
        dataKeys: ['fullName', 'logonName', 'jobTitle', 'phoneNo', 'isAdmin', 'mailchimpOptIn', 'isActive'],
        blockType: 'changesButton',
        buttonRow: true,
    },
    {
        label: 'Reset Password',
        colWidth: 2,
        buttonClass: 'btn btn-primary',
        buttonAction: 'resetPassword',
        blockType: 'defaultButton',
        buttonRow: true,
    }
];

export const ProfileRolesTableColumns: IDynamicTableColumn[] = [
    {
        header: 'Organisation',
        dataKey: 'organisationName',
        percWidth: 30,
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Product',
        dataKey: 'productName',
        percWidth: 30,
        sortable: true,
        groupable: false,
        searchable: true
    },
    {
        header: 'Role Name',
        dataKey: 'roleName',
        percWidth: 20,
        sortable: true,
        groupable: true,
        searchable: false
    },
    {
        header: 'Year',
        dataKey: 'roleYear',
        percWidth: 10,
        sortable: true,
        groupable: true,
        searchable: false
    },
    {
        header: 'Service Item',
        dataKey: 'serviceItemName',
        percWidth: 10,
        sortable: true,
        groupable: true,
        searchable: false
    }
];