<!-- Loading and error -->
<app-loading-status *ngIf="(loaderService.isLoading | async) && !currentEvent; else notLoading"></app-loading-status>
<app-alert-status *ngIf="loadingError" [loadingError]="loadingError"></app-alert-status>
<app-alert-status *ngIf="alert" [alert]="alert" [parent]="componentName" (emitAlertCancelled)="alert = $event"></app-alert-status>

<ng-template #notLoading>

    <!-- Table -->
    <app-dynamic-table 
        *ngIf="currentEvent.registrations" 
        [data]="currentEvent.registrations" 
        [columns]="eventRegistrationTableColumns" 
        [currentYear]="currentYear"
        [tableTitle]="'registrationList'"
        [primaryKey]="tablePrimaryKey"
        [onRowClick]="onRegistrationClick"
        [userTableUpdate]="registrationTableUpdate"
        [dynamicButtons]="dynamicButtons"
        (buttonClicked)="openAddDelegateSlider()"
        [changeUsersList]="changeEventsList"
        >
    </app-dynamic-table>

    <!-- Add Delegate -->
    <app-slider-panel *ngIf="addDelegateLayout" [onClose]="closeAddDelegateSlider" [pageWidth]="50">
        <!-- Heading -->
        <app-area-heading [heading]="addDelegateLayout.heading" [subheading]="addDelegateLayout.subheading"></app-area-heading>
        <!-- Form -->
        <app-dynamic-form [data]="addDelegateForm" [blocks]="addDelegateFormBlock" (dataUpdate)="dataUpdate($event)"></app-dynamic-form>
      </app-slider-panel>

    <!-- Selected Registration -->
    <app-slider-panel *ngIf="selectedRegistration" [onClose]="closeRegistrationSlider">
        <!-- Heading -->
        <app-area-heading [heading]="layout.heading" [subheading]="layout.subheading" [backRoute]="layout.backRoute"></app-area-heading>
        <!-- Error message -->
        <div *ngIf="acceptOrRejectError" class="alert alert-danger mt-5 d-flex align-items-center">
            <i class="fas fa-exclamation-triangle fa-lg mr-3"></i>
            <p class="mb-0">{{ acceptOrRejectError }}</p>
         </div>
        <!-- Form -->
        <app-dynamic-form [data]="selectedRegistration" [blocks]="eventRegistrationDetailsForm" (dataUpdate)="dataUpdate($event)" [parentSection]="currentSection" (buttonAction)="buttonAction($event)" [acceptAndAmendButtonText]="acceptAndAmendButtonText"></app-dynamic-form>   
        <!-- Create Organisation slider -->
        <app-slider-panel *ngIf="createOrgLayout" [layer]="1" [onClose]="closeOrgSlider">
            <app-area-heading [heading]="createOrgLayout.heading" [subheading]="createOrgLayout.subheading"></app-area-heading>
            <app-dynamic-form [data]="organisationDetails" [blocks]="createOrganisationForm" (dataUpdate)="dataUpdate($event)" [parentSection]="currentSection"></app-dynamic-form>
        </app-slider-panel>
        <!-- Reject modal-->
        <app-popup-modal *ngIf="modalInfo" [modalInfo]="modalInfo" (action)="closeModal($event)" (commentText)="commentText($event)"></app-popup-modal>     
    </app-slider-panel>

</ng-template>