import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { IStoredProcedure } from '../models/stored-procedure.vm';
import { AuthService } from './auth.service';
import { ISubmissionDetails } from '../models/submissionDetails';
import { SubmissionDetailsEdit } from '../models/submissionData';

@Injectable()
export class ProjectService {
  private nhsApiUrl: string = environment.nhsApiUrl;
  private icsApiUrl: string = environment.icsApiUrl;

  constructor(private http: HttpClient, public auth: AuthService) {}

  // NHS
  public getProjects(year: number): Observable<any> {
    return this.http.get(this.nhsApiUrl + 'projects/list', {
      headers: new HttpHeaders().set('Authorization', this.auth.token()),
      params: new HttpParams().set('year', year.toString()),
    });
  }

  public getProject(projectId: number, year: number): Observable<any> {
    return this.http.get(
      this.nhsApiUrl + 'projects/list/' + projectId.toString(),
      {
        headers: new HttpHeaders().set('Authorization', this.auth.token()),
        params: new HttpParams().set('year', year.toString()),
      }
    );
  }

  public getProjectPeerGroups(projectId: number): Observable<any> {
    return this.http.get(
      this.nhsApiUrl + 'projects/' + projectId.toString() + '/peerGroups',
      {
        headers: new HttpHeaders().set('Authorization', this.auth.token()),
      }
    );
  }

  public getProjectServiceItems(projectId: number): Observable<any> {
    return this.http.get(
      this.nhsApiUrl + 'projects/' + projectId.toString() + '/serviceItems',
      {
        headers: new HttpHeaders().set('Authorization', this.auth.token()),
      }
    );
  }

  public getSubmissionPortalServiceItems(submissionId: number, projectId: number, submissionYear: number): Observable<any> {
    return this.http.get(
      this.nhsApiUrl + '/submissionPortal/getServiceItem',
      {
        headers: new HttpHeaders().set('Authorization', this.auth.token()),
        params: new HttpParams()
        .set('submissionId', submissionId.toString())
        .set('projectId', projectId.toString())
        .set('submissionYear', submissionYear.toString())
      }
    );
  }

  public submitProjectRoles(projectId: number, projectRoles: any): Observable<any> {   
    return this.http.put(
      this.nhsApiUrl + 'projects/' + projectId.toString() + '/projectRoles', projectRoles,  
      {
        headers: new HttpHeaders().set('Authorization', this.auth.token())
        .set("Content-Type", "application/json"),
      }
    );
  }

  public removeProjectRoles(projectRoleId: number): Observable<any> {
    return this.http.delete(
      this.nhsApiUrl + 'projects/projectRoles/' + projectRoleId.toString(), {
        headers: new HttpHeaders().set('Authorization', this.auth.token()),
      }
      );
    }

  public submitProjectServiceItems(projectId: number, projectYear:number, projectServiceItems): Observable<any> {
    return this.http.put(this.nhsApiUrl + 'projects/' + projectId.toString() + '/projectServices' ,  projectServiceItems , {
      headers: new HttpHeaders().set('Authorization', this.auth.token()),
      params: new HttpParams().set('year', projectYear.toString())
      }
    );
  }   

  public submitProjectDates(projectId: number, projectDates): Observable<any> {
    return this.http.put(this.nhsApiUrl + 'projects/' + projectId.toString() + '/projectDates' ,  projectDates , {
      headers: new HttpHeaders().set('Authorization', this.auth.token()),
      }
    );
  }

  public getProjectGuidance(projectId: number): Observable<any> {
    return this.http.get(
      this.nhsApiUrl + 'projects/' + projectId.toString() + '/projectGuidance',
      {
        headers: new HttpHeaders().set('Authorization', this.auth.token()),
      }
    );
  }

  public deleteProjectGuidance(projectId: number, guidanceId: number): Observable<any> {
    return this.http.delete(
      this.nhsApiUrl + 'projects/' + projectId.toString() + '/projectGuidance', {
        headers: new HttpHeaders().set('Authorization', this.auth.token()),
        params: new HttpParams().set('guidanceId', guidanceId.toString())
      }
    );
  }

  public putProjectGuidance(projectId: number, body): Observable<any> {
    return this.http.put(
      this.nhsApiUrl + 'projects/' + projectId.toString() + '/projectGuidance', body, {
        headers: new HttpHeaders().set('Authorization', this.auth.token()).set("Content-Type", "application/json")
      }
    );
  }


  public getProjectSurveys(projectId: number): Observable<any> {
    return this.http.get(
      this.nhsApiUrl + 'projects/' + projectId.toString() + '/externalSurveys',
      {
        headers: new HttpHeaders().set('Authorization', this.auth.token()),
      }
    );
  }

  public putProjectSurveys(body): Observable<any> {
    return this.http.put(
      this.nhsApiUrl + 'projects/'  + 'externalSurveys', body, {
        headers: new HttpHeaders().set('Authorization', this.auth.token()).set("Content-Type", "application/json")
      }
    );
  }

  public putProjectSurveysSettings(body): Observable<any> {
    return this.http.put(
      this.nhsApiUrl + 'projects/'  + 'surveySettings', body, {
        headers: new HttpHeaders().set('Authorization', this.auth.token()).set("Content-Type", "application/json")
      }
    );
  }

  public getProjectCaseCodes(projectId: number): Observable<any> {
    return this.http.get(
      this.nhsApiUrl + 'submissions/' + projectId.toString() + '/caseCodes',
      {
        headers: new HttpHeaders().set('Authorization', this.auth.token()),
        params: new HttpParams().set('allCaseCodes', 'true'),
      }
    );
  }

  public getProjectRegistrations(
    projectId: number,
    year: number
  ): Observable<any> {
    return this.http.get(this.nhsApiUrl + 'projectRegistration/list', {
      headers: new HttpHeaders().set('Authorization', this.auth.token()),
      params: new HttpParams()
        .set('projectId', projectId.toString())
        .set('year', year.toString()),
    });
  }

  public getProjectSubmissions(
    projectId: number,
    year: number,
    registrationId?: number
  ): Observable<any> {
    let params: HttpParams = new HttpParams();
    params = params
      .set('projectId', projectId.toString())
      .set('year', year.toString());

    if (registrationId) {
      params = params.append('registrationId', registrationId.toString());
    }
    return this.http.get(this.nhsApiUrl + 'submissions/list', {
      headers: new HttpHeaders().set('Authorization', this.auth.token()),
      params: params,
    });
  }

  public getProjectSubmissionsEditHistory(
    submissionId: number
  ): Observable<any> {
    return this.http.get(
      this.nhsApiUrl +
        'submissions/' +
        submissionId.toString() +
        '/editHistory',
      {
        headers: new HttpHeaders().set('Authorization', this.auth.token()),
      }
    );
  }

  public getPeerGroups(projectId: number): Observable<any> {
    return this.http.get(
      this.nhsApiUrl + 'projects/' + projectId.toString() + '/peerGroups',
      {
        headers: new HttpHeaders().set('Authorization', this.auth.token()),
      }
    );
  }

  public getSubmissionServices(submissionId: number): Observable<any> {
    return this.http.get(
      this.nhsApiUrl + 'submissions/' + submissionId.toString() + '/services',
      {
        headers: new HttpHeaders().set('Authorization', this.auth.token()),
      }
    );
  }

  public getProjectQuestions(projectId: number, year: number, serviceItemIdList?: number | Array<number>): Observable<any> {
    let params: HttpParams = new HttpParams();
    params = params
    .set('year', year.toString())
if (serviceItemIdList){
  if (Array.isArray(serviceItemIdList)) {
    serviceItemIdList.forEach(id => {
      params = params.append('serviceItemIdList[]', String(id))
    })
  } else {
    params = params.append('serviceItemIdList', String(serviceItemIdList));
  }
}

    return this.http.get(
      this.nhsApiUrl + 'projects/' + projectId.toString() + '/projectQuestions',
      {
        headers: new HttpHeaders().set('Authorization', this.auth.token()),
        params: params
      }
    );
  }

  public getProjectQuestionDetails(questionId: number): Observable<any> {
    return this.http.get(this.nhsApiUrl + 'projects/questionDetails', {
      headers: new HttpHeaders().set('Authorization', this.auth.token()),
      params: new HttpParams().set('questionId', questionId.toString()),
    });
  }

  public getProjectQuestionLevelId(
    projectId: number,
    year: number
  ): Observable<any> {
    return this.http.get(
      this.nhsApiUrl +
        'projects/' +
        projectId.toString() +
        '/questionGroupLevels',
      {
        headers: new HttpHeaders().set('Authorization', this.auth.token()),
        params: new HttpParams().set('year', year.toString()),
      }
    );
  }

  public getProjectResponses(
    projectId: number,
    year: number,
    submissionId: number,
    questionId: number,
    serviceItemId: number,
    questionGroupId: number,
    questionGroupLevelId: number
  ): Observable<any> {
    let params = new HttpParams().set('year', year.toString());

    if (submissionId) {
      params = params.append('submissionId', submissionId.toString());
    }

    if (questionId) {
      params = params.append('questionId', questionId.toString());
    }

    if (serviceItemId) {
      params = params.append('serviceItemId', serviceItemId.toString());
    }

    if (questionGroupId) {
      params = params.append('questionGroupId', questionGroupId.toString());
    }

    if (questionGroupLevelId) {
      params = params.append(
        'questionGroupLevelId',
        questionGroupLevelId.toString()
      );
    }

    return this.http.get(
      this.nhsApiUrl + 'projects/' + projectId.toString() + '/responses',
      {
        headers: new HttpHeaders().set('Authorization', this.auth.token()),
        params: params,
      }
    );
  }

  public deleteSubmission(submissionId: number): Observable<any> {
    return this.http.post(
      this.nhsApiUrl + 'submissions/deleteSubmission', ({}), {
        headers: new HttpHeaders().set('Authorization', this.auth.token()),
        params: new HttpParams().set('submissionId', submissionId.toString())
      }
    );
  }

  public getProjectTiers(projectId: number, year: number): Observable<any> {
    return this.http.get(this.nhsApiUrl + 'outputs/tiers', {
      headers: new HttpHeaders().set('Authorization', this.auth.token()),
      params: new HttpParams()
        .set('projectId', projectId.toString())
        .set('year', year.toString()),
    });
  }

  public getReportDetails(reportId: number, tierId: number): Observable<any> {
    return this.http.get(
      this.nhsApiUrl + 'outputs/' + reportId.toString() + '/reportDetails',
      {
        headers: new HttpHeaders().set('Authorization', this.auth.token()),
        params: new HttpParams().set('tierId', tierId.toString()),
      }
    );
  }

  public getAllReports(projectId: number, year?: number): Observable<any> {
    const params = year ? new HttpParams().set('year', year.toString()) : null
    return this.http.get(
      this.nhsApiUrl + 'projects/' + projectId.toString() + '/reports',
      {
        headers: new HttpHeaders().set('Authorization', this.auth.token()),
        params: params,
      }
    );
  }

  public getReportViewDetails(reportId: number, year:number): Observable<any> {
    return this.http.get(
      this.nhsApiUrl + 'outputs/' + reportId.toString() + '/reportViewsDetails',
      {
        headers: new HttpHeaders().set('Authorization', this.auth.token()),
        params: new HttpParams().set('year', year.toString()),
      }
    );
  }

  public getValidations(projectId: number, year: number): Observable<any> {
    return this.http.get(this.nhsApiUrl + 'validation/getValidations', {
      headers: new HttpHeaders().set('Authorization', this.auth.token()),
      params: new HttpParams()
        .set('projectId', projectId.toString())
        .set('year', year.toString()),
    });
  }

  public getProjectDynamicDocuments(
    projectId: number,
    organisationId,
    eventId,
    year: number
  ): Observable<any> {
    return this.http.get(this.nhsApiUrl + 'awsDocuments/dynamic', {
      headers: new HttpHeaders().set('Authorization', this.auth.token()),
      params: new HttpParams()
        .set('projectId', projectId.toString())
        .set('year', year.toString()),
    });
  }

  public getProjectStaticDocuments(
    projectId?: number,
    year?: number
  ): Observable<any> {
    return this.http.get(this.nhsApiUrl + 'awsDocuments/static', {
      headers: new HttpHeaders().set('Authorization', this.auth.token()),
      params: new HttpParams()
        .set('projectId', projectId.toString())
        .set('year', year.toString()),
    });
  }

  public triggerOutlierIdentifier(
    projectId?: number,
    year?: number
  ): Observable<any> {
    return this.http.post(
      'https://outlier-identifier.azurewebsites.net/api/oi-project-year-http',
      {},
      {
        params: new HttpParams()
          .set(
            'code',
            'v9Bz-UR-evjP7kFPGsXqIylrB1rW53aTaF7RuqNMjNu1AzFu4R9vbQ=='
          )
          .set('projectId', projectId.toString())
          .set('year', year.toString()),
      }
    );
  }

  public getDashboardMetrics(
    projectId: number,
    serviceItemId?: number,
    year?: number
  ): Observable<any> {
    let params: HttpParams = new HttpParams();

    if (serviceItemId)
      params = params.set('serviceItemId', serviceItemId.toString());

    if (year) params = params.append('year', year.toString());

    return this.http.get(
      this.nhsApiUrl + 'projects/' + projectId + '/dashboardMetrics',
      {
        headers: new HttpHeaders()
          .set('Authorization', this.auth.token())
          .set('Content-Type', 'application/json'),
        params: params,
      }
    );
  }

 
  public getStoredProcedures(): Observable<any> {
    return this.http
      .get<any>(this.nhsApiUrl + 'storedProcedures', {
        headers: new HttpHeaders().set('Authorization', this.auth.token()),
      })
      .pipe(
        map((res) => {
          const storedProcedures: IStoredProcedure[] = res.data.storedProcedures ?? [];
          return storedProcedures;
        })
      );
  }

  public getAdminReports(): Observable<any> {
    return this.http.get(this.nhsApiUrl + 'storedProcedures/adminReports', {
        headers: new HttpHeaders().set('Authorization', this.auth.token()),
      })
  }

  public getAdminReportData(id, year?): Observable<any> {
    let params: HttpParams = new HttpParams();
    if(year){
      params = params.set('year', year.toString())
    }  
    return this.http.get(this.nhsApiUrl + '/storedProcedures/' + id + '/adminReportData', {
        headers: new HttpHeaders().set('Authorization', this.auth.token()),
        params: params
      })
  }

  
  // ICS
  public getPreferences(): Observable<any> {
    return this.http.get(this.icsApiUrl + 'preferences', {
      headers: new HttpHeaders().set('Authorization', this.auth.token()),
    });
  }

  // Submission Portal

  public getDataUpload(projectId: number, year: number): Observable<any> {
    return this.http.get(
      this.nhsApiUrl + 'submissionPortal/getAllUploads', {
        headers: new HttpHeaders().set('Authorization', this.auth.token()),
        params: new HttpParams().set('projectId', projectId.toString()).set('year', year.toString())
      }
    );
  }

  public getSubmissions(year: any, projectId: any, organisationId: any): Observable<any> {
    return this.http.get(
      this.nhsApiUrl + 'submissionPortal/getSubmission', {
        headers: new HttpHeaders().set('Authorization', this.auth.token()),
        params: new HttpParams()
        .set('year', year.toString())
        .set('projectId', projectId.toString())
        .set('organisationId', organisationId?.toString())
      }
    );
  }

  public getEditLink(fileTrackingId: number): Observable<any> {
    return this.http.get(
      this.nhsApiUrl + 'submissionPortal/getEditLink', {
        headers: new HttpHeaders().set('Authorization', this.auth.token()),
        params: new HttpParams().set('fileTrackingId', fileTrackingId.toString())
      }
    );
  }

  public getKeyInformationFromFile(fileTrackingId: number): Observable<any> {
    return this.http.get(
      this.nhsApiUrl + 'submissionPortal/getKeyInformationFromFile', {
        headers: new HttpHeaders().set('Authorization', this.auth.token()),
        params: new HttpParams().set('fileTrackingId', fileTrackingId.toString())
      }
    );
  }

  public updateOrganisation(fileTrackingId: number, object: any): Observable<any> {
    return this.http.post(
      this.nhsApiUrl + 'submissionPortal/updateUploadedFileInformation/' + fileTrackingId, JSON.stringify(object), {
        headers: new HttpHeaders().set('Authorization', this.auth.token()).set('Content-Type', 'application/json')
      }
    );
  }

  public getDataUploadsProjects(): Observable<any> {
    return this.http.get(this.nhsApiUrl + 'submissionPortal/getProject', {
      headers: new HttpHeaders().set('Authorization', this.auth.token()),
    });
  }

  public rejectUploadedFile(object: any): Observable<any> {
    return this.http.post(
      this.nhsApiUrl + 'submissionPortal/rejectUploadedFile', JSON.stringify(object), {
        headers: new HttpHeaders().set('Authorization', this.auth.token()).set('Content-Type', 'application/json')
      }
    );
  }

  public moveFileToIntray(fileTrackingId: any, projectId: any): Observable<any> {
    return this.http.post(
      this.nhsApiUrl + 'submissionPortal/moveFileToIntray/' + fileTrackingId + '/' + projectId, {}, {
        headers: new HttpHeaders().set('Authorization', this.auth.token())
      }
    );
  }

  public createSubmission(object: any): Observable<any> {
    return this.http.post(
      this.nhsApiUrl + 'submissionPortal/createSubmission', JSON.stringify(object), {
        headers: new HttpHeaders().set('Authorization', this.auth.token()).set('Content-Type', 'application/json')
      }
    );
  }


  
  public updateSubmission(object:ISubmissionDetails):Observable<any>{
    return this.http.post(
      this.nhsApiUrl + 'submissions/updateSubmission',JSON.stringify(object),{
        headers:new HttpHeaders().set('Authorization', this.auth.token()).set('Content-Type','application/json')
      }
    )
  }
  public updateSubmissionDetails(object:SubmissionDetailsEdit,projectId:number):Observable<any>{
    return this.http.patch(
      this.nhsApiUrl + `projects/${projectId}/modifyProject`,
      JSON.stringify(object),
      {
        headers: new HttpHeaders().set('Authorization', this.auth.token()).set('Content-Type', 'application/json'),
        params: new HttpParams().set('id', projectId.toString()),
      }
    );
  }

  public renameSubmission(object: any): Observable<any> {
    return this.http.post(
      this.nhsApiUrl + 'submissionPortal/renameSubmission', JSON.stringify(object), {
        headers: new HttpHeaders().set('Authorization', this.auth.token()).set('Content-Type', 'application/json'),
      }
    );
  }

  public createOrganisation(object: any): Observable<any> {
    return this.http.post(
      this.nhsApiUrl + 'submissionPortal/createOrganisation', JSON.stringify(object), {
        headers: new HttpHeaders().set('Authorization', this.auth.token()).set('Content-Type', 'application/json')
      }
    );
  }

  public moveFileToSubmitted(fileTrackingId: number): Observable<any> {
    return this.http.post(
      this.nhsApiUrl + 'submissionPortal/moveFileToSubmitted/' + fileTrackingId, ({}), {
        headers: new HttpHeaders().set('Authorization', this.auth.token()),
      }
    );
  }

  public getFileStatusHistory(fileTrackingId: number): Observable<any> {
    return this.http.get(
      this.nhsApiUrl + 'submissionPortal/getFileStatusHistory',  {
        headers: new HttpHeaders().set('Authorization', this.auth.token()),
        params: new HttpParams().set('fileTrackingId', fileTrackingId.toString())
      }
    );
  }

  public getAllUploads(submissionId): Observable<any> {
    return this.http.get(
      this.nhsApiUrl + 'submissionPortal/getAllUploads',  {
        headers: new HttpHeaders().set('Authorization', this.auth.token()),
        params: new HttpParams().set('submissionId', submissionId.toString())
      }
    );
  }
}