import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { CannyService } from 'src/app/core/services/canny.service';
import { ICannyBoard } from 'src/app/core/models/canny.vm';

declare let Canny: any;

@Component({
  selector: 'app-canny-modal',
  templateUrl: './canny-modal.component.html',
  styleUrls: ['./canny-modal.component.scss'],
  providers: [NgbModalConfig, NgbModal],
})
export class CannyModalComponent implements OnInit {
  @Output() public action = new EventEmitter<any>();
  @Output() public commentText = new EventEmitter<any>();

  @ViewChild('modal') modal: ElementRef;

  public myTextarea: string = '';
  public boards: Array<ICannyBoard> = [];
  public selectedBoard: ICannyBoard;
  private cannySSO: string;

  darkMode$: Observable<boolean>;

  constructor(
    config: NgbModalConfig,
    private modalService: NgbModal,
    private cannyService: CannyService,
    private store: Store<{ darkMode: boolean }>
  ) {
    config.backdrop = 'static';
    config.keyboard = false;
    this.darkMode$ = store.select('darkMode')
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.modalService.open(this.modal, { centered: true, size: 'xl' });

      this.getBoards()
    }, 200);
  }

  getBoards(): void {
    this.cannyService.getBoards().subscribe(
      success => {
        this.boards = success.data.boards
        this.cannySSO = success.data.cannySSO
      },
      error => {
        console.error(error)
      }
    )
  }

  renderBoard(board: ICannyBoard): void {
    this.selectedBoard = board;

    Canny('render', {
      boardToken: board.token,
      ssoToken: this.cannySSO,
      theme: 'light', // options: light [default], dark, auto
    });
  }

  onValueChange(value: string): void {
    this.commentText.emit(value)
  }

  closeModal(event: boolean) {
    this.selectedBoard = null;
    this.action.emit(event);
  }

}
